#skiptocontent a {
  padding:6px;
  position: absolute;
  top:-40px;
  left:0px;
  color:#000;
  border-right:1px solid $primary-color;
  border-bottom:1px solid $primary-color;
  border-bottom-right-radius:8px;
  background:#fff;
  -webkit-transition: top 1s ease-out, background 1s linear;
    transition: top 1s ease-out, background 1s linear;
    z-index: 100;
}

#skiptocontent a:focus {
  position:absolute;
  left:0px;
  top:0px;

  outline:0;

  -webkit-transition: top .1s ease-in, background .5s linear;
    transition: top .1s ease-in, background .5s linear;
}

.main-header {
  h1,div.logo{
    @include grid-column(4);
    margin: rem-calc(15) 0 ;
    line-height: 1;

    img{
      width:190px;
      max-width: inherit;
      @media #{$medium-up} {
        width:232px;
      }
    }
  }
  .tools{
    @include grid-column(8);
    position: relative;
    display: none;
    padding-top:rem-calc(22);
    ul{
      @include inline-list;
      float:right;
      li{
        position:relative;
        padding-right: rem-calc(22);
        min-height: rem-calc(31);
        &:after {
            content: '';
            height: 100%;
            width: 1px;
            background-color: #979797;
            position: absolute;
            right: 0;
            top: 0;
        }
        &.open {
              background-color: #eaeaea;
              padding-left: 1.375rem;
              margin-left: 0;
          }
        &:first-child{
          padding-right: rem-calc(0);
          &:after {
            content: none;
          }

        }
        a{
          color: #757575;
          padding:rem-calc(3) 0;
          vertical-align: middle;
          &.active{
            border-radius: 8px;
            background-color: #f9f2e6;
            padding:rem-calc(3) rem-calc(8);
          }
          &.destacada{
            color:$primary-color;
            font-weight: bold;
          }
          &.buscar{
             @extend .icon-lupa;
             display: inline-block;
            span{
              @include visually-hidden;

            }
          }
        }
      }
    }
     @media #{$medium-up} {
      display:block;

    }
  }
  .buscar-contenedor{
    display: none;
    position: absolute;
    z-index: 9;
    background-color: #eaeaea;
    right: 0;
    padding: 8px 10px;
    height: rem-calc(52);
    top: rem-calc(53);
    input {
      height: rem-calc(18);
    }
    .boton-buscar {
      padding: rem-calc(4) rem-calc(10);
      padding-bottom: rem-calc(5);
      right: 20px;
      color: $white;

    }
  }
}
