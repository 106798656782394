.slider-wrapper-b {
.slider{
  .item{
      position:relative;
      height: 100%;
      width: 100%;
      a{
        display: block;
        width: 75%;
        margin:0 auto;
        height: 100%;
        position:relative;
        text-align: center;
        max-width: 825px;
        .text{
          position:absolute;
          top:50%;
          left:50%;
          width:100%;

          transform:translate(-50%,-50%);
          text-align:center;
          display: block;
          padding:rem-calc(20 25 60);
          z-index:2;
        }
        h3{
          font-size: rem-calc(30);
          color:#fff;
          line-height: 1.2;
          text-shadow: 0 2px 3px rgba(0,0,0,.4);
          @media #{$medium-up} {
            font-size: rem-calc(49);

          }

        }
        span.mas{
          display: inline-block;
          background: #fff;
          color: #000000;
          font-size: rem-calc(18);
          border-radius:7px;
          padding: rem-calc(7 12);
          font-weight:bold;
          position:absolute;
          bottom:60px;
          margin:0 auto;
          width:auto;
          transform:translateX(-50%);
          z-index:2;
          @include single-transition();
          min-width: 62px;
          text-align: center;
        }
        &:after{
          background:#000;
          background:rgba(0,0,0,.7);
          transition: all 0.3s;
              content: '';
          position: absolute;
          z-index:1;
              width: 100%;
          height: 0;
          top: 0;
          left: 0;
          border-left:solid 3px #fff;
          border-right:solid 3px #fff;
        }
        &:hover{
          span.mas{
            color:#fff;
            background:#37BFCB;
          }
          &:after{
            height: 100%;
          }

        }
      }


  }
}
}

.slider-wrapper-b {
  .prev-btn,.next-btn{
      position:absolute;
      top:50%;
      z-index:10;
      margin-top:-32px;
      span{
         @include visually-hidden;
      }
    }
    .prev-btn{
      left: 15px;
      @media #{$medium-up} {
        left: 45px;
      }
      @extend .slide-enrera;

    }
    .next-btn{
      right: 15px;
      @media #{$medium-up} {
        right: 45px;
      }
      @extend .slide-endavant;
    }

}
.slider-wrapper {
  .prev-btn,.next-btn{
      position:absolute;
      top:50%;
      z-index:10;
      margin-top:-32px;
      span{
         @include visually-hidden;
      }
    }
    .prev-btn{
      left: 45px;
      @extend .slide-enrera;
      opacity: 0;

    }
    .next-btn{
      right: 45px;
      @extend .slide-endavant;
      opacity: 0;
    }
    &:hover {
      .prev-btn{
        opacity: .8;
        transition: opacity .25s ease-in-out;
        -moz-transition: opacity .25s ease-in-out;
        -webkit-transition: opacity .25s ease-in-out;

      }
      .next-btn{
        opacity: .8;
        transition: opacity .25s ease-in-out;
        -moz-transition: opacity .25s ease-in-out;
        -webkit-transition: opacity .25s ease-in-out;
      }

    }
    .overlay {
        background-color: rgba(0, 0, 0, 0.5);
        padding-top: 1em;
        padding-bottom: 1em;
    }

}
