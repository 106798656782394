.pagination-centered {
      text-align: center;
      margin-top: 1.5em;
ul.pagination {
  padding-top: 2rem;
  border-top: 1px solid $gainsboro;


  li {
    display: inline-block;
    
      a {
        &:hover {
        color: #0084af;
        }
      }
    
    &:before {
      content: none;
    }
    &.hide {
      display: none;
    }
    &.current {
      
      a {
        color: $white;
        background-color: #0084af;
      }
    }
    &:last-child {
      a {
        border-right: 0;
      }
    }

    a {
      border-radius: 0;
      transition: none;
      background: $light-gray;
      color: #757575;

      &:link:hover {
        color:  #0084af;
        border-bottom: 0;
        text-decoration: none;
      }
    }

    &.unavailable {
      a {
        background: none;
      }
    }

    &.arrow {
      a {
        padding-top: 0;
        padding-bottom: 0;
        background: none;
      }

      img {
        width: 29px;
      }

      span {
        display: none;
      }

      &.prev {
        a {
          padding-left: 0;
        }
      }

      &.next {
        a {
          padding-right: 0;
        }
      }
    }
  }

  @media #{$small-up} {

    li {
      a {
        font-size: rem-calc(17);
        padding-left: 0.75rem;
        padding-right:  0.75rem;
      }

      &.hide {
        display: inline-block;
      }

      &.arrow {
        margin-top: 3px;

        a {
          border-right: 0;
        }

        img {
          width: 40px;
        }

        &.prev {
          float: left;
        }

        &.next {
          float: right;
        }
      }
    }
  }

  @media #{$medium-up} {
    li {

      &.arrow {
        span {
          display: inline;
        }

        a:link {
          &:hover {
            text-decoration: underline;
            color: $body-font-color;
          }
        }
      }

      &.prev {
        img {
          margin-right: 10px;
        }
      }

      &.next {
        img {
          margin-left: 10px;
        }
      }
    }
  }
}
}
/*paginació anterior*/
#pag {
  position: relative;
  margin: 4em 0 1.125em;
}
  #pag p {
    float: left;
    margin: 0;

  }
    #pag p.anterior {
      margin-right: 2em;
    }
    #pag p.seguent {
      position: absolute;
      top: 0;
      right: 0;
    }
  #pag ul {
    float: left;
    margin: 0 0 0 2em;
  }
  #pag.cercador ul {
    float: right;
    margin: 0;
  }
    #pag ul li {
      border: 1px solid #ddd;
      background: #f0f0f0;
      background: none;
      padding: 5px 10px;
      margin: 0 18px 0 0;
      float: left;
      text-align: center;
      line-height: 1.2em;
      &:before{
        content:none;
      }
    }
    #pag ul li.prev {
      margin-right: rem-calc(45);
      border:none;
      a:before{
        content:'<< ';
        background:none;

      }
    }
    #pag ul li.next {
      margin-left: rem-calc(45);
      border:none;
      a:after{
        content:' >>';
        background:none;

      }
    }
      #pag ul li.actual {
        background: #ccc;
      }
      #pag ul li a {
        border: none;
      }
