.main-footer {
  background-color: #6e797a;
  margin-top: rem-calc(55);
   padding: rem-calc(27) 0 0;

  p,
  ul {
    font-size: rem-calc(14);
    color:#fff;
    @media #{$small-up} {
      font-size: rem-calc(15);
    }

    @media #{$medium-up} {
      font-size: rem-calc(16);
    }


  }
  a{
    color:#fff;
  }
  .row div{
    padding-top: 10px;
    padding-bottom:30px;
  }
  .main-footer__info{
    @include grid-column(12);
    .copyright{
      font-size: rem-calc(14);
    }
  }
  .main-footer__contact{
    @include grid-column(12);

  }
  .main-footer__menu{
      @include grid-column(12);
      ul{
          font-size: rem-calc(14);
      }

    }
   .main-footer__social{
        @include grid-column(12);
        p{
          float:left;
        }
        ul{
          @include inline-list;
          li{
            &.twitter a{
              @extend .xarxes-twitter;
              display: inline-block;
              span{
                @include visually-hidden;
              }
            }
            &.vimeo{
               margin-left: rem-calc(12);
              a{
                @extend .xarxes-vimeo;
                display: inline-block;
                span{
                  @include visually-hidden;
                }
              }

            }
            &.facebook{
               margin-left: rem-calc(12);
              a{
                @extend .xarxes-facebook;
                display: inline-block;
                span{
                  @include visually-hidden;
                }
              }

            }
          }
        }
    }
    .correo_web {
      margin-top: -8px;
      img {
        margin-right: 5px;
      }
    }

}
#flag{
  display: block;
}
@media #{$tablet-up} {
  .main-footer {
    .row div{
      padding-bottom:0;
    }
    .main-footer__info{
      @include grid-column(4);
    }
    .main-footer__contact{
      @include grid-column(4);
    }
    .main-footer__menu{
      @include grid-column(4);
    }
    .main-footer__social{

        @include grid-column(12);

    }
  }
}
@media #{$medium-up} {
  .main-footer {
    .row div{
      padding-bottom:30px;
    }
    .main-footer__info{
      @include grid-column(3);
    }
    .main-footer__contact{
      @include grid-column(3);
    }
    .main-footer__menu{
      @include grid-column(3);
    }
    .main-footer__social{
        @include grid-column(3);
        text-align: right;
        div{
          padding:0;
          float: right;
          width: auto;
          ul{
            float:left;
            margin-left:rem-calc(10);
            padding-top:rem-calc(3);
            padding-right:rem-calc(3);
          }
        }

    }
  }
}
.subir {
  display: block;
}