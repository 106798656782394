/* botó menú mòbil */
$button-size : 20px;
//http://sarasoueidan.com/blog/navicon-transformicons/
.lines {
  margin-top: 8px;
  //margin-left: 7px;
  //height: 5px;
  vertical-align: top;
  //create middle line
  @include line($primary-color,$button-size ,.3s);
  @media #{$medium-up} {
      background-color: $white;
    }
  position: relative;

  /*create the upper and lower lines as pseudo-elements of the middle line*/
  &:before, &:after {
    @include line($primary-color,$button-size ,.3s);
    position: absolute;
      left:0;
    content: '';
    transform-origin: $button-size/14 center;
    //height: 5px;
    @media #{$medium-up} {
      background-color: $white;
    }
  }
  &:before { top: $button-size/3; }
  &:after { top: -$button-size/3; }
}

.lines-button.x.close .lines{

    /*hide the middle line*/
    background: transparent;

    /*overlay the lines by setting both their top values to 0*/
    &:before, &:after{
      transform-origin: 50% 50%;
      top:0;
      width: $button-size;
    }

    // rotate the lines to form the x shape
    &:before{
      transform: rotate3d(0,0,1,45deg);
    }
    &:after{
      transform: rotate3d(0,0,1,-45deg);
    }
}

.main-nav__button{
  position: absolute;
  top:16px;
  //left:1px;
  right: rem-calc(10);
  border:none;
  z-index: 2;
  background-color:transparent;
  @include  radius(3px);
  text-align:center;
  box-shadow: none;
  color:$white;
  padding:6px 10px;
  text-transform: uppercase;
  font-size:em-calc(14);
  display:none;
  .js & {
    display:block;
  }
  &:focus{
    background-color:transparent;
  }
  &:hover{
    background-color:transparent;
  }
  &.open{
    background-color:transparent;
    &:focus{
      background-color:transparent;
    }
  }
  .llegenda{
    display: none;
  }
  @media #{$medium-up} {
    top:64px;
    .llegenda{
      display: inline-block;
      padding-left:0.75em;
    }
  }
}
/*fi botó menú mòbil*/

/* menú mòbil*/
.menu-mobil{
    display:none;
   background: $primary-color;
   margin-bottom:1em;
   ul{
      margin:0;
      list-style: none;
      li{
        //background-color: #eaeaea;
        margin:0;
        /*display:none;*/

/*        &.active, &.home{
          display: block;
          background-color: #646464;*/
          a{
            &:before{
               //@extend .menu-mobile-b-nivell1;
                position: absolute;
                content: '';
                left: 10px;
                top: 15px;
            }
          }
          /*li.active{
          &.active {
            display: block;
            background-color: $primary-color;
            a{
              &:before{
                //@extend .menu-mobile-b-nivell2;
              }
            }
          }
        li{
          display: block;
          background-color: $primary-color;
          a{
            &:before{
              content: none;
            }
          }
        }*/

        /*}*/
        /*a{
          color:$black;
          display: block;
          padding:.5em 2.5em;
          border-bottom:solid 1px $iron;
          position:relative;
          &.menu-mobil__submenu{
            &:after{
              //@extend .menu-mobile-b-nivell3;
              position: absolute;
              content: '';
              right: 5%;
              top: 50%;
             // margin-top: -$menu-mobile-b-nivell3-height/2;
            }
          }
          &.nivel1{
          background-color:$secondary-color;
          color:#000;
          border-color:#fff;
          padding-left:rem-calc(38);
          &:before{
              content: '';
              @extend .menu-arrows-left;
              position:absolute;
              left:8px;
              top:13px;
          }
        }
        &.active{
           background-color:$primary-color;
            border-color: $primary-color;
           color:#fff;
           &.nivel1{
              background-color:$primary-color;
              border-color: $primary-color;
              color:#fff;
              &:before{
                content: '';
                @extend .menu-arrows-down;
                position:absolute;
                top:15px;
              }
           }
        }
        }*/
      a{
        color:$body-font-color;
        display: block;
        padding:rem-calc(10 15);
        margin-bottom:0;
        line-height: 1.3;
        border-bottom: solid 1px $iron;
        text-decoration: none;
        background-color:#fff;
        word-wrap: break-word;
        position:relative;
        @include single-transition();
        &:hover{
          background-color: lighten($primary-color, 53%);
           color:$body-font-color;
        }
        &:focus,&:active{
          border-top:none;
          border-bottom: solid 1px $iron;
        }
        &.nivel1{
          background-color:$secondary-color;
          color:#000;
          border-color:#fff;
          padding-left:rem-calc(38);
          &:before{
              content: '';
              @extend .menu-arrows-left;
              position:absolute;
              left:8px;
              top:13px;
          }
        }
        &.active{
           background-color:$primary-color;
            border-color: $primary-color;
           color:#fff;
           &.nivel1{
              background-color:$primary-color;
              border-color: $primary-color;
              color:#fff;
              &:before{
                content: '';
                @extend .menu-arrows-down;
                position:absolute;
                top:15px;
              }
           }
        }
      }
      ul{
        //display: none;
        li:first-child a{
          border-top: none;
        }
      }
      &.active{
        ul{
            display: block;
            margin-left: 0;
            li a.active{
              color:$primary-color;
              background-color:#fff;
              border-color: $iron;
            }
        }
      }
      }
   }
   @media #{$medium-up} {
    display:none;
   }
   .menu-sota {
      li {
        background-color: #f9f2e6;
        a {
          color: $black;
          background-color: #f9f2e6;
        }
      }

   }
}
.js .menu-mobil{
  overflow: hidden;
    max-height: 0;
    display:block;
    visibility: hidden;
    a{
      opacity:0;
    }
    @media #{$medium-up} {
      display:none;
    }
}
.js .menu-mobil.open{
  display:block;
  height: auto;
  max-height: 1200px;
  transition-delay: 2s;
  -webkit-transition: max-height .4s cubic-bezier(0.77,0,.175,1);
  -moz-transition: max-height .4s cubic-bezier(0.77,0,.175,1);
  -o-transition: max-height .4s cubic-bezier(0.77,0,.175,1);
  transition: max-height .4s cubic-bezier(0.77,0,.175,1);
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  visibility: visible;

  width:100%;
  text-align:left;
  z-index:12;
  a{
      opacity:1;
      transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -webkit-transition: opacity 1s ease-in-out;
    }
}
/* menú desktop */
.main-header{
  .menu{
    border-top: solid 1px #c2c2c2;
    margin-bottom: 0;
    display:none;
    //opacity: 0;
    overflow:hidden;
    height: 54px;
    position:relative;
    h2.hidden{
      color:#fff;
    }
    @media #{$medium-up} {
      display:block;

    }
    .main-nav {
      // nav mobile
      margin-bottom: $paragraph-margin-bottom;
      padding: rem-calc(15 23);
      transition: all 0.3s ease-out;
      background-color:$white;
      position: relative!important;
      /*h2 {
        @include visually-hidden;
      }*/

      ul {
        list-style: none;
        font-size: rem-calc(20);
        margin: 0;

        a {
          color: #4a4a4a;
          display: block;
          padding: 0.45em 0 0.6em;
          text-transform: uppercase;
          letter-spacing: 0.6px;
        }
      }

      /*.js & {
        display: none;
        position: absolute;
        top: 69px;
        right: $column-gutter/2;
        z-index: 1;
        width: rem-calc(226);

        &.open {
          display: block;
        }
      }*/
      .nav-menu {
          display: block;
          //position: relative;
          list-style: none;
          margin: 0;
          padding: 0;
          z-index: 15;
      }
      .nav-menu .nav-item .sub-nav {
            position: absolute;
            display: none;
            top: 54px;
            margin-top: -2px;
            padding: 1em 2em 0;
            border-top: solid 1px #c2c2c2;
            background-color: $secondary-color;
            z-index: 2;
            width:100%;
            left:0;
            margin-right:-1px;
            font-size: 1rem;
            min-height: 368px;
            max-height: 0;
            overflow:hidden;
            opacity: 0;
            //max-width: 1205px;
            box-shadow: 0 4px 2px -2px #c2c2c2;

          &.open {
            display: block;
            max-height: 1200px;
            opacity: 1;
            //max-width: 77.5rem;

          }
          .titol{
            font-size: rem-calc(36);
            margin-bottom:.25em;
            line-height: 1.4;
            font-weight: normal;
            color:#222;
            min-height: 50px;
            a{
              color:#222;
              display: inline-block;
              padding:0;
              position:absolute;
              z-index:3;
            }
        }

        .sub-nav-group{
          display: inline-block;
        }
        ul{
          display: inline-block;
          vertical-align: top;
          margin: 0;
          padding: .35em 0 3rem;
          width: 335px;

          z-index:2;
          li{
            display: block;
            list-style-type: none;
            margin: 0;
            padding: 0;
            background: $secondary-color;

            a{
              color: #4a4a4a;
              text-align: left;
              padding:0;
              font-size: rem-calc(16);
              text-transform: none;
              //line-height: 2.24;
              line-height: 1.25;
              font-weight:normal;
              position: relative;
              padding:rem-calc(7) rem-calc(23) rem-calc(7) rem-calc(17);
              display: inline-block;
              width: 100%;
              &.dropdown:after{
                @extend .menu-triangle-desplega;
                position: absolute;
                content: '';
                right: 20px;

                //top: 50%;
                //margin-top: -$ico-megamenu-dropdown-height/2;
                top:0;
                margin-top: 12px;
              }

            }
            &:hover {
                > a{
                  background-color: rgba($primary-color,.9);
                  color: $white;
                  &.dropdown:after{
                    @extend .menu-triangle-desplega-blanc;
                  }
                }
                > ul.sub-level-group{
                    max-height: 1200px;
                    //@include single-transition(opacity, 300ms, ease-in);
                    visibility: visible;
                    opacity: 1;
                    //transition-delay: 0s; /* react immediately on hover */

                }
            }
          }

          &.sub-level-group{
            position: absolute;
            left: 340px;
            top: -2px;
            //width: 375px;
            width: 375px;
            max-height: 0;
            visibility: hidden;
            opacity: 0;
            height: 100%;
            //transition-delay: 1s;
            //transition-duration: 0s;
            &:before{
              content: '';
              border-left: solid 35px $secondary-color;
              position: absolute;
              //height: 100%;
              //top: -2px;
              height: 300%;
              top: -60px;
              width: 400px;
            }
            li {
              a{
                margin-left:35px;
                max-width:340px;
                &:hover {
                  background-color: #daf6ff;
                  color: #4a4a4a;
                }
              }
              .estruct {
                margin-top: rem-calc(120);
              }
            }
            //display: none;
          }

        }
        .intro{
          position: absolute;
          right: 0;
          top: -30px;
          z-index: 1;
          width: 400px;
          @include clearfix;
          img{

          }
          .text{

            background-color:#6e797a;
            padding:rem-calc(10 20);
            p{
               font-size: rem-calc(14);
               color:$white;
               line-height: 1.3;
               margin:0;
            }
          }
        }
        .tancar{
            position: absolute;
            top:29px;
            right:27px;
            z-index: 2;
            //@extend .ico-megarmenu-tancar;
            width: 23px;
            height: 23px;
            display: inline-block;
            background-color:transparent;
            padding: 0;
            span{
               @include visually-hidden;
            }
        }
      }
      ul li.has-sub-nav > a{
              position:relative;
              &:after{
                position:absolute;
                content: '';
                display: block;
                right: 14px;
                //left: calc(100% - 18px);
                top:50%;
                margin-top:-2px;
                @extend .triangle-menu;
              }
            }

      // nav medium
      @media #{$medium-up} {

        @include clearfix;
        display: block;

        margin-bottom: 0;
        padding: rem-calc(0 15);
        position: static;
        width: auto;
        margin-top: 0;

        .menu-wrapper {
          @include grid-row();
          position:relative;
          padding: 0 .9375rem;
        }

        ul {
          @include grid-column;
          //position: relative;
          font-size: rem-calc(14);
          float: none;
          @media #{$medium-up} {
            font-size: rem-calc(13);
          }
          @media only screen and (min-width: 1080px){
            font-size: rem-calc(15);
          }
          @media only screen and (min-width: 1190px){
            font-size: rem-calc(16);
          }


          }
          li {
            display: inline-block;
            /*position: relative;*/
            min-width:9%;
            margin:0;
            &.menu-destacado > a{
              font-weight:900;
            }

            &.nav-item > a {
              margin: 0;
              //border: 1px solid transparent;
              color: #4a4a4a;
              padding:rem-calc(0 12 0 12);
              @media only screen and (min-width: 1190px){
                padding:rem-calc(0 18 0 18);
              }
              line-height: rem-calc(52);
              text-align: center;
              border-bottom:solid 1px transparent;
              border-left: solid 1px transparent;
              border-right: solid 1px transparent;

              /*&.open{
                box-shadow: 0px -3px 7px -3px rgba(0,0,0,0.50);

              }*/
              &:hover,
              &.is-hovered {
                text-decoration: none;
                background-color: $secondary-color;

              }

              &.open{
                background-color: $secondary-color;
                z-index: 3;
                color:#000;
                border-bottom:solid 1px $secondary-color;
                border-left: solid 1px #c2c2c2;
                border-right: solid 1px #c2c2c2;
              }
              /*&:before{
                    content: '';
                    height:60%;
                    width: 1px;
                    background-color:$secondary-color;
                    position:absolute;
                    left:0;
                    top:20%;
                }*/
            }
            &.nav-item.has-sub-nav > a{
              padding-right:rem-calc(30);
            }
            &.nav-item.active > a{
                 background-color: $secondary-color;
            }


            &.menu-fixed{
                display:none;
            }
            &.nav-item.investigacion{
              .sub-nav{
                //min-height: 468px;
                ul.sub-level-group{
                  width: 575px;
                  li a{
                    //max-width:540px;
                    max-width:335px;
                  }
                }

              }
            }

        }
      }
    }
    &.fixed{
      position: fixed;
      z-index: 100;
      width: 100%;
      top: 0;
      box-shadow: 0 2px 2px rgba(0,0,0,0.1);
      .main-nav{
        .nav-menu .nav-item .sub-nav{
          top:41px;
        }
        ul li.nav-item>a:after,ul li.nav-item>a:hover:after{
         //content:none;
        }

         ul li.nav-item>a.open{
          box-shadow:none;
         }
        ul {
           font-size: rem-calc(13);
           @media only screen and (min-width: 1220px){
            font-size: rem-calc(15);
           }
           text-transform: none;
            li {
              min-width:8.8%;
              @media only screen and (min-width: 1220px){
                min-width:9%;
              }
              &.nav-item > a {
                padding:0 0.5em;
                @media only screen and (min-width: 1220px){
                  padding:0 0.75em;
                }

                line-height: rem-calc(40);
              }
              &.nav-item.has-sub-nav > a{
                padding-right:rem-calc(30);
              }

              &.menu-fixed{
                text-align: center;
                display:inline-block;
                a{
                  display: inline;
                  padding:0;

                  img{
                    margin-top:-5px;
                  }

                }
                &.menu-fixed__home{
                   min-width:6%;
                }
                &.menu-fixed__search{
                  min-width:6%;
                }
              }
            }
        }

      }
    }
  }
}

 /* mega menu list */


/* sub-navigation panel */

.js .main-header .menu.load{
  @media #{$medium-up} {
    opacity: 1;
    overflow:inherit;
    height: auto;
    background-color:#fff;
  }
}
