.no-js{
    .menu{
        opacity: 1;
        overflow:inherit;
        li.nav-item{
            &:hover{
              .sub-nav{
                display:block!important;
                opacity:1!important;
              }
              .tancar,.intro{
                display:none!important;
              }
            }
        }
    }
    div.eines .desplegable:hover{
        position: relative;
    }
    div.eines .desplegable:hover ul{
        display:block;
        position: absolute;;
        z-index:100;
        background-color: #fff;
        left:0;
        text-align: left;
        width:250px;
        list-style: none;
        margin:0;
        border:solid 1px #ccc;
    }
    .slider-wrapper,.carousel-bt{
        //display:none;
    }
    .slider-wrapper .next-btn, .slider-wrapper .prev-btn
    {
        display:none;
    }
    .carousel .slide{
        @include grid-column(2);
    }
    img.lazyNoticies, img.lazy, img.lazySlider{
        display:none;
    }
    .menu-mobil {
        visibility: visible;
        overflow: hidden;
        max-height: 1200px;
        display: block;
        @media #{$tablet-up} {
            display: none;
        }
    }
    .main-nav__button {
        display: none;
    }
    @media #{$tablet-up} {
        .slider-wrapper{
           opacity:1;
        }
        .slider .item{
            width: 100%;
        }
    }
}