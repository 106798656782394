.curso{
	.main-titol.c{
		background-image: none;
		min-height: 325px;
		position:relative;
		overflow: hidden;
		&:after{
			@media #{$tablet-up} {
				content: '';
			    position: absolute;
			    background: transparent url(img/background.svg) no-repeat;
			    width: 330px;
			    height: 150%;
			    top: -130px;
			    right: 0;
			    z-index: -1;
		    }
		    @media #{$medium-up} {

		    	width: 50%;

		    }

		}
		/*
		@media #{$tablet-up} {
			background-image: url('img/background.svg');
			background-position: 500% 0;
			background-size: 100%;
		}

		@media #{$medium-up} {
			background-image: url('img/background.svg');
			background-position: 120% 45%;
			background-size: 50%;
		}
		@media #{$large-up} {
			background-position: 120% 45%;
			background-size: 60%;
		}
		*/
		.cabecera{
			min-height: 325px;
			background:rgba($primary-color,.8);
		}
		h1{
			max-width:650px;
		}
	}
	/*.datos-curso{
		margin:rem-calc(16);
		.columns{
			background-color: #ededed;
			padding:rem-calc(30 50);
		}
		@media #{$medium-up} {
		    position: absolute;
		    width:100%;
		    margin:0;
		    margin-top:-150px;
		    z-index: 2;
		    .columns:before{
				height:calc(100% - 10px);
				width:calc(100% - 10px);
				background-color:$body-font-color;
				content: '';
				right:-10px;
				top:20px;
				position: absolute;
				z-index: -1;
				box-shadow: 0 5px 10px rgba(0,0,0,.15);
			}
		 }
	}*/
	.datos-curso{
		@include grid-row();
		position: relative;
		padding:rem-calc(16);
		.columns{
			background-color: #ededed;
			padding:rem-calc(20);
			@media #{$medium-up} {
				padding:rem-calc(30 50);
			}
		}
		@media #{$medium-up} {
			.wrapper{
			    position: absolute;
			    width:100%;
			    padding:0;
			    margin-top:-150px;
			    z-index: 2;
			    right: 10px;
    			max-width: 300px;
    			overflow: hidden;
			    .columns:before{
					height:calc(100% - 10px);
					width:calc(100% - 10px);
					background-color:$body-font-color;
					content: '';
					right:-10px;
					top:20px;
					position: absolute;
					z-index: -1;
					box-shadow: 0 5px 10px rgba(0,0,0,.15);
				}
				p {
					margin-bottom: 0;
				}
				dt span {
					font-weight: 400;
					color: #6e797a;

				}
				.linea {
					display: inline-block;
					margin-bottom: .9375rem;
				}
		 	}
		}
	}
	.multi{

		@media #{$medium-up} {
			&:before {
			    left: 2%;
			    right: auto;
			    top: -82px;
			    content: '';
			    position: absolute;
			    border-top: 30px #1697CB solid;
			    border-left: transparent 30px solid;
			    border-right: transparent 30px solid;
			    opacity:.8;
			    z-index: 1;
			}
		}
	}
	.otros{
		position: absolute;
		top:-50px;
		color:#fff;
		right:0;
		padding-bottom: rem-calc(3);
		&:before{
			content: '←';
			display: inline;
			font-weight: bold;
			padding-right:5px;
		}
		&:after{
			content: '';
			position:absolute;
			width: 0;
			height: 2px;
			background-color:#fff;
			bottom:0;
			left:0;
			@include single-transition();
		}
		&:hover{
			&:after{
		  	width: 100%;
			}
		}
	}
}
