// imatge esquerra/dreta
.img-left,.txtImgEsq,
.img-right,.txtImgDr {
  img {
    margin-bottom: $paragraph-margin-bottom;
  }
}
@media #{$medium-up} {
  .img-left,.txtImgEsq {
    img {
      float: left;
      margin-right: $column-gutter/2;
      min-width: 148px;
    }
  }

  .img-right,.txtImgDr {
    img {
      float: right;
      margin-left: $column-gutter/2;
      min-width: 148px;
    }
  }
}

//images amb flexbox per  ten paràgrafs de continuació
@media #{$medium-up} {
  .img-left{
    display: flex;
    .image {
      flex-shrink: 0;
      /*img {
        width: 100%;
      }*/
    }
    .text{
      padding-left:0.75em;
    }
  }
}
//vincles
a:focus {
    //outline: 3px solid #ffbf47;
}

// llistes
.contenido {
  //ul:not(.pagination,.related-links-list) {
  ul{
    list-style: none;
    margin-left: 0;

    li {
      position: relative;
      padding-left: 1.3em;
      margin-bottom: 0.5em;
      margin-top:0.5em;
      line-height: 1.4;
      &:before {
        content: '';
        display: block;
        @extend .llistat-nivell-1;
        position: absolute;
        left: 0;
        top: 0.375em;
      }

      p {
        margin-bottom: 0;
      }

      // subllista 1
      ul {
        margin-left: 0;
        li {
          &:before {
             @extend .llistat-nivell-2;
          }

          p {
            font-size: inherit;
          }

          // subllista 2
          ul {
            margin-bottom:1em;
            margin-top:0;
            font-size: rem-calc(15);
            li {
              padding-left: rem-calc(20);
             
              &:before {
                //top: 0.55em;
                //@extend .kland-llistat-nivell-3;
              }

              p {
                font-size: inherit;
              }
            }
          }
        }
      }
    }

    // llistat dues columnes
    &.duesCol {
      @media #{$medium-up} {
        @include block-grid(2);
        margin: 0 0 $paragraph-margin-bottom 0;

        li {
          padding-left: 1.3em;
          padding-bottom: 0;
        }
      }
    }

    // llista amb fons de color
    &.fons,&.llista_fons {
      margin-left: 0;

      li {
        background-color: $light-gray;
        padding: 0.5em 35px;
        margin-bottom: 0.5em;

        &:before {
          top: 0.9em;
          left: 14px;
        }
      }
    }
    &.tipusA{
      background-color: $light-gray;
      //border:solid 1px $primary-color;
      //box-shadow: 0 0 2px rgba(0,0,0,0.15),0 2px 2px rgba(0,0,0,0.2);
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
      padding:1em 2em;
    }
    &.tipusB{
      border:solid 1px $primary-color;
      padding:1em 2em;
    }
    &.contacte{
      padding:1em 2em;
    }

    // llista separadors
    &.llista_separadors {
      margin-left: 0;

      li {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border-bottom: 1px dotted $secondary-color;
        margin-bottom: 0;

        &:before {
          top: 1.05em;
        }
      }
    }

    // llista caixa
    &.llista_caixa {
      margin-left: 0;
      background: $secondary-color;
      padding: 8px;

      li {
        padding: 0.5em 30px;
        margin-bottom: 0.55em;
        background-color: $white;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          top: 1.05em;
          left: 14px;
        }

        &.caixaTitol {
          color: $white;
          background: $primary-color;
          color: $white;
          font-size: rem-calc(17);
        }
      }
    }
  }

  ol {
    margin-left: $column-gutter;
  }
  h3{
    color:$primary-color;
    text-transform: uppercase;

  }
}

// botons
.btn,
.button {
  @include button($padding: $button-sml);

  a {
    color: $white;
    text-decoration: none;
  }
}

a.button {
  text-decoration: none;
}

// baixar arxiu
.dl {
  background: $secondary-color;
  padding: 0.5em 1em;
  border-radius: 12px;

  a {
    @include hover;
  }

  &:before {
    //@extend .icon-download;
    content: '';
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 10px;
  }
}

// vincle destacado
.dest {
  a {
    //@include hover;
    display: block;
    background: #fff;
    padding: 0.5em 1em 0.5em rem-calc(35);
    border-radius: 12px;
    font-size: rem-calc(16);
    font-weight: bold;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
    position: relative;
    line-height: 1.2;
    @include single-transition();
    &:before {
      content: '';
      @extend .kland-arxiu-destacat;
      display: inline-block;
      top:11px;
      left:12px;
      position:absolute;
      //width: 10px;
      //height: 10px;
      //background: $primary-color;
      //border-radius: 3px;
    }
    &:hover{
      box-shadow: 0 0 3px 0 rgba( $primary-color, 1);
      //color:#fff;
    }
  }
}

// destacado
.destacado{
  width:100%;
  font-style:italic;
  padding:rem-calc(25) 0;
  border-top:solid 9px $primary-color;
  border-bottom:solid 9px $primary-color;
  margin: rem-calc(30) 0 rem-calc(40);
  line-height: 1.38;

  color:#000;
  font-size: rem-calc(24);


}
.destacadoInterior{
  width:70%;
  font-style:italic;
  padding:rem-calc(25) 0;
  border-top:solid 9px $primary-color;
  border-bottom:solid 9px $primary-color;
  margin: rem-calc(30) 0 rem-calc(40);
  line-height: 1.38;

  color:#000;
  font-size: rem-calc(24)!important;
  margin: 0 auto;
  margin-bottom: 2.5rem;
  margin-top: 1.875rem;


}
.destacadoDr{
  @extend .destacado;
  float:right;
  margin: rem-calc(0 0 50 40);
  max-width:250px;
  p{
    font-size: rem-calc(18);
    @media #{$medium-up} {
       font-size: rem-calc(20);
    }
    @media #{$large-up} {
       font-size: rem-calc(24);
    }
  }
  @media #{$large-up} {
    max-width:400px;
  }
}
.destacadoEsq{
  @extend .destacado;
  float:left;
  margin: rem-calc(0 40 50 0);
  max-width:250px;
  p{
    font-size: rem-calc(18);
    @media #{$medium-up} {
       font-size: rem-calc(20);
    }
    @media #{$large-up} {
       font-size: rem-calc(24);
    }
  }
  @media #{$large-up} {
    max-width:400px;
  }
}
aside{
  .destacadoDr,.destacadoEsq{
    float:none;
    margin-top:0;
    margin: rem-calc(0 0 55 0);
  }
}

.inscripcion{
  background-color:$primary-color;
  width:100%;
  display: block;
  color:#fff;
  font-weight:bold;
  text-transform: uppercase;
  letter-spacing: 3.8px;
  text-align: center;
  padding: rem-calc(7 11);
  position:relative;
  @include single-transition();
  border:solid 2px $primary-color;

  &:hover{
    background-color:#fff;


  }
}
.inscripcion-no-hover{
  background-color:$primary-color;
  width:100%;
  display: block;
  color:#fff;
  font-weight:bold;
  text-transform: uppercase;
  letter-spacing: 3.8px;
  text-align: center;
  padding: rem-calc(7 11);
  position:relative;
  @include single-transition();
  border:solid 2px $primary-color;
}
dl{
  margin-bottom:rem-calc(35);
  dt{
    margin-bottom:0;
    color:#000;
  }
  dd{
    margin-bottom:rem-calc(15);
    a{
      text-decoration: underline;
    }
  }
}