.imagen-superior{
    max-height: 450px;
    @media only screen and (min-width: 1820px){
      max-height: 525px;
    }
    overflow: hidden;
    img{
      width:100%;
    }
}
.imagen-lateral{
    max-width: 100%;
    overflow: hidden;
    margin-bottom:rem-calc(30);

}
.imagen-interior {
  max-width: 100%;
  overflow: hidden;
  margin-bottom:rem-calc(30);
  display: block;
  float: left;
  margin-right: 20px;
}
.main-titol{
 background-color:$primary-color;
 margin-bottom:rem-calc(50);
 position:relative;

  h1{
    @include grid-row();
    padding: rem-calc(0 15 13);
    line-height:  rem-calc(45);
    font-size: rem-calc(36);
    margin-bottom:0;
     color: #ffffff;
     font-weight: normal;
     text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    color:#fff;
    @media #{$medium-up} {
      font-size: rem-calc(46);
      line-height:  rem-calc(55);
    }

  }
  &.b{

    background-position:0 0;
    background-repeat: no-repeat;
    background-size: cover;
    div{
      //height: 100%;
      //width:100%;
      background:rgba(0,0,0,.2);
      //padding-top:rem-calc(75);
      //position:absolute;
      //top:0;
    }

    h1{
      font-size: rem-calc(48);
      padding-bottom: rem-calc(35);
      padding-top: rem-calc(115);
    }
  }
  &.c{
      min-height:450px;
      background: url('img/grupo-investigacion.jpg') no-repeat 0 0;
      background-size: cover;
      .cabecera{
          //position: absolute;
          width:100%;
          background-color:$primary-color;
          background:rgba(13,88,117,0.8);
          min-height:450px;
          .acronimo{
              padding: 0 1rem;
              color:#fff;
              margin-top:rem-calc(15);
              font-size:rem-calc(16);
              position:relative;
              display: inline-block;
              a{
                color:#fff;
              }
              &:after{
                content:'';
                height: 2px;
                width:25%;
                bottom: -7px;
                left: 1rem;
                position:absolute;
                background-color:$primary-color;
              }
          }
          h1{
            max-width:1100px;
            margin:rem-calc(18) 0 rem-calc(15);
            padding-bottom:0;
          }
          .investigador-principal,.fecha{
              padding: 0 1rem;
              color:#fff;
              padding-bottom:15px;
               font-size:rem-calc(14);
              span{
                font-size:rem-calc(22);
              }
          }
          .investigador-principal {
            font-size:rem-calc(22);
          }
      }
  }

}
 @media #{$medium-up} {
  .introduccion-texto{
      position: absolute;
      width:100%;
      margin-top:-150px;
      .columns{
        padding:30px 50px 0;
        background-color: #fff;
        p{
          font-size: rem-calc(17);
          &:first-child{
             /* font-size: rem-calc(20);*/
          }
        }
      }
  }
}
figure {
  margin: 0 0 2rem;
  //border-bottom: 1px solid $iron;
  padding-bottom: 1rem;

  img {
    margin-bottom: 1rem;
  }
}

figcaption {
  font-size: rem-calc(13);
  color: #4a4a4a;
  border-bottom: 1px solid $iron;
   padding-bottom: 1rem;
  @media #{$small-up} {
    font-size: rem-calc(14);
  }
}
.contenido{
  h2{
    border-left: solid 9px $primary-color;
    margin-bottom: rem-calc(28);
    padding-left: rem-calc(10);
    &.b{
      border:none;
      padding-bottom:0;
       margin-bottom: 0;
       font-size: rem-calc(24);
    }
  }
  h3,h4,h5,h6{
    margin-top:2rem;
    margin-bottom:1rem;
  }
  ul+h2,p+h2{
    margin-top:2rem;
  }
  p,ul,ol,div{
    font-size: rem-calc(17);
  }
  .texto-centrado{
    text-align: center;
    h2{
      font-weight: 900;
      color: $primary-color;
      font-size:rem-calc(16);
      margin-bottom:rem-calc(0);
      border:none;
    }
    p{
      font-size: rem-calc(16);
    }
  }

}
aside{
  h3{
    color:$white;
    background-color:$primary-color;
    padding:rem-calc(10 15);
    font-size: rem-calc(17);
    font-weight: 700;
  }
  p+h3, p+h2{
    margin-top:rem-calc(40);
  }

  ul{
    margin-left:0;
    margin-bottom:rem-calc(40);
    &.nav-menu{
      display: none;
      @media #{$medium-up} {
        display:block;
      }
    }
    li{
      list-style: none;
      a{
        color:$body-font-color;
        display: block;
        padding:rem-calc(10 15);
        margin-bottom:0;
        line-height: 1.3;
        border-bottom: solid 1px $iron;
        text-decoration: none;
        background-color:#fff;
        word-wrap: break-word;
        position:relative;
        @include single-transition();
        &:hover{
          background-color: lighten($primary-color, 53%);
           color:$body-font-color;
        }
        &:focus,&:active{
          border-top:none;
          border-bottom: solid 1px $iron;
        }
        &.nivel1{
          background-color:$secondary-color;
          color:#000;
          border-color:#fff;
          padding-left:rem-calc(38);
          &:before{
              content: '';
              @extend .menu-arrows-left;
              position:absolute;
              left:8px;
              top:13px;
          }
        }
        &.active{
           background-color:$primary-color;
            border-color: $primary-color;
           color:#fff;
           &.nivel1{
              background-color:$primary-color;
              border-color: $primary-color;
              color:#fff;
              &:before{
                content: '';
                @extend .menu-arrows-down;
                position:absolute;
                top:15px;
              }
           }
        }
      }
      &:first-child a{
        //border-top: solid 1px $iron;
      }
      ul{
        display: none;
        li:first-child a{
          border-top: none;
        }
      }
      &.active{
        ul{
            display: block;
            margin-left: 0;
            li a.active{
              color:$primary-color;
              background-color:#fff;
              border-color: $iron;
            }
        }
      }

    }
  }
  /*&.grupos{
      .active.grupos-investigacion-menu{
        a:before{
          content: none!important;
        }
        ul{
          display: none;
        }
      }
  }*/
}
.boton-accion, .boton-accion-2{
  h3{
    padding:0;
    background-color: transparent;
    margin:0;
  }
  a{
    background-color: $secondary-color;
    padding:rem-calc(16 16 52);
    display: block;
    position:relative;
    line-height: 1.3;
    font-size: rem-calc(19);
    color:#000;
    font-weight: normal;
    @include single-transition();
    margin-bottom:rem-calc(30);
    text-decoration: none;
    &:after{
      content: '';
      background: url('img/arrow.svg') no-repeat 0 0;
      position:absolute;
      bottom:rem-calc(16);
      left:rem-calc(16);
      width:25px;
      height: 25px;
      opacity: 0.4;
      @include single-transition();
    }

    &:hover,&:focus{
      background-color: darken($secondary-color, 30%);
      color:#fff;
      &:after{
        left:82%;
        opacity: 1;
      }
    }
    &.twitter{
      margin-top:40px;
      &:before{
         content: '';
          @extend .comparte-twitter;
          position:absolute;
          top:-11px;
          right:5px;
          opacity: 0.4;
          @include single-transition();
      }
      &:hover{
        background-color:#CCEBF5;
        color:#000;
        &:before{
          opacity: 1;
        }
      }
    }
    &.facebook{
      margin-top:40px;
      &:before{
         content: '';
         background-color:#fff;
          @extend .comparte-facebook;
          position:absolute;
          top:-11px;
          right:5px;
          opacity: 0.4;
          @include single-transition();
      }
      &:hover{
        background-color:#CCEBF5;
        color:#000;
        &:before{
          opacity: 1;
        }
      }
    }
  }
}
.convocatorias .boton-accion a:hover {
  background-color: #f29e4c;
}
.courses .boton-accion, .publication .boton-accion {
  max-width: rem-calc(256);
  //margin-left: rem-calc(22);
}
.courses .boton-accion-2, .publication .boton-accion-2 {
  max-width: rem-calc(256);
  margin-top: rem-calc(20);
}
.otras-actividades {
    display: none;
    @media #{$tablet-up} {
      display: block;
      float: right;
      font-size: rem-calc(15);
      padding-left:0;
      padding-bottom: rem-calc(3);
      position:relative;
      padding-top:  rem-calc(4);
      &:before{
        content: '←';
        display: inline;
        font-weight: bold;
        padding-right:5px;
      }
      &:after{
        content: '';
        position:absolute;
        width: 0;
        height: 2px;
        background-color:$primary-color;
        bottom:0;
        left:0;
        @include single-transition();
      }
      &:hover{
        &:after{
          width: 100%;
        }
      }
    }
  }
.courses .otras-actividades {
  margin-top: rem-calc(25);
}
.curso .otras-actividades {
  margin-top: rem-calc(-25);
}

@media #{$large-up} {
  .columns.contenido.multi{
    padding-right:rem-calc(60);
  }
}
div#gmap {
    width: 100%;
    height: 525px;
    text-align: center;
    font-size: .75em;
    line-height: 120%;
    padding: 0;
    overflow: hidden;
}
//galeria
.slider-responsive {
  margin:0 rem-calc(20);
  .slick-next, .slick-prev {
    &:before {
      color: #0099cb;
    }
  }
  .slick-slide img {
    border: solid 2px #fff;
  }
  /*.slick-next {
    right: -15px;
  }*/

}
//colors
.granate {
  .main-titol.p {
    background-color: $investigacion;
  }
  .contenido .cards li .card-info span.categoria svg, .contenido .cards li .card-info .arrow {
    fill: $investigacion;
  }
  .contenido .cards li a h2:after {
    background-color: $investigacion;
  }
  .contenido .cards li a .recomendado, .contenido .cards li div.card-box .recomendado {
    color: $investigacion;
  }
  .main-titol.c .cabecera {
    background-color:rgba($investigacion,.8)!important;
  }
  .main-titol.c .cabecera .acronimo:after {
    background-color: $investigacion;
  }
  .contenido h2 {
     border-left: solid 9px $investigacion;
  }
  .otras-actividades, .link-form {
    color: $investigacion;
  }
  .otras-actividades:after {
    background-color: $investigacion;
  }
  .inscripcion {
    background-color: $investigacion;
    border: 2px solid $investigacion;
    &:hover {
      color: $black;
    }

  }
  .button {
    background-color: $investigacion;
    border-color: #C22727;
    &:hover {
      background-color: #C22727;
    }
    &.acceptar {
      background-color: $white;
    }
  }

  .multi:before {
    border-top: 30px $investigacion solid!important;
    border-top-color: $investigacion!important;

  }
  aside {
    ul li a.active, ul li a.active.nivel1 {
      background-color: $investigacion;
      border-color: $investigacion;
      color: #fff;
    }
    ul li.active ul li a.active {
      color: $investigacion;
    }
    ul li a:hover {
      background-color: rgba($investigacion,.3);
    }
  }

}
.morado {
  .main-titol.p {
    background-color: $hospitalidad;
  }
  .contenido .cards li .card-info span.categoria svg, .contenido .cards li .card-info .arrow {
    fill: $hospitalidad;
  }
  .contenido .cards li a h2:after {
    background-color: $hospitalidad;
  }
  .contenido .cards li a .recomendado, .contenido .cards li div.card-box .recomendado {
    color: $hospitalidad;
  }
  .main-titol.c .cabecera {
    background-color:rgba($hospitalidad,.8)!important;
  }
  .main-titol.c .cabecera .acronimo:after {
    background-color: $hospitalidad;
  }
  .contenido h2 {
     border-left: solid 9px $hospitalidad;
  }
  .otras-actividades, .link-form {
    color: $hospitalidad;
  }
  .otras-actividades:after {
    background-color: $hospitalidad;
  }
  .inscripcion {
    background-color: $hospitalidad;
    border: 2px solid $hospitalidad;
    &:hover {
      color: $black;
    }

  }
  .button {
    background-color: $hospitalidad;
    border-color: #6961A7;
    &:hover {
      background-color: #6961A7;
    }
    &.acceptar {
      background-color: $white;
    }
  }

  .multi:before {
    border-top: 30px $hospitalidad solid!important;
    border-top-color: $hospitalidad!important;

  }
  aside {
    ul li a.active, ul li a.active.nivel1 {
      background-color: $hospitalidad;
      border-color: $hospitalidad;
      color: #fff;
    }
    ul li.active ul li a.active {
      color: $hospitalidad;
    }
    ul li a:hover {
      background-color: rgba($hospitalidad,.3);
    }
  }
}
.naranja {
  .main-titol.p {
    background-color: $formacion;
  }
  .contenido .cards li .card-info span.categoria svg, .contenido .cards li .card-info .arrow {
    fill: $formacion;
  }
  .contenido .cards li a h2:after {
    background-color: $formacion;
  }
  .contenido .cards li a .recomendado, .contenido .cards li div.card-box .recomendado {
    color: $formacion;
  }
  .main-titol.c .cabecera {
    background-color:rgba($formacion,.8)!important;
  }
  .main-titol.c .cabecera .acronimo:after {
    background-color: $formacion;
  }
  .contenido h2 {
     border-left: solid 9px $formacion;
  }
  .otras-actividades, .link-form {
    color: $formacion;
  }
  .otras-actividades:after {
    background-color: $formacion;
  }
  .inscripcion {
    background-color: $formacion;
    border: 2px solid $formacion;
    &:hover {
      color: $black;
    }

  }
  .button {
    background-color: $formacion;
    border-color: #E07810;
    &:hover {
      background-color: #E07810;
    }
    &.acceptar {
      background-color: $white;
    }
  }
  .multi:before {
    border-top: 30px $formacion solid!important;
    border-top-color: $formacion!important;

  }
  aside {
    ul li a.active, ul li a.active.nivel1 {
      background-color: $formacion;
      border-color: $formacion;
      color: #fff;
    }
    ul li.active ul li a.active {
      color: $formacion;
    }
    ul li a:hover {
      background-color: rgba($formacion,.3);
    }
  }
}
.error {
  .main-content {
    .row {
      img {
        margin-bottom: rem-calc(20);
      }
      text-align: center;
      width: 50%;
    }
  }
}
.contacte {
  .select2 {
    .select2-selection--multiple {
      border-radius: 0;
    }
    ul {
      li {
        padding-left: rem-calc(5);
        margin-top: 0;
        margin-bottom: .3em;
        input {
          margin-bottom: 0;
          height: auto;
          margin-top: 0;
        }
        &:before {
          background-image: none;
        }
      }
    }
  }
}
