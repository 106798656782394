 .wrapper{
 -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.home {

  .slider-wrapper{
    margin-bottom:rem-calc(53);
    min-height: rem-calc(350);
    max-height: rem-calc(350);
    overflow: hidden;
    @media #{$tablet-up} {
        min-height: rem-calc(315);
        max-height: rem-calc(315);
    }
    @media #{$medium-up} {
        min-height: rem-calc(418);
        max-height: rem-calc(418);
    }
    @media #{$xlarge-up} {
        min-height: rem-calc(508);
        max-height: rem-calc(508);
    }
    position:relative;
    .caroufredsel_wrapper{
      //min-height: rem-calc(134);
      @media #{$tablet-up} {
        min-height: rem-calc(315);
      }
      @media #{$medium-up} {
        min-height: rem-calc(418);
      }
      @media #{$xlarge-up} {
        min-height: rem-calc(508);
      }
    }
    .slider{
      //min-height: rem-calc(134);
      //height: rem-calc(518);
      overflow: hidden;
      @media #{$tablet-up} {
        min-height: rem-calc(315);
      }
      @media #{$medium-up} {
        min-height: rem-calc(418);
      }
      @media #{$xlarge-up} {
        min-height: rem-calc(508);
      }
    }
    .item{
       max-height:518px;
       overflow: hidden;
       position:relative;
       img{
        width:100%;
       }
       .text{
         width:100%;
          position:absolute;

          z-index:1;
          top:18%;
          @media #{$medium-up} {
            top:38%;
          }
          div.titulo{
             border-left:solid 77px #37c0cc;
             padding-left:rem-calc(32);
             @include grid-row();
             h2,p{
              max-width: 420px;
              color:#fff;
             }
             h2{
                a {
                  font-size: rem-calc(48);
                  font-weight: 900;
                  letter-spacing: 2.9px;
                  margin:0;
                  line-height: 1.2;
                  color: $white;
                }
             }
             p{
              font-size: rem-calc(21);
              letter-spacing: 1.3px;
              margin:rem-calc(13 0 0);
              line-height: 1.2;
             }
          }
       }
    }


  }
  .columns+.columns:last-child {
    float: left;
  }
  section{
    margin-bottom:rem-calc(60);
  }
  .pre-destacados img{
    margin-bottom:rem-calc(60);
  }
  .actualidad{
      h2{
        color: $body-font-color;
        font-size:rem-calc(24);
        font-weight:bold;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        //border-bottom: solid 1px #c2c2c2;
        @include grid-column(12);
        padding-bottom:rem-calc(13);
        margin-bottom:rem-calc(27);
        position:relative;
        &:after{
            content: '';
            position:absolute;
            display: block;
            height: 1px;
            width:100%;
            bottom:0;
            border-bottom: solid 1px #c2c2c2;
          }
      }
      #destacamos{
        position:relative;
        article{
          @include grid-column(6);
          img{
            margin-bottom: rem-calc(20);
          }
          h3{
            font-size: rem-calc(16);
            color:#000;
            a{
              color:#000;
              &:hover{
                text-decoration: underline;
              }
            }
          }
          .meta{
            font-size: rem-calc(11);
             margin-bottom: rem-calc(12);
          }
          p{
            display: none;
          }
          @media #{$large-up} {
            h3{
              font-size: rem-calc(21);
            }
            .meta{
              font-size: rem-calc(14);

            }
            p{
              display: block;
            }
          }
        }
      }
      #agenda{
        position:relative;
        h2:after{
            width:calc(100% - 39px);

          }
        article{
          @include grid-column(12);
          position:relative;
          padding-bottom: rem-calc(20);
          padding-top: rem-calc(20);
          &.first{
            padding-top: 0;
          }
          &:after{
            content: '';
            position:absolute;
            display: block;
            height: 1px;
            width:calc(100% - 39px);
            bottom:0;
            border-bottom: solid 1px #c2c2c2;
          }
          img{
            margin-bottom: rem-calc(20);
          }
          h3{
            font-size: rem-calc(18);
            color:#000;
            margin-top:0;
            max-width: 90%;
             @media #{$large-up} {
               font-size: rem-calc(22);
             }
             a{
              color:#000;
              &:hover{
                text-decoration: underline;
              }
            }
          }
          .fecha{
             width:80px;
             text-align: center;
             padding:0;
             float:left;

             span{
              display: block;
              line-height: .95;
             }
             .dia{
                font-size: rem-calc(28);
                margin:0 0 0.1em;
                font-weight: 900;
                color: $primary-color;

                @media #{$large-up} {
                  font-size: rem-calc(60);
                }
             }
             .mes{
                font-size: rem-calc(12);
                text-transform: uppercase;
                color:#000;
             }
          }
          .texto{
            float:left;
            width: calc(100% - 80px);
            padding-left:25px;
          }

        }
      }
      .todas{
        position:absolute;
        right: 32px;
        top:10px;
      }
  }

  .formacion{
    .caroufredsel_wrapper{
      min-height: 384px;
      @media #{$medium-up} {
        min-height: 484px;
      }
    }
    .slider{
      max-height: 384px;
      height: 384px;
      @media #{$medium-up} {
        max-height: 484px;
        height: 484px;
      }
      overflow: hidden;
    }
    .slider-wrapper-b{
        background:#fff url('img/foto-portada-formacion.jpg') no-repeat center 0;
        min-height: 384px;
        @media #{$medium-up} {
          min-height: 484px;
        }
    }
  }
  .investigacion{
    .row{
       background:#fff url('img/portada-investigacion.jpg') no-repeat center center;
       @media #{$medium-up} {
        min-height:484px;
       }
       .contenedor{
         background-color:#f9f2e6;
         background:rgba(249,242,230,.9);

         max-width: 420px;
         padding: rem-calc(30 15 80);
         @media #{$medium-up} {
          margin-left: rem-calc(100);
          padding: rem-calc(60 35);
          height:484px;
         }
         h2{
          font-size: rem-calc(24);
          font-weight: bold;
          letter-spacing: 2px;
          color: #000000;
          text-transform: uppercase;
          margin-bottom: rem-calc(25);
          @media #{$medium-up} {
            font-size: rem-calc(34);
            margin-bottom: rem-calc(35);
          }
         }
        ul{
          list-style: none;
          margin-left:0;
          margin-bottom:rem-calc(30);
          li{
            margin-bottom:rem-calc(13);
            a{
              position: relative;
              padding:rem-calc(0 0 3 20);
              @include single-transition();
              display: inline-block;
              color: #000000;
              font-size: rem-calc(18);
              &:before{
                @extend .kland-llistats-portada;
                content:'';
                position:absolute;
                left:0;
                top:8px;
                @include single-transition();
              }
              &:after{
                content:'';
                position:absolute;
                left:rem-calc(20);
                bottom:0;
                height: 1px;
                width:0;
                background-color: transparent;
                @include single-transition();
              }
              &:hover{

                &:after{
                   background-color: $body-font-color;
                   width:calc(100% - 20px);
                }
              }
            }
          }
        }
         a.mas{
          display: block;
          background: $body-font-color;
          color: #fff;
          font-size: rem-calc(18);
          border-radius:7px;
          padding: rem-calc(7 12);
          font-weight:bold;
          position:absolute;
          @include single-transition();
          min-width: 62px;
          text-align: center;
          &:hover{
            color:#000;
            background:#fff;
          }
        }
       }
    }
  }

  .proyectos{
    .row{
       background:#fff url('img/portada-proyectos-social.jpg') no-repeat center center;


      @media #{$medium-up} {
        min-height:220x;
       }
       .contenedor{
         background-color:#f9f2e6;
         background:rgba($primary-color,.9);

         max-width: 420px;

         padding: rem-calc(15 15 70);
         @media #{$medium-up} {
            height:220px;
            margin-right: rem-calc(100);
             padding: rem-calc(30);
             float: right;
         }
         h2{
          font-size: rem-calc(24);
          font-weight: bold;
          letter-spacing: 2px;
          color: #fff;
          text-transform: uppercase;
          margin-bottom: rem-calc(25);
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
          @media #{$medium-up} {
            font-size: rem-calc(34);
          }
         }
         a.mas{
          display: block;
          color:#000;
          background:#fff;
          font-size: rem-calc(18);
          border-radius:7px;
          padding: rem-calc(7 12);
          font-weight:bold;
          position:absolute;
          @include single-transition();
          min-width: 62px;
          text-align: center;
          &:hover{
            background: #000;
            color: #fff;
          }
        }
       }
    }

    a {
      color: #fff;
      &:hover {
        text-decoration: underline;
      }
    }

  }
  .bloques-destacados{
    a{
      display: block;
      background-color: #f9f2e6;
      box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.5);
      min-height: 145px;
      font-size: rem-calc(24);
      color:#000;
      padding:rem-calc(16 110 16 21);
      text-transform: uppercase;
      position:relative;
      @include single-transition();
      &:hover{
        //background-color: $primary-color;
        color: #fff;

      }
      &:after{
        content: '';
        display: block;
        position:absolute;
        bottom:21px;
        right:25px;
      }
      &.biblioteca{
        margin-bottom: rem-calc(15);
        &:after{
          @extend .icon-caixa-biblioteca-cusrn;
        }
        &:hover{
            background-color: #C2002F;
          }
      }
      &.memoria{
        margin-bottom: rem-calc(15);
        &:after{
          @extend .icon-caixa-memoria;
        }
        &:hover{
            background-color: #0099cb;
          }
      }
      &.newsletter{
        margin-bottom: rem-calc(15);
        &:after{
          @extend .icon-caixa-newsletter;
        }
        &:hover{
            background-color: #f29e4c;
          }
      }
      &.donaciones{
        margin-bottom: rem-calc(15);
        &:after{
          @extend .icon-caixa-donaciones;
        }
        &:hover{
            background-color:  #9A94C3;
          }
      }
    }
  }

}

@media #{$small-only} {
  .home {
    .slider-wrapper{
      .item{
         max-height:350px;
         overflow: hidden;
         position:relative;
         background-color:$primary-color;
         img{
          width:100%;
         }
         .text{
            position:relative;
            height:318px;
            div.titulo{
               border-left:none;
               padding:rem-calc(15);
               h2{
                  a {
                    font-size: rem-calc(28);
                  }
               }
               p{
                font-size: rem-calc(16);
                margin:rem-calc(6 0 0);

               }
            }
         }
      }
    }
    .actualidad{
      #destacamos{
          margin-bottom: rem-calc(65);
          border-bottom: solid 1px #c2c2c2;
          article{
            width: 100%;
            padding-bottom: rem-calc(15);
            .meta{
              margin:0;
            }
          }
      }
      .todas{
          top:100%;
          margin-top:rem-calc(10);
      }
      #agenda{
        border-bottom: solid 1px #c2c2c2;
        h2:after{
          width: 100%;
        }
        article:after{
          content:none;
        }
      }
    }
  }
}