.circulo{
  display: block;
  width:11px;
  height: 11px;
  border:solid 1px #fff;
  position:absolute;
  border-radius:100%;
  left:0;
  top:40%;
  &.abierta{
    background-color:$success-color;
  }
  &.cerrada{
    background-color:$alert-color;
  }
  &.prevista{
    background-color:$warning-color;
  }

}
.convocatorias{
  article{
    margin:rem-calc(20 0 55);
  }
  .categoria{
    position:relative;
    padding-left: 17px;
    text-transform: uppercase;
    font-size:rem-calc(14);
    margin-bottom:rem-calc(8);
  }
  .entidad{
    color:#000;

    margin-bottom:rem-calc(5);
  }
  .fechas{


    margin-bottom:rem-calc(10);
  }
  .importe{
    color:#000;

    margin-bottom:rem-calc(10);
  }
  h2{
    border:none;
    padding:0;
    font-size:rem-calc(28);
    margin:rem-calc(0 0 12);
  }
  .more{
    font-weight: bold;
  }

}
#sortable{
  .filterlist{
    position:relative;
    .search-box{
      position:absolute;
      top:8px;
      right:0;
      max-width:190px;
      input{
        height: 30px;
        border-radius:6px;
        border-color:#666767;
        box-shadow: 0 0 0 0 #d8d8d8 inset;
        background: url('img/ico-buscar.png') 7px 50% no-repeat;
        background-size:16px 16px;
        padding-left: rem-calc(32);
      }
    }
  }
}


.dataTables_wrapper .pagination-centered {
    margin-top: 0;
    .pagination {
        border: 0;
        padding-top: 0;
    }
}

.dataTables_filter input {
    height: 30px;
    border-radius:6px;
    border-color:#666767;
    box-shadow: 0 0 0 0 #d8d8d8 inset;
    background: url('img/ico-buscar.png') 7px 50% no-repeat;
    background-size:16px 16px;
    padding-left: rem-calc(32);
    box-sizing: border-box;
}



//datatables
table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 1.5rem;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  cursor: pointer;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background-repeat: no-repeat;
  background-position: center right;
}
table.dataTable thead .sorting {
  background-image: url("../images/sort_both.png");
}
table.dataTable thead .sorting_asc {
  background-image: url("../images/sort_asc.png");
}
table.dataTable thead .sorting_desc {
  background-image: url("../images/sort_desc.png");
}
table.dataTable thead .sorting_asc_disabled {
  background-image: url("../images/sort_asc_disabled.png");
}
table.dataTable thead .sorting_desc_disabled {
  background-image: url("../images/sort_desc_disabled.png");
}
table.datatables td a{
 font-size:rem-calc(16);
}
.convocatoria{
  .contenido .desc{
    padding-top: rem-calc(15);
  }
  dd.estado{
    position:relative;
    padding-left:rem-calc(18);
    .circulo{
      top:8px;
    }
  }
}
div.cercadorTaula{
  padding: 0px .9375rem;
}
div.toolbar ul.filters{
  display: inline-block;
  width: 100%;
  li{
    text-transform: uppercase;
    padding: 5px 12px 5px 5px;
    display: inline-block;
    float: left;
    background-color: #eaeaea;
    margin-right: 10px;
    font-size: 0.65em;
    @media #{$tablet-up} {
      font-size: 0.8em;
      padding: 5px 22px 5px 15px;
      margin-right: 15px;
      &.closed{
        margin-right: 0 !important;
      }
    }
    a{
      color: #000;
    }
    &.active{
      a{
        color: #FFF;
      }
      &:after{
          content: '';
          position:absolute;
          display: block;
          height:19px;
          width: 100%;
          left:0;
          background-repeat: no-repeat;
          background-position: 45%;
      }
    }
    &.closed{
      margin-right: 0 !important;
      border-left: 7px solid #d11318;
      &:hover{
        background-color:#d11318;
        a{
          color: #FFF;
        }
      }
      &.active{
        background-color:#d11318;
        &:after{
          background-image: url('../images/triangle-cerradas.png');
        }
      }
    }
    &.opened{
      border-left: 7px solid #55921f;
      &:hover{
        background-color:#55921f;
        a{
          color: #FFF;
        }
      }
      &.active{
        background-color:#55921f;
        &:after{
          background-image: url('../images/triangle-abiertas.png');
        }
      }
    }
    &.future{
      border-left: 7px solid #f4a923;
      &:hover{
        background-color:#f4a923;
        a{
          color: #FFF;
        }
      }
      &.active{
        background-color:#f4a923;
        &:after{
          background-image: url('../images/triangle-previstas.png');
        }
      }
    }
    &:before{
      background-image: none;
    }
  }
}