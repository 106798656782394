.subportada {
  h1{
     border-left: solid 9px $primary-color;
    margin: rem-calc(60 0 40);
    padding-left: rem-calc(10);
    font-size: rem-calc(36);
    line-height: rem-calc(44);
  }
  h2{
    margin:0;
  }
  .noticias{
    .noticia2{
      margin-top: rem-calc(35);
    }
    .imagen{
      margin-bottom: rem-calc(12);
    }
    @media #{$tablet-up} {
      .imagen{
        float:left;
        max-width:50%;
        padding-right:rem-calc(30);
      }
      .texto{
        float:left;
        max-width:50%;
        padding-right:rem-calc(25);
        &.noimage{
          float:none;
          max-width: 100%;
           padding-right:0;
        }
      }
      .noticia2{
         margin-top: 0;
        .imagen{
          padding-left:rem-calc(25);
          padding-right:rem-calc(10);
        }
        .texto{
          padding-left:rem-calc(20);
        }
      }
    }
    .meta{
      text-transform: uppercase;
      font-size: rem-calc(14);
      margin-bottom: rem-calc(6);
      @media #{$tablet-up} {
        margin-bottom: rem-calc(12);
      }
    }
    h3{
      font-size:rem-calc(21);
      line-height: 1.4;
      a{
        color:#000;
      }
    }
    p{
      color:#6e797a;
    }
  }
  .bloque{
    margin:rem-calc(40 0);
    display: block;
  }
  .destacado-fila{
    padding: rem-calc(28 36);

    overflow: hidden;
    margin:rem-calc(40 0);
    @media #{$medium-up} {
      height:250px;
      max-height:251px;
    }
    h2{
      font-size: rem-calc(24);
      letter-spacing: 1.4px;
      max-width:184px;
      margin-right:rem-calc(40);
      margin-bottom:rem-calc(20);
      float:left;
      margin-top:0;
      line-height: 1.3;
    }
    ul{
      float:left;
      max-width:350px;
      font-size: rem-calc(21);
      line-height: 1.32;
      margin:0;
      li{
        list-style: none;
      }
    }
  }
  .centro-universitario{
    background-color: $primary-color;
    @media #{$tablet-up} {
      background: $primary-color url('img/fondo-centro-universitario-san-rafael-nebrija.jpg') no-repeat 300% 0;
    }
    @media #{$medium-up} {
      background-position:  140% 0;
    }
    @media #{$large-up} {
      background-position: top right;
    }
    h2{
      color:#fff;

    }
    a{
      color:#fff;
    }
    ul{
      font-size: rem-calc(22);
      li{
        margin-bottom:rem-calc(20);
        a{
          position: relative;
          padding:rem-calc(0 0 3 20);
          @include single-transition();
          border:solid 1px transparent;
          display: inline-block;

          &:before{
            @extend .kland-caixa-blanc;
            content:'';
            position:absolute;
            left:0;
            top:11px;
            @include single-transition();
          }
          &:after{
            content:'';
            position:absolute;
            left:rem-calc(20);
            bottom:0;
            height: 1px;
            width:0;
            background-color: transparent;
            @include single-transition();
          }
          &:hover{
             //border:solid 1px #fff;
            &:after{
              //left:calc(100% - 20px);
              background-color: $white;
              width:calc(100% - 20px);

            }
          }
        }
      }
    }
  }
  /*.escuela-hospitalidad{*/
  .formacion-profesional {
    background-color: #f9f2e6;
    @media #{$tablet-up} {
      background: #f9f2e6 url('img/fondo-formacion-profesional.png') no-repeat 300% 0;
      height:249px;
      max-height:249px;
    }
    @media #{$medium-up} {
      background-position:  140% 0;
    }
    @media #{$large-up} {
      background-position: top right;
    }
    a{
        color:$body-font-color;
    }
    h2{
      color:$body-font-color;
    }

    ul{
      li{
        margin-bottom:rem-calc(10);
        a{
          position: relative;
          padding:rem-calc(0 0 3 20);
          @include single-transition();
          display: inline-block;
          &:before{
            @extend .kland-caixa-gris;
            content:'';
            position:absolute;
            left:0;
            top:6px;
            @include single-transition();
          }
          &:after{
            content:'';
            position:absolute;
            left:rem-calc(20);
            bottom:0;
            height: 1px;
            width:0;
            background-color: transparent;
            @include single-transition();
          }
          &:hover{

            &:after{
               background-color: $body-font-color;
               width:calc(100% - 20px);
            }
          }
        }
      }
    }

  }
  .mision-valores{
    background-color: #f9f2e6;
    height:auto!important;
    max-height:700px;
    @media #{$medium-up} {
      background: #f9f2e6 url('img/fondo-mision-valores.jpg') no-repeat 140% 0;
      height:249px;
      max-height:249px;
    }
    @media #{$large-up} {
      background-position: top right;

    }
    a{
        color:$body-font-color;
    }
    h2{
      color:$body-font-color;
      max-width: 300px;
      margin-right:rem-calc(75);
    }

    ul{
      li{
        margin-bottom:rem-calc(20);
        a{
          position: relative;
          padding:rem-calc(0 0 3 20);
          @include single-transition();
          display: inline-block;
          &:before{
            @extend .kland-caixa-gris;
            content:'';
            position:absolute;
            left:0;
            top:6px;
            @include single-transition();
          }
          &:after{
            content:'';
            position:absolute;
            left:rem-calc(20);
            bottom:0;
            height: 1px;
            width:0;
            background-color: transparent;
            @include single-transition();
          }
          &:hover{

            &:after{
               background-color: $body-font-color;
               width:calc(100% - 20px);
            }
          }
        }
      }
    }

  }
  .grupos-investigacion{
    background: #f9f2e6;
    margin-top: 0;
    @media #{$tablet-up} {
      background: #f9f2e6 url('img/fondo-investigacion-grupos') no-repeat top right;
    }
    h2{
      color:$body-font-color;
      max-width: 500px;
      margin-right:rem-calc(75);
      a{
        color:$body-font-color;
        position: relative;
          padding:rem-calc(0 0 3 0);
          @include single-transition();
          display: inline-block;
          &:after{
            content:'';
            position:absolute;
            left:rem-calc(0);
            bottom:0;
            height: 1px;
            width:0;
            background-color: transparent;
            @include single-transition();
          }
          &:hover{

            &:after{
               background-color: $body-font-color;
               width:calc(100% - 0px);
            }
          }
      }
    }
  }
  .destacado-columna{
    font-size: rem-calc(24);
    letter-spacing: 1.4px;
    color:#fff;
    background-color: $primary-color;
    display: block;
    line-height: 1.25;
    position:relative;
    @include single-transition();
    span{
      max-width: 275px;
      padding: rem-calc(17 35);
      display: block;
    }
    @media #{$tablet-up} {
      min-height: 156px;
      span{
        display: block;
        max-width: 275px;
        padding: rem-calc(17 35);
        position:absolute;
        top:0;
        left:0;
        z-index:2;
      }
    }
    &:after{
      @include single-transition();
      content:'';
      position:absolute;
      left:0;
      top:0;
      height: 100%;
      width:0;
      background-color: transparent;
      z-index:1;
      opacity: 0;
      @media #{$tablet-up} {
        min-height: 156px;
      }
    }
    &:hover{
      &:after{
        background-color: #000;
        width:100%;
        opacity: .3;
      }
    }
    &.actividades-difusion-conocimiento{
      background: $tertiary-color;
      margin-bottom:rem-calc(40);
      @media #{$tablet-up} {
        margin-bottom: 0;
        background: $tertiary-color url('img/fondo-actividades-culturales.jpg') no-repeat top right;
      }
      &:hover{
        background-position: 110% 110%;
      }
    }
    &.formacion-investigacion{
      background: $primary-color;
      @media #{$tablet-up} {
        background: $primary-color url('img/fondo-formacion-investigacion.jpg') no-repeat top right;
      }
      &:hover{
        background-position: 110% 110%;
      }
    }
    &.presentacion-objetivos{
      background: $tertiary-color;
      @media #{$tablet-up} {
        background: $tertiary-color url('img/fondo-presentacion.jpg') no-repeat top right;
      }
      &:hover{
        background-position: 110% 110%;
      }
    }
    &.saludo-director{
      background: $primary-color;
      margin-bottom:rem-calc(40);
      @media #{$tablet-up} {
        margin-bottom: 0;
         background: $primary-color url('img/fondo-saludo-director.jpg') no-repeat top right;
      }
      &:hover{
        background-position: 110% 110%;
      }
    }
    &.estructura{
      background: $tertiary-color;
      margin-bottom:rem-calc(40);
      @media #{$tablet-up} {
        margin-bottom: 0;
        background: $tertiary-color url('img/fondo-estructura.jpg') no-repeat top right;
      }
       &:hover{
        background-position: 110% 110%;
      }
    }
    &.localizacion{
      background: $primary-color;
      @media #{$tablet-up} {
        background: $primary-color url('img/fondo-localizacion.jpg') no-repeat top right;
      }
      &:hover{
        background-position: 110% 110%;
      }
    }

    &.convocatorias-financiacion{
      background: $primary-color;
      @media #{$tablet-up} {
        background: $primary-color url('img/fondo-investigacion-financiacion.jpg') no-repeat top right;
      }
      &:hover{
        background-position: 110% 110%;
      }
    }
    &.formacion{
      background: #f9f2e6;
      color:#4a4a4a;
      @media #{$tablet-up} {
        background: #f9f2e6 url('img/fondo-investigacion-comision.jpg') no-repeat top right;
      }
      &:hover{
        background-position: 110% 110%;
      }
    }
    &.redes-investigacion{
      background: $tertiary-color;
      margin-bottom:rem-calc(40);
      @media #{$tablet-up} {
        margin-bottom: 0;
        background: $tertiary-color url('img/fondo-investigacion-redes.jpg') no-repeat top right;
      }
      &:hover{
        background-position: 110% 110%;
      }
    }
    &.servicios{
      background: $primary-color;
      margin-bottom:rem-calc(40);
      @media #{$tablet-up} {
        margin-bottom: 0;
        background: $primary-color url('img/fondo-investigacion-servicios.jpg') no-repeat top right;
      }
      &:hover{
        background-position: 110% 110%;
      }
    }
    &.comision-investigacion{
      background: $tertiary-color;
      @media #{$tablet-up} {
        background: $tertiary-color url('img/fondo-investigacion-formacion.jpg') no-repeat top right;
      }
      &:hover{
        background-position: 110% 110%;
      }
    }
  }
  .introduccion{
    p, ul{
      font-size: rem-calc(23);
    }
    margin-bottom: rem-calc(50);
  }
  .introduccion{
    &.normal{
      p, ul{
        font-size: 1em;
      }
    }
  }
  &.fundacion h1{
    margin-bottom:rem-calc(20);
  }

}