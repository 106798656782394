.biografia{
  .boton{
    text-align:center;
    a{
      margin-top: 0;
    }
  }
}


.timeline {
  list-style: none;
  position: relative;
  max-width: 1200px;
  padding:0 20px 50px;
  margin: 0 auto;
  overflow: hidden; }

  .timeline:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -2px;
    background-color: #37c0cc;
    height: 100%;
    width: 7px;
    border-radius: 2px;
    display: block; }
  .timeline .timeline-row {
    padding-left: 50%;
    position: relative;
     min-height: 150px;
    z-index: 1; }
    .timeline .timeline-row .timeline-time {
      position: absolute;
      right: 50%;
      top: 0;

      text-align: center;
      margin-right: -44px;
      font-size: rem-calc(24);
      line-height: 1.3;
      font-weight: 900;
      width: 84px;
      height: 84px;
      color:#fff;
      background-color:$primary-color;
      border-radius:100%;
      span{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1;
      }

    }
      .timeline .timeline-row .timeline-time small {
        display: block;

        text-transform: uppercase;
        opacity: 0.75;
        font-size: .9em;
        font-weight: 400; }

    .timeline .timeline-row .timeline-content {
      margin-left: 70px;
      position: relative;
      padding:5px 0 0;

      color: #333333; }
      /*.timeline .timeline-row .timeline-content:after {
        content: "";
        position: absolute;
        top: 48px;
        left: -41px;
        height: 4px;
        width: 40px;
        background-color: #997900;
        z-index: -1; }*/
      .timeline .timeline-row .timeline-content .panel-body {
        padding: 15px 28px;
        background-color: #f1f1f1;
        position: relative;
        min-height: 74px;
        z-index: 10; }
      .timeline .timeline-row .timeline-content h2 {
        font-size: 22px;
        margin-bottom: 12px;
        margin-top: 0;
        line-height: 1.2; }
      .timeline .timeline-row .timeline-content p {
        margin-bottom: 0;
        line-height: 1.5;
        font-size:1em; }
      .timeline .timeline-row .timeline-content strong {
        color:#D80A0A;
         }
      .timeline .timeline-row .timeline-content img {
        margin-bottom: 15px;max-width:200px;margin-right:20px; }
      .timeline .timeline-row .timeline-content blockquote {
        border-color: #eeeeee; }
        .timeline .timeline-row .timeline-content blockquote footer, .timeline .timeline-row .timeline-content blockquote small, .timeline .timeline-row .timeline-content blockquote .small, .timeline .timeline-row .timeline-content blockquote.blockquote-reverse footer, .timeline .timeline-row .timeline-content blockquote.blockquote-reverse small, .timeline .timeline-row .timeline-content blockquote.blockquote-reverse .small {
          color: #999999; }
      .timeline .timeline-row .timeline-content .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        margin-bottom: 15px;
        overflow: hidden; }
        .timeline .timeline-row .timeline-content .video-container iframe, .timeline .timeline-row .timeline-content .video-container object, .timeline .timeline-row .timeline-content .video-container embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
    .timeline .timeline-row:nth-child(odd) {
      padding-left: 0;
      padding-right: 50%; }
      .timeline .timeline-row:nth-child(odd) .timeline-time {
        right: auto;
        left: 50%;
        text-align: left;
        margin-right: 0;
        margin-left: -44px;

      }
      .timeline .timeline-row:nth-child(odd) .timeline-content {
        margin-right: 70px;
        margin-left: 0;
      }
        .timeline .timeline-row:nth-child(odd) .timeline-content:after {
          right: -12px;
          top:30px;
          content: '';
          position:absolute;
          @extend .triangle-cronologia-esquerra;

        }
        .timeline .timeline-row:nth-child(even) .timeline-content:after {
          left: -12px;
          top:30px;
          content: '';
          position:absolute;
          @extend .triangle-cronologia-dreta;

        }
  .timeline.animated .timeline-row .timeline-content {
    opacity: 0;
    left: 20px;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    transition: all 0.8s; }
  .timeline.animated .timeline-row:nth-child(odd) .timeline-content {
    left: -20px; }
  .timeline.animated .timeline-row.active .timeline-content {
    opacity: 1;
    left: 0; }
  .timeline.animated .timeline-row.active:nth-child(odd) .timeline-content {
    left: 0; }

@media (max-width: 750px) {
  .timeline {
    padding: 15px 10px; }
    .timeline:after {
      left: 28px; }
    .timeline .timeline-row {
      padding-left: 0;
      margin-bottom: 16px; }
      .timeline .timeline-row .timeline-time {
        position: relative;
        right: auto;
        top: 0;
        text-align: left;
        margin: 0 0 6px 56px; }
        .timeline .timeline-row .timeline-time strong {
          display: inline-block;
          margin-right: 10px; }

      .timeline .timeline-row .timeline-content {
        margin-left: 56px;
        //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        position: relative; }
        /*.timeline .timeline-row .timeline-content:after {
          right: auto !important;
          left: -20px !important;
          top: 32px; }*/
      .timeline .timeline-row:nth-child(odd) {
        padding-right: 0; }
        .timeline .timeline-row:nth-child(odd) .timeline-time {
          position: relative;
          right: auto;
          left: auto;
          top: 0;
          text-align: left;
          margin: 0 0 6px 56px; }
        .timeline .timeline-row:nth-child(odd) .timeline-content {
          margin-right: 0;
          margin-left: 55px; }
    .timeline.animated .timeline-row:nth-child(odd) .timeline-content {
      left: 20px; }
    .timeline.animated .timeline-row.active:nth-child(odd) .timeline-content {
      left: 0; }

      .timeline .timeline-row:nth-child(odd) .timeline-content:after {
          right: 0;


        }
        .timeline .timeline-row:nth-child(even) .timeline-content:after {
          left: 0;


        }
}