.colabora{
  #breadcrumbs p .home a{
     margin: 0;
  }
  .colabora-wrapper{
    position: relative;
    width: 100%;
  }
  .imagen-superior {
    @media #{$tablet-up} {
      img{height: 750px;min-width:1600px;}
    }
    max-height: 750px;
    overflow: hidden;
  }
  .colabora-contenido{
    background-color:#fff;
    background: rgba(255,255,255,0.6);
    padding: rem-calc(15);
    @media #{$tablet-up} {
      width:80%;
      max-width:800px;
      margin:0 auto;
      position:absolute;
      left:50%;
      top:55px;
      z-index: 1;
      transform: translateX(-50%);
      padding: rem-calc(30 50);
    }
    @media #{$medium-up} {
      top:75px;
    }
    @media #{$large-up} {
      top:90px;
    }
    h1{
      color:#000;
      text-align: center;
      text-shadow: none;
    }
    p{
      color:#000;
      &.introduccion{
        font-size: rem-calc(20);
      }
      &.numero-cuenta{
        font-size: rem-calc(22);
        color:$primary-color;
        position: relative;
        display: inline-block;
        padding:rem-calc(16 35);
        margin:0;
        line-height: 1;
        background-color:#fff;
        @media #{$tablet-up} {
          font-size: rem-calc(35);
          &:before{
            content: '[';
            position: absolute;
            left:-5px;
            top:-9px;
            font-size: rem-calc(76);
            line-height: 1;

          }
          &:after{
            content: ']';
            position: absolute;
            right:-5px;
            top:-9px;
            font-size: rem-calc(76);
            line-height: 1;

          }
        }

      }
      &.nota{
        font-size: rem-calc(12);
        margin: rem-calc(13 0 50);

      }
      &.final{
        font-size: rem-calc(14);
        text-align: center;

      }
      &.dest{
        text-align:center;

        a{
          display: inline-block;
          border:none;
          border-radius:0;
          color:#fff;
          background-color: $primary-color;
          box-shadow:none;
          max-width: 200px;
          font-size: rem-calc(20);
          font-weight: normal;
          line-height: 1.3;
          &:before{
            content:none;
          }
          &:hover{
            background-color: darken($primary-color,10%);
          }
        }
      }
    }
  }
  .main-titol{
    margin-bottom:0;
  }
  .main-footer{
    margin-top:0!important;
  }
}