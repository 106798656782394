.top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  @media #{$tablet-up} {
     bottom: 70px;
  }
  text-align: center;
  text-transform: uppercase;
  color: $base;
  padding-top: 1.5em;
 display:none;
 color:#000;
 z-index:10;
  &:before {
    content: '';
    @extend .top;
    display: block;
  }

  &:hover {
    border: 0;
    color: $tuatara;
  }
}
