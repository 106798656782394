.actualidad {
  h2{
    color:#000;
    margin-bottom:rem-calc(25);
    font-size: rem-calc(20);
    padding-bottom:rem-calc(9);
    position:relative;
    display: inline-block;
    &:after{
      content: '';
      position:absolute;
      height: 3px;
      width: 30px;
      background-color: $primary-color;
      bottom:0;
      left:0;
    }
  }
  .todas{
      position:relative;
      padding-right:rem-calc(25);
      text-transform: uppercase;
      font-size: rem-calc(14);
      float: right;
      &:after{
        content: '';
        display: block;
        position:absolute;
        top:1px;
        right: 0;
        width: 17px;
        height: 18px;
       //background: url('img/icona-mas.svg') no-repeat 0 0;
        background-color: $body-font-color;
        -webkit-mask-image: url('img/icona-mas.svg');
        mask-image: url('img/icona-mas.svg');
        opacity: .6;
        @include single-transition();
      }
      &:hover{
        &:after{
          transform: rotate(90deg);
          background-color: darken($primary-color, 5%);
          opacity: 1;
        }
      }
    }
}

.actualidad-noticias{
  position:relative;



  .noticiaDestacada{
    article{
      @include grid-column(12);
      padding:0;
      margin-bottom: rem-calc(50);
      img{
        margin-bottom: rem-calc(10);
        width: 100%;
      }
      .meta{
        font-size: rem-calc(11);
         margin-bottom: rem-calc(7);
      }
      @media #{$large-up} {
        h3{
          font-size: rem-calc(30);
          float:left;
          width: 50%;
          padding-right: rem-calc(12);
          @include single-transition();
        }
        p{
          float:left;
          width: 50%;
          padding-left: rem-calc(12);
        }
        .meta{
          font-size: rem-calc(14);

        }
      }
      a.noticia{
        display: block;
         div{
          @include grid-row();
          margin-bottom: rem-calc(15);
         }
         svg{
          @include single-transition();
         }
         p{
          color:$body-font-color;
         }
         &:hover{
           h3{
            color: $primary-color;
           }
           svg{
            fill:$primary-color;
           }
         }
      }
    }
  }
  .mas-noticias{
    border-bottom: solid 1px #c2c2c2;
    margin-bottom:rem-calc(6);
    @media #{$small-only} {
      li{
        width:100%;
      }
    }
    article{
      /*@include grid-column(6);
      margin-bottom: rem-calc(30);
      &:nth-child(odd) {
          padding:rem-calc(0 10 0 0);
      }
      &:nth-child(even) {
          padding:rem-calc(0 0 0 10);
      }*/
      img{
        margin-bottom: rem-calc(10);
        width: 100%;
      }
      h3{
        font-size: rem-calc(16);
        color:#000;
        a{
          color:#000;
          &:hover{
            text-decoration: underline;
          }
        }
      }
      .meta{
        font-size: rem-calc(11);
         margin-bottom: rem-calc(7);
      }
      p{
        display: none;
      }
      @media #{$large-up} {
        h3{
          font-size: rem-calc(21);
        }
        .meta{
          font-size: rem-calc(14);

        }
        p{
          display: block;
        }
      }
    }
  }
}
.listado{
  li{
    margin-bottom: rem-calc(40);
    a.noticia{
      color:$body-font-color;
      &:hover{
         h3{
          color: $primary-color;
         }
         svg{
          fill:$primary-color;
         }
       }
    }
  }
}
.actualidad-agenda{
  position:relative;
  margin-top: rem-calc(30);
  .agenda-mas{
    margin-top: rem-calc(6);
    text-align:right;
  }
  @media #{$tablet-up} {
    margin-top: 0;
  }
  @media #{$medium-up} {
    padding: rem-calc(0 30);
  }
  article{
    @include grid-column(12);
    position:relative;
    padding: rem-calc(20 0);

    &.first{
      padding-top: 0;
    }
    &:after{
      content: '';
      position:absolute;
      display: block;
      height: 1px;
      width:calc(100%);
      bottom:0;
      border-bottom: solid 1px #c2c2c2;
    }
    img{
      margin-bottom: rem-calc(20);
    }
    h3{
      font-size: rem-calc(18);
      color:#000;
      margin-top:0;
      max-width: 90%;
       @media #{$large-up} {
         font-size: rem-calc(22);
       }
       a{
        color:#000;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .fecha{
      margin-bottom:rem-calc(8);
      @media #{$medium-up} {
         width:80px;
         text-align: center;
         padding:0;
         float:left;
      }

       span{
        display: block;
        line-height: .95;
       }
       .dia{
          font-size: rem-calc(28);
          margin:0 0 0.1em;
          font-weight: 900;
          color: $primary-color;

          @media #{$large-up} {
            font-size: rem-calc(60);
          }
       }
       .mes{
          font-size: rem-calc(12);
          text-transform: uppercase;
          color:#000;
       }
    }

    @media #{$medium-up} {
      .texto{
        float:left;
        width: calc(100% - 80px);
        padding-left:25px;
      }
    }

  }
  .enlaces-actualidad{
      @include grid-column(12);
      padding:0;
      margin-top:rem-calc(45);
      @media #{$medium-up} {
        div{
          float:left;
          width:50%;
          padding-left:10px;
          a{
          min-height: 120px;
          }
          &:nth-child(odd){
            padding-left:0;
             padding-right:10px;
          }
        }
      }
      .ample {
        width:100%!important;
        &:nth-child(odd){
           padding-left:0;
           padding-right:0;
        }
        a {
          background-color: #f29e4c;
          &:hover {
            background-color: #E07810;
          }
        }
      }
      /*.prensa {
        a {
          background-color: #37c0cc;
          &:hover {
            background-color: #2DA7B0;
          }
        }
      }
      .colabora {
        a {
          background-color: #37c0cc;
          &:hover {
            background-color: #2DA7B0;
          }
        }
      }*/
    }
}
.noticia, .agenda{
  .main-titol.c{
    //background-image: url('/dev/diagonal-stripes.svg');
    background-image:none;

    @media #{$medium-up} {
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
    }
    min-height: 225px;
    .cabecera{
      min-height: auto;
      background:rgba($primary-color,.8);

      .titulo{
        position:relative;
        h1{
          max-width:950px;
        }
        @media #{$small-only} {
          h1{
            font-size: rem-calc(30);
          }
          .fecha span{
            font-size: rem-calc(16);
          }
        }
      }
    }

  }
  .otras-noticias{
    position: absolute;
    bottom:20px;
    color:#fff;
    right:20px;
    padding-bottom: rem-calc(3);
    @media #{$small-only} {
      display: none;
    }
    &:before{
      content: '←';
      display: inline;
      font-weight: bold;
      padding-right:5px;
    }
    &:after{
      content: '';
      position:absolute;
      width: 0;
      height: 2px;
      background-color:#fff;
      bottom:0;
      left:0;
      @include single-transition();
    }
    &:hover{
      &:after{
        width: 100%;
      }
    }
  }
}
.agenda{
  #breadcrumbs p .home a{margin-bottom:0;}
  figure{
    margin:2rem 0 0;
  }
  .otros-actos{
    color:$primary-color;
    padding-left:0;
    padding-bottom: rem-calc(3);
    position:relative;
    @media #{$small-only} {
      display: none;
    }
    &:before{
      content: '←';
      display: inline;
      font-weight: bold;
      padding-right:5px;
    }
    &:after{
      content: '';
      position:absolute;
      width: 0;
      height: 2px;
      background-color:$primary-color;
      bottom:0;
      left:0;
      @include single-transition();
    }
    &:hover{
      &:after{
        width: 100%;
      }
    }
  }
  .fecha-agenda{
    border:$primary-color 10px solid;
    padding:rem-calc(20);
    margin-top: rem-calc(8);
    span{
      display: block;
      line-height: .95;
     }
     .dia{
        font-size: rem-calc(28);
        margin:0 0 0.1em;
        font-weight: 900;
        color: $primary-color;

        @media #{$large-up} {
          font-size: rem-calc(80);
        }
     }
     .mes{
        font-size: rem-calc(12);
        text-transform: uppercase;
        color:#000;
        @media #{$large-up} {
          font-size: rem-calc(30);
        }
     }
     dl{
        margin-top: rem-calc(30);
        dt{
          padding-left:rem-calc(35);
        }
        dd{
          margin-bottom: rem-calc(5);
          padding-left:rem-calc(35);
          line-height: 1.2;
        }
     }
  }

}
.icons-agenda{
   position:relative;
   &:before{
      content: '';
      position:absolute;
      display: block;
      width: 25px;
      height: 25px;
      top:5px;
      left:0;
    }
    &.icon-fecha{
      &:before{
        background: url('img/fecha.svg') no-repeat 0 0;
      }
    }
    &.icon-hora{
      &:before{
        background: url('img/hora.svg') no-repeat 0 0;
      }
    }
    &.icon-lugar{
      &:before{
        background: url('img/lugar.svg') no-repeat 0 0;
      }
    }
}

/*
.news {
  .section-header {
    margin-bottom: 0;
  }
}

.meta {
  padding: 1.25rem 0;

  p {
    border-bottom: 1px solid $iron;
    padding-bottom: 1rem;
    margin-bottom: 0;
  }

  @media #{$small-up} {
    font-size: rem-calc(15);
  }

  @media #{$medium-up} {
    font-size: rem-calc(16);
  }

  &.back {
    a {
      &:before {
        content: '\2190  ';
      }
    }
  }
}

.read-more {
  @include button($padding: rem-calc(5), $radius: 5px);
  font-size: rem-calc(12);

  @media #{$medium-up} {
    font-size: rem-calc(15);
  }

  &:hover {
    border: 0;
  }

  span {
    display: none;
  }
}


// llistat notícies
.list {

  article {
    margin-bottom: 2rem;

    img {
      margin-bottom: 1.5rem;
    }

    h2 {
      font-size: rem-calc(24);
    }

    @media #{$small-up} {
      h2 {
        font-size: rem-calc(28);
      }

      img {
        width: 175px;
        float: right;
        margin-left: 1.5rem;
      }
    }

    @media #{$medium-up} {
      img {
        width: auto;
      }
    }

    @media #{$large-up} {
      h2 {
        font-size: rem-calc(29);
      }
    }

    @media #{$xlarge-up} {
      h2 {
        font-size: rem-calc(34);
      }
    }
  }
}


// fitxa noticia
.view {
  article h1 {
    font-size: rem-calc(30);
    color: $primary-color;
    margin-bottom: 1rem;

    @media #{$small-up} {
      font-size: rem-calc(33);
    }

    @media #{$medium-up} {
      font-size: rem-calc(30);
    }

    @media #{$large-up} {
      font-size: rem-calc(32);
    }

    @media #{$xlarge-up} {
      font-size: rem-calc(34);
    }
  }

  .lead {
    font-size: rem-calc(16);
    color: $black;

    @media #{$medium-up} {
      font-size: rem-calc(17);
      border-bottom: 1px solid $border;
      padding-bottom: rem-calc(15);
    }

    @media #{$xlarge-up} {
      font-size: rem-calc(19);
    }
  }

  article .share {
    border: 1px solid $iron;
    border-width: 1px 0;
    margin: 1.5rem 0 2rem;
    padding: rem-calc(15 0);

    a {
      margin: 0 0.25rem;
      color: $primary-color;
    }
  }

  .fotos,
  .text {
    @include grid-column($columns: 12);
  }

  @media #{$small-up} {
    article .share {
      a {
        margin: 0 1rem;
      }
    }
  }

  @media #{$medium-up} {
    article .share {
      padding-top: 0;
      border-top: 0;
      margin-top: 0.5rem;
      text-align: left;

      a {
        margin: 0 0.4rem 0 0;
      }
    }

    .fotos {
      @include grid-column($columns: 5, $push: 7);
    }

    .text {
      @include grid-column($columns: 7, $pull: 5);
    }

  }

  @media #{$large-up} {
    article .share {
      a {
        margin: 0 2rem 0 0;
      }
    }
  }
}
*/

