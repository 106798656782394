.hidden {
    position: absolute;
    left: 0;
    top: -500px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
#breadcrumbs{
  @include grid-row();
  p{
     @include grid-column(12);
     font-size: rem-calc(13);
     color:#fff;
     margin:rem-calc(13) 0;
     a{
      color:#fff;
     }
     .divisor{
       display: inline-block;
       @extend .icon-breadcrumb;
       margin:0 rem-calc(12);
     }
     .home a{
      display: inline-block;
      margin:0;
      position: relative;
      padding-left:rem-calc(20);
      @media #{$tablet-up} {
        margin:0 0 rem-calc(12) 0;
      }
        &:after{
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top:3px;
          @extend .icon-breadcrumb-home;
        }
     }
     span.last{
      display: none;
     }
  }
}
hr.line{
  display:none;
  @media #{$tablet-up} {
    display:block;
    border-color:#c9c9c9;
    margin-top:rem-calc(13);
    margin-bottom:rem-calc(35);
  }
}
///desplegables menú accessibles
.filtros{
  text-align: left;
  margin-bottom: rem-calc(12);
  @media #{$medium-up} {
    text-align: right;
    padding-top: rem-calc(15);
  }
  button{
    margin:0;
    outline:0;
    padding:0 rem-calc(25) rem-calc(5) 0;
    background-color:#fff;
    position:relative;
    font-size: rem-calc(14);
    &:hover,&:focus{
      color:#000;
    }
    &:after{
      content:'';
      @extend .filter-arrows-down;
      display: block;
      position:absolute;
      right:0;
      top:50%;
      margin-top:-$filter-arrows-down-height/2;
      @include single-transition;
    }
    &.open{
      color:#000;
      &:after{
        transform: rotate(-180deg);
      }
    }

  }

}
.desplegable{
  [aria-haspopup="true"] {
      position: relative;
  }
  ul[aria-hidden="true"] {
      display: none;
  }
  [aria-haspopup="true"] + ul[aria-hidden="false"] {
      margin: 0;
      display: block!important;
      position: absolute;
      z-index: 100;
      min-width:200px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.2);
      background-color: #efefef;
      border: 1px solid $iron;
      border: 1px solid rgba(0,0,0,.2);
      list-style:none;
      text-align: left;
      padding:0;
      li{
        padding:0;
        margin:0;
        //border-bottom: 1px solid $iron;
        &:before{
          content: none;
        }
        a{
           padding:rem-calc(10 22);
           display: block;
           color:#000;
           &.active{
            background-color:#444;
            color:#fff;
           }
           &:hover{
              background-color:$primary-color;
              color:#fff;
           }
        }
      }

  }
  &.categorias [aria-haspopup="true"] + ul[aria-hidden="false"] {
      //right: 0;
      left:0;
      @media #{$medium-up} {
        //right: 60px;
        right: 15px;
      }
  }



}

/* 3. Progressive collapsibles */
.contenido {
  .collapsible {
    border-bottom: solid 1px #979797;
    margin:2em 0;
    h3{
      margin:0;
    }
    button{
      font-size: rem-calc(17);
      margin: 0;
      border-top: solid 1px #979797;
      padding:1em 1em 1em 2.75em;
      background-color:transparent;
      outline:0;

      display: block;
      width: 100%;
      text-align: left;
      &:hover,&:active,&:focus{
        color:$body-font-color;
      }
      &:focus{
        background-color: #efefef;
      }
    }
    div{
      padding:0 1em;
    }
    h3 [aria-expanded]:before {
      //@extend .acordio-desplega;
      position: absolute;
      content: '';
      left: 10px;
      top: 15px;
    }

    h3 [aria-expanded="true"]:before {
      //@extend .acordio-plegar;
    }
    h3 + [aria-hidden] {
      display: none;
    }

    h3 + [aria-hidden="false"] {
      display: block;
    }
    &.fons{
      border:none;
      margin-top:1.5em;
      h3{
        margin-top:0.5em;
      }
      button{
        background-color: $light-gray;
        border:none;
        &:focus{
          background-color: $light-gray;
          outline: 1px $primary-color dashed;
        }
      }
      div{
         background-color: $light-gray;
         padding-bottom:1em;
      }
    }
  }
  h2.b + .collapsible {
    margin-top: 1em!important;
  }

}

.comparteix{
     margin-top: rem-calc(32);
    .titol{
        font-size:rem-calc(14);
        position:relative;
        padding-bottom:7px;
        margin-bottom:11px;
        &:after{
            content:'';
            position:absolute;
            bottom:0;
            left:0;
            width: 120px;
            height: 2px;
            background-color:#bbbbbb;
        }
    }
    ul{
        @include inline-list;
        li{
             padding-left:0;
            &:before{
              content: none;
            }
          }
        a{
          //@extend .logo-peu-int-facebook;
          display: inline-block;
          background-color:transparent;
          padding: 0;
          margin-top:3px;
          span{
             @include visually-hidden;
          }
          &.facebook{
            //@extend .share-facebook;
          }
          &.twitter{
            //@extend .share-twitter;
          }
          &.linkedin{
            //@extend .share-linkedin;
          }
          &:focus{
            background-color: transparent;
            //border:solid 1px #;
            border:none;
            //outline:#ffbf47 solid 3px;
          }
          &.external:after{
            content: none;
          }

        }
      }

}
.contenido{
  .bloques{
    margin:rem-calc(50) 0 rem-calc(75);
    text-align: center;
    img{
      margin-bottom: rem-calc(32);
    }
    h2{
      color:$primary-color;
      border:none;
      padding-left:0;
      font-size: rem-calc(18);
      text-transform: uppercase;

      //@include single-transition();


    }
    p,ul{
      text-align:left;
    }
    .columns{
      padding-right: rem-calc(25);
    }
    &.mensaje{
      text-align:center;
      .columns{
        padding-top:rem-calc(40);
      }
      h2{
        color:#000;
        margin-bottom: rem-calc(20);
        position:absolute;
        text-align:center;
        width: 100%;
        top:-40px;
        z-index: 1;
        @include single-transition();

      }
      .imagen{
        position:relative;
        width: 100%;
        display: block;
        .wrap{
          width: 144px;
          height: 144px;
          overflow: hidden;
          border-radius:144px;
          transition: all .3s linear;
          overflow:hidden;
          margin:0 auto rem-calc(35);
          -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
          @media #{$tablet-up} {
            margin-bottom: 0;
          }
        }
        &:after{
          content: '';
          display: block;
          position: absolute;
          right: 20px;
          top:0;
          @extend .valores-rodona-mes;
          @include single-transition();
        }
        &.open:after{
          opacity:0;
        }
        img{
         // @include single-transition();
          transition: all .3s linear;

        }
        &:hover,&.open{
          h2{
            color: $primary-color;
            top:-35px;

          }
          img{
            transform: scale(1.5);
          }
        }
      }
      .texto{
        position:absolute;
        top:60%;
        left:0;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
        width: 0;
        background-color:#fff;

        z-index:3;
        padding:rem-calc(15 50 15 25);
        @include single-transition();

        overflow: hidden;
        color:#fff;
        @media #{$tablet-up} {
          left:60%;
          height: 185px;
        }
        .cerrar-mensaje{
          position:absolute;
          top:6px;
          right:6px;
          padding:rem-calc(10);
          img{
            margin:0;
          }
        }
        /*&:before{
           @extend .bocadillo-valores;
           content: '';
            position: absolute;
            left: 0;
            top:0;
            z-index:2;
        }*/
      }
      @media #{$tablet-up} {
        #texto-3{
            left:-50%;
        }
        #texto-4{
            left:-140%;
        }
      }
      @media #{$medium-up} {
        #texto-3{
            left:60%;
        }
        #texto-4{
            left:-1%;
        }
      }
      @media #{$large-up} {
        #texto-4{
            left:60%;
        }
      }
      .texto[aria-hidden]{
        opacity:0;
      }
      .texto[aria-hidden="false"] {
          opacity:1;
          width: 290px;
          @media #{$tablet-up} {
            width: 450px;
          }
          color:$body-font-color;
      }
    }

  }
  ul.listado-personas{
    margin-left:-1.3em;
    li{
      text-align:center;
      img{
        margin-bottom: rem-calc(16);
        max-width: 75px;
        @media #{$tablet-up} {
          max-width: 148px;
        }
      }
      p{
        font-size:rem-calc(16);
        margin-bottom:rem-calc(10);
        color:#6e797a;
      }
      h2,h3{
        font-weight: 900;
        color: $primary-color;
        font-size:rem-calc(16);
        margin-bottom:0;
        border:none;
        padding:0;
      }
      .email{
        display: inline-block;
        margin-bottom:rem-calc(25);
        @extend .icon-email-equipo;
      }
      &:before{
        content: none;
      }
    }
    &.b{
      li{
        text-align: left;
      }
    }
  }
}

.contenido #tabs{

    @include inline-list;
    border-bottom:solid 1px #979797;
    margin-left: 0;
    margin-bottom: rem-calc(35);
    font-size:rem-calc(15);
    text-transform: uppercase;
    position:relative;
    overflow: visible;
    min-height: 46px;
    .circulo{
      left:5px;
      top:50%;
      width:18px;
      height: 18px;
      margin-top:-9px;
    }
    a{
      display: block;
      background-color: #E9E9E9;
      border:solid 1px #E9E9E9;
      border-bottom:none;
      padding:0.5em 0.25em;
      text-align: center;
      position:relative;

      @media #{$tablet-up} {
        //padding:1em 1.25em;
      }
      color:$body-font-color;
      text-decoration: none;
      &:hover{
       background-color: darken(#E9E9E9, 3%);
      }
      &:focus{
        border:none;
        background-color: darken(#E9E9E9, 20%);
        //outline:#ffbf47 solid 3px;
        color:#fff;
      }
      &.active{
        border:solid 1px #979797;
        border-bottom:none;
        background-color: #fff;
        padding:0.5em 0.25em;
        text-align: center;
        /*@media #{$tablet-up} {
          padding:1em 1.25em;
        }*/
         color:$body-font-color;
         &:after{
          content: '';
          position:absolute;
          bottom:-1px;
          left: 0;
          height: 2px;
          z-index: 1;
          width: 100%;
          background-color:#fff;
          display: block;
         }
      }
    }
    li{
      padding:0;
      margin-bottom:0;
      margin-right:0.25em;
      margin-left:0;
      min-width: 125px;
      @media #{$large-up} {
         margin-right:0.5em;
      }

       &:before{
        content: none;
      }
      &:last-child{
        margin-right:0;
      }
    }


}

form{
  label{
    margin-bottom:0.25em;
  }
  textarea{
    height:200px;
    margin: 0 0 1rem;
    font-size:  rem-calc(16);
  }
  textarea[rows] {
    max-height:200px;
    margin: 0 0 1rem;
  }
  button[type=submit]{
    font-size: rem-calc(18);
  }
}

/* botones */
button.leer-mas, p.boton a, a.boton{
  background-color:#fff;
  border: solid 3px $primary-color;
  color:#000;
  display: inline-block;
  margin:rem-calc(34) 0;
  font-size: rem-calc(16);
  padding: rem-calc(10 42 13);
  @include single-transition();
  @media #{$medium-up} {
    font-size: rem-calc(20);
  }
  &:hover{
    background-color: lighten($primary-color, 53%);
  }
}
.boton-publicaciones {
  background-color:#fff;
  border: solid 2px $primary-color;
  color:#000;
  display: inline-block;
  margin:rem-calc(15) 0;
  font-size: rem-calc(14);
  padding: rem-calc(5 20 7);

  @include single-transition();
  @media #{$medium-up} {
    font-size: rem-calc(14);
    float:right;
  }
  &:hover{
    background-color: lighten($primary-color, 53%);
  }
}
.boton-blanco {
    display: block;
    background: $white;
    color: #000000!important;
    font-size: rem-calc(18);
    border-radius:7px;
    padding: rem-calc(7 12);
    font-weight:bold;
    position:absolute;
    @include single-transition();
    min-width: 62px;
    text-align: center;
      &:hover{
          color:#fff!important;
          background:$body-font-color;
      }
}
.boton-azul {
    display: block;
    background: #0099cb;
    color: #fff!important;
    font-size: rem-calc(18);
    border-radius:7px;
    padding: rem-calc(7 12);
    font-weight:bold;
    position:absolute;
    @include single-transition();
    min-width: 62px;
    text-align: center;
      &:hover{
          color:#fff!important;
          background:$body-font-color;
      }

}
button.leer-mas.open{
  border: solid 3px #ccc;
  color:#ccc;
  outline: none;
}
/* leer más */
#mas-informacion[aria-hidden] {
    display: none;
}
#mas-informacion[aria-hidden="false"] {
    display: block;
}
/* videos*/
.flex-video{
  margin-bottom: rem-calc(30);
  iframe{
    max-width: 100%;
  }
}
iframe {
  padding-bottom: rem-calc(30);
}
/*.flex-video,.iframe{
  margin:rem-calc(23) 0 rem-calc(34);
}*/
.contenido{
  .cards{
    margin:0 0 2em -1.3em;
    li{
      list-style: none;
      margin-top:0;
      &:before{
        content:none;
      }
      .card-info{
        padding:rem-calc(20 35 20 20);
        position:relative;
        min-height: 50px;
        @media #{$medium-up} {
          min-height: 350px;
        }
        span.categoria{
          display: block;
          color:#000;
          font-size:rem-calc(12);
          margin-bottom:rem-calc(18);
          svg{
            width:48px;
            height: 48px;
            display: block;
            padding-bottom:rem-calc(5);
            fill: $primary-color;
          }

        }
        .arrow{
          position:absolute;
          bottom:20px;
          right: 0;
          display: block;
          right: 10px;
          fill: $primary-color;
        }
      }
      @media #{$small-only} {
        width:100%;

      }

      a, div.card-box {
        position: relative;
          overflow: hidden;
          display: block;
          width:100%;
          background: white;
          -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.1);
          -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.1);
          color:#000;
          //transition: box-shadow .2s linear 0s,transform .2s linear 0s,background-color .2s linear 0s;
          @include single-transition();
          h2{
          font-size: rem-calc(22);
          line-height: 1.3;
          border:none;
          padding:3px 0;
          color:#000;
          font-weight: 100;
          @include single-transition();
          position:relative;
          &:after{
            content: '';
            display: block;
            height: 100%;
            background-color:$primary-color;
            width: 0;
            position: absolute;
              top: 0;
              left:-60px;
              @include single-transition();
              z-index:-1;
          }
        }
        p{
          color: $body-font-color;
          font-size: rem-calc(14);
        }
        .recomendado {
          margin-bottom: rem-calc(10);
          color: $primary-color;
        }
        &:hover{
             box-shadow: 0 8px 16px 0 rgba(45,45,55,.25);
           transform: translateY(-10px);
           h2{
            color:#fff!important;
            &:after{
              width: 160%;
            }

           }
           .arrow,span.categoria svg{
            fill: #000;
           }
            }
      }
      .card-wrapper{
          position: relative;
          overflow: hidden;
          display: block;
          width:100%;
          background: white;
          -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.1);
          -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.1);
          color:#000;


          .card-titulo{
            background-color:$primary-color;
             padding:rem-calc(10 20);
             h2{
              font-size: rem-calc(22);
              line-height: 1.3;
              border:none;
              padding:0 0 3px;
              color:#fff;
              margin:0
            }


          }
          .card-info {
            h3{
              color:#000;
              margin:0 0 rem-calc(10);
              font-size: rem-calc(22);
              font-weight: 100;
            }
            li{
              padding-left:0;
            }
            .boton{
              text-align:center;
              margin-bottom:0;
               a{
                display: inline-block;
                width:auto;
                margin-bottom:0;
               }
            }
          }
      }



    }
    &.b{
      margin:0 0 2em -1.3em;
      li{
        .imagen{
          max-height: 135px;
          overflow: hidden;
          position:relative;
          img{
            width:100%;
          }
          &:after{
            content:'';
            top:0;
            left:0;
            position:absolute;
            width: 100%;
            height: 100%;
            display: block;
            background:rgba(0,0,0,.23);
            @include single-transition();
          }
        }
        .card-info{
          min-height: 75px;
          @media #{$tablet-up} {
            min-height: 190px;
          }
        }
        a{
            h2{
              font-weight: normal;
            }
            &:hover{
              .imagen{
                &:after{
                  background:rgba(0,0,0,0);
                }
              }
            }
          }
      }
    }
    &.c{
      li{

        .card-image{
           max-height: 248px;
           overflow: hidden;
           img{
            width: 100%;
           }
          @media #{$tablet-up} {
            float:left;
            width:30%;
          }
        }
        .card-info{
            min-height: 248px;
            span.categoria{
              margin-bottom: 0;
              //color:$body-font-color;
            }

        }
        @media #{$tablet-up} {
          .card-info.has-image{
              float:left;
              width:70%;
          }
        }
        a{
            @media #{$medium-up} {
              min-height: 350px;
            }
            h2{
              font-weight: normal;
              margin-bottom: rem-calc(10);
              font-size: rem-calc(22);
              color:$primary-color;
            }
            p{
              margin-bottom: rem-calc(6);
              line-height: 1.3;
              &:last-child{
                margin-bottom: 0;
              }
            }

          }

      }
    }
    &.d{
        margin:0 0 2em 0;
        li{
          padding-left:0;
          margin-top:0;
        }
    }
  }
  p + ul.cards.b{
    margin-top: 2.5em;
  }

}

//flex grid
.flex-grid {
  display: flex;
}
.flex-grid .col {
  flex: 1;
}
.flex-grid-thirds {
  display: flex;
  justify-content: space-between;
}
.flex-grid-thirds .col {
  width: 32%;
  border-right:solid 1px #efefef;
  padding-right:rem-calc(10);
  &:last-child{
    border:none;
  }
}

@media (max-width: 760px) {
  .flex-grid,
  .flex-grid-thirds {
    display: block;
    .col {
      width: 100%;
      margin: 0 0 30px 0;
      border:none;
    }
  }
}


/* mapa provincia */
#mapa{
    margin-bottom: 20px;
    display: none;
    @media #{$tablet-up} {
      display: block;
    }
}
#mapa + p.dest{
padding-top: 20px;
max-width:550px;
  @media #{$tablet-up} {
    margin-bottom: 200px;
  }
}
.centros-js{

  position:absolute;right:0px;top:0px;width:300px;height: 500px;z-index:2;

}
.centros-js .item-provincia-contenedor{background-color:#fff;display:none;width:300px;box-shadow: 0 1px 2px rgba(0,0,0,.15),0 0 1px rgba(0,0,0,.1);padding:10px;}
.centros-js .item-provincia li{width:100%;background-color:#FFF;list-style-type:none;font-size:.75em;list-style: none;padding:0;}
.centros-js .item-provincia li p{font-size: 12px;}
.centros-js .item-provincia li h2{padding:0;border:none;font-size: 14px;margin:0;font-weight:bold;color:#000;}
.centros-js .item-provincia li h3{padding:0;border:none;font-size: 14px;margin:0;color:#0099cb;}
.centros-js .item-provincia li .text{float:left;width:140px;}
.centros-js .item-provincia li:before{content:none;}
.centros-js .item-provincia li .views-field-field-imagen-centro{text-align:center;}
.centros-js .item-provincia li .views-field-title,.centros-js .item-provincia li .views-field-field-localidad{margin:0px 10px;}
.centros-js .item-provincia li img{margin-top:5px;padding-bottom:10px;float:left;padding-right:9px;}
area{outline:0;}



div#pestanyes{
  display: none;
  @media only screen and (min-width:768px) {
    display: block;
  }
  margin: 1em 0 3em;
  ul.tabs{
    border-bottom: 4px solid #EAEAEA;
    li.tab-title{
      padding-left: 0;
      vertical-align: bottom;
      height: 65px;
      a{
        background-color: #FFF;
        padding: 10px 20px;
        height: 100%;
        vertical-align: bottom;
      }
      &:before{
        display: none;
      }

      &.active, &.is-active, &:hover, &:active, &:focus{
        a{
          color:#0099CB;
        }
        &:after{
          content: '';
          height: 4px;
          width: 100%;
          background-color: #0099CB;
          position: absolute;
        }
      }
    }
  }
}

div.tabs-content .content{
  @media only screen and (max-width:768px) {
    display: block;
    float: none;
  }
}


