.contenido{
	.listado-grupos{
		margin-left:-1.3em;
		li{
	      text-align:center;
	      @media #{$small-only} {
        	width:100%;

      	  }
	      a{
	      	padding:rem-calc(5);
	      	display: block;
	      	transition: box-shadow .2s linear 0s,transform .2s linear 0s;
	      	min-height: 320px;
	      	word-wrap: break-word;
	      	&:hover{
	      	 box-shadow: 0 8px 16px 0 rgba(45,45,55,.25);
    		 transform: translateY(-10px);
	      	}
	      }
	      img{
	        margin-bottom: rem-calc(16);
	        max-width: 148px;
	      }
	      p{
	        font-size:rem-calc(16);
	        margin-bottom:rem-calc(8);
	        color:#6e797a;
	      }
	      .acronimo{
	      	 font-size:rem-calc(16);
	        margin-bottom:rem-calc(5);
	        color:#6e797a;
	      }
	      h2{
	        color: #000;
	        font-size:rem-calc(21);
	        margin-bottom:rem-calc(8);
	        border:none;
	        padding:0;

	      }
	      .email{
	        display: inline-block;
	        margin-bottom:rem-calc(25);
	        @extend .icon-email-equipo;
	      }
	      &:before{
	        content: none;
	      }
	    }
	}

}
.grupo{

	@media #{$medium-up} {
  		.contenido.b{
  			opacity:0;
  			transition: opacity .2s ease-in;
     		padding:rem-calc(0 50 0);
  		}
  	}
}
.titulo .fila{
    opacity: 0;

    -webkit-animation: showText .6s forwards;
    animation: showText .6s forwards;
}
.titulo .fila:nth-child(1){
  -webkit-animation-delay:.2s;
  animation-delay:.2s;
}
.titulo .fila:nth-child(2){
  -webkit-animation-delay:.5s;
  animation-delay:.5s;
}
.titulo .fila:nth-child(3){
  -webkit-animation-delay:.9s;
  animation-delay:.9s;
}


@-webkit-keyframes showText {
  0%   { -webkit-transform: translateY(10px); opacity: 0; }
  100% { -webkit-transform: translateY(0px); opacity: 1; }
}
@keyframes showText {
  0%   { transform: translateY(10px); opacity: 0; }
  100% { transform: translateY(0px); opacity: 1; }
}