// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$bola-play-video-name: 'bola-play-video';
$bola-play-video-x: 0px;
$bola-play-video-y: 0px;
$bola-play-video-offset-x: 0px;
$bola-play-video-offset-y: 0px;
$bola-play-video-width: 142px;
$bola-play-video-height: 141px;
$bola-play-video-total-width: 273px;
$bola-play-video-total-height: 248px;
$bola-play-video-image: 'img/spritesheet.png?1649327118895';
$bola-play-video: (0px, 0px, 0px, 0px, 142px, 141px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'bola-play-video', );
$comparte-facebook-name: 'comparte-facebook';
$comparte-facebook-x: 0px;
$comparte-facebook-y: 223px;
$comparte-facebook-offset-x: 0px;
$comparte-facebook-offset-y: -223px;
$comparte-facebook-width: 25px;
$comparte-facebook-height: 25px;
$comparte-facebook-total-width: 273px;
$comparte-facebook-total-height: 248px;
$comparte-facebook-image: 'img/spritesheet.png?1649327118895';
$comparte-facebook: (0px, 223px, 0px, -223px, 25px, 25px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'comparte-facebook', );
$comparte-linkedin-name: 'comparte-linkedin';
$comparte-linkedin-x: 25px;
$comparte-linkedin-y: 223px;
$comparte-linkedin-offset-x: -25px;
$comparte-linkedin-offset-y: -223px;
$comparte-linkedin-width: 24px;
$comparte-linkedin-height: 25px;
$comparte-linkedin-total-width: 273px;
$comparte-linkedin-total-height: 248px;
$comparte-linkedin-image: 'img/spritesheet.png?1649327118895';
$comparte-linkedin: (25px, 223px, -25px, -223px, 24px, 25px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'comparte-linkedin', );
$comparte-twitter-name: 'comparte-twitter';
$comparte-twitter-x: 56px;
$comparte-twitter-y: 200px;
$comparte-twitter-offset-x: -56px;
$comparte-twitter-offset-y: -200px;
$comparte-twitter-width: 27px;
$comparte-twitter-height: 22px;
$comparte-twitter-total-width: 273px;
$comparte-twitter-total-height: 248px;
$comparte-twitter-image: 'img/spritesheet.png?1649327118895';
$comparte-twitter: (56px, 200px, -56px, -200px, 27px, 22px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'comparte-twitter', );
$filter-arrows-down-name: 'filter-arrows-down';
$filter-arrows-down-x: 156px;
$filter-arrows-down-y: 178px;
$filter-arrows-down-offset-x: -156px;
$filter-arrows-down-offset-y: -178px;
$filter-arrows-down-width: 17px;
$filter-arrows-down-height: 10px;
$filter-arrows-down-total-width: 273px;
$filter-arrows-down-total-height: 248px;
$filter-arrows-down-image: 'img/spritesheet.png?1649327118895';
$filter-arrows-down: (156px, 178px, -156px, -178px, 17px, 10px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'filter-arrows-down', );
$fletxa-menu-avall-name: 'fletxa-menu-avall';
$fletxa-menu-avall-x: 173px;
$fletxa-menu-avall-y: 178px;
$fletxa-menu-avall-offset-x: -173px;
$fletxa-menu-avall-offset-y: -178px;
$fletxa-menu-avall-width: 17px;
$fletxa-menu-avall-height: 10px;
$fletxa-menu-avall-total-width: 273px;
$fletxa-menu-avall-total-height: 248px;
$fletxa-menu-avall-image: 'img/spritesheet.png?1649327118895';
$fletxa-menu-avall: (173px, 178px, -173px, -178px, 17px, 10px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'fletxa-menu-avall', );
$fletxa-menu-endavant-name: 'fletxa-menu-endavant';
$fletxa-menu-endavant-x: 244px;
$fletxa-menu-endavant-y: 70px;
$fletxa-menu-endavant-offset-x: -244px;
$fletxa-menu-endavant-offset-y: -70px;
$fletxa-menu-endavant-width: 8px;
$fletxa-menu-endavant-height: 14px;
$fletxa-menu-endavant-total-width: 273px;
$fletxa-menu-endavant-total-height: 248px;
$fletxa-menu-endavant-image: 'img/spritesheet.png?1649327118895';
$fletxa-menu-endavant: (244px, 70px, -244px, -70px, 8px, 14px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'fletxa-menu-endavant', );
$icon-breadcrumb-home-name: 'icon-breadcrumb-home';
$icon-breadcrumb-home-x: 177px;
$icon-breadcrumb-home-y: 200px;
$icon-breadcrumb-home-offset-x: -177px;
$icon-breadcrumb-home-offset-y: -200px;
$icon-breadcrumb-home-width: 12px;
$icon-breadcrumb-home-height: 12px;
$icon-breadcrumb-home-total-width: 273px;
$icon-breadcrumb-home-total-height: 248px;
$icon-breadcrumb-home-image: 'img/spritesheet.png?1649327118895';
$icon-breadcrumb-home: (177px, 200px, -177px, -200px, 12px, 12px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-breadcrumb-home', );
$icon-breadcrumb-name: 'icon-breadcrumb';
$icon-breadcrumb-x: 244px;
$icon-breadcrumb-y: 96px;
$icon-breadcrumb-offset-x: -244px;
$icon-breadcrumb-offset-y: -96px;
$icon-breadcrumb-width: 7px;
$icon-breadcrumb-height: 9px;
$icon-breadcrumb-total-width: 273px;
$icon-breadcrumb-total-height: 248px;
$icon-breadcrumb-image: 'img/spritesheet.png?1649327118895';
$icon-breadcrumb: (244px, 96px, -244px, -96px, 7px, 9px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-breadcrumb', );
$icon-caixa-biblioteca-cusrn-name: 'icon-caixa-biblioteca-cusrn';
$icon-caixa-biblioteca-cusrn-x: 142px;
$icon-caixa-biblioteca-cusrn-y: 0px;
$icon-caixa-biblioteca-cusrn-offset-x: -142px;
$icon-caixa-biblioteca-cusrn-offset-y: 0px;
$icon-caixa-biblioteca-cusrn-width: 70px;
$icon-caixa-biblioteca-cusrn-height: 74px;
$icon-caixa-biblioteca-cusrn-total-width: 273px;
$icon-caixa-biblioteca-cusrn-total-height: 248px;
$icon-caixa-biblioteca-cusrn-image: 'img/spritesheet.png?1649327118895';
$icon-caixa-biblioteca-cusrn: (142px, 0px, -142px, 0px, 70px, 74px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-caixa-biblioteca-cusrn', );
$icon-caixa-donaciones-name: 'icon-caixa-donaciones';
$icon-caixa-donaciones-x: 142px;
$icon-caixa-donaciones-y: 74px;
$icon-caixa-donaciones-offset-x: -142px;
$icon-caixa-donaciones-offset-y: -74px;
$icon-caixa-donaciones-width: 62px;
$icon-caixa-donaciones-height: 62px;
$icon-caixa-donaciones-total-width: 273px;
$icon-caixa-donaciones-total-height: 248px;
$icon-caixa-donaciones-image: 'img/spritesheet.png?1649327118895';
$icon-caixa-donaciones: (142px, 74px, -142px, -74px, 62px, 62px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-caixa-donaciones', );
$icon-caixa-memoria-name: 'icon-caixa-memoria';
$icon-caixa-memoria-x: 212px;
$icon-caixa-memoria-y: 0px;
$icon-caixa-memoria-offset-x: -212px;
$icon-caixa-memoria-offset-y: 0px;
$icon-caixa-memoria-width: 40px;
$icon-caixa-memoria-height: 70px;
$icon-caixa-memoria-total-width: 273px;
$icon-caixa-memoria-total-height: 248px;
$icon-caixa-memoria-image: 'img/spritesheet.png?1649327118895';
$icon-caixa-memoria: (212px, 0px, -212px, 0px, 40px, 70px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-caixa-memoria', );
$icon-caixa-newsletter-name: 'icon-caixa-newsletter';
$icon-caixa-newsletter-x: 0px;
$icon-caixa-newsletter-y: 141px;
$icon-caixa-newsletter-offset-x: 0px;
$icon-caixa-newsletter-offset-y: -141px;
$icon-caixa-newsletter-width: 62px;
$icon-caixa-newsletter-height: 59px;
$icon-caixa-newsletter-total-width: 273px;
$icon-caixa-newsletter-total-height: 248px;
$icon-caixa-newsletter-image: 'img/spritesheet.png?1649327118895';
$icon-caixa-newsletter: (0px, 141px, 0px, -141px, 62px, 59px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-caixa-newsletter', );
$icon-clip-relacionats-name: 'icon-clip-relacionats';
$icon-clip-relacionats-x: 109px;
$icon-clip-relacionats-y: 200px;
$icon-clip-relacionats-offset-x: -109px;
$icon-clip-relacionats-offset-y: -200px;
$icon-clip-relacionats-width: 22px;
$icon-clip-relacionats-height: 21px;
$icon-clip-relacionats-total-width: 273px;
$icon-clip-relacionats-total-height: 248px;
$icon-clip-relacionats-image: 'img/spritesheet.png?1649327118895';
$icon-clip-relacionats: (109px, 200px, -109px, -200px, 22px, 21px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-clip-relacionats', );
$icon-email-equipo-name: 'icon-email-equipo';
$icon-email-equipo-x: 96px;
$icon-email-equipo-y: 178px;
$icon-email-equipo-offset-x: -96px;
$icon-email-equipo-offset-y: -178px;
$icon-email-equipo-width: 24px;
$icon-email-equipo-height: 20px;
$icon-email-equipo-total-width: 273px;
$icon-email-equipo-total-height: 248px;
$icon-email-equipo-image: 'img/spritesheet.png?1649327118895';
$icon-email-equipo: (96px, 178px, -96px, -178px, 24px, 20px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-email-equipo', );
$icon-lat-categoria-name: 'icon-lat-categoria';
$icon-lat-categoria-x: 0px;
$icon-lat-categoria-y: 200px;
$icon-lat-categoria-offset-x: 0px;
$icon-lat-categoria-offset-y: -200px;
$icon-lat-categoria-width: 29px;
$icon-lat-categoria-height: 23px;
$icon-lat-categoria-total-width: 273px;
$icon-lat-categoria-total-height: 248px;
$icon-lat-categoria-image: 'img/spritesheet.png?1649327118895';
$icon-lat-categoria: (0px, 200px, 0px, -200px, 29px, 23px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-lat-categoria', );
$icon-lupa-name: 'icon-lupa';
$icon-lupa-x: 49px;
$icon-lupa-y: 223px;
$icon-lupa-offset-x: -49px;
$icon-lupa-offset-y: -223px;
$icon-lupa-width: 23px;
$icon-lupa-height: 23px;
$icon-lupa-total-width: 273px;
$icon-lupa-total-height: 248px;
$icon-lupa-image: 'img/spritesheet.png?1649327118895';
$icon-lupa: (49px, 223px, -49px, -223px, 23px, 23px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-lupa', );
$icon-relacionats-excel-name: 'icon-relacionats-excel';
$icon-relacionats-excel-x: 252px;
$icon-relacionats-excel-y: 100px;
$icon-relacionats-excel-offset-x: -252px;
$icon-relacionats-excel-offset-y: -100px;
$icon-relacionats-excel-width: 21px;
$icon-relacionats-excel-height: 25px;
$icon-relacionats-excel-total-width: 273px;
$icon-relacionats-excel-total-height: 248px;
$icon-relacionats-excel-image: 'img/spritesheet.png?1649327118895';
$icon-relacionats-excel: (252px, 100px, -252px, -100px, 21px, 25px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-relacionats-excel', );
$icon-relacionats-pdf-name: 'icon-relacionats-pdf';
$icon-relacionats-pdf-x: 252px;
$icon-relacionats-pdf-y: 0px;
$icon-relacionats-pdf-offset-x: -252px;
$icon-relacionats-pdf-offset-y: 0px;
$icon-relacionats-pdf-width: 21px;
$icon-relacionats-pdf-height: 25px;
$icon-relacionats-pdf-total-width: 273px;
$icon-relacionats-pdf-total-height: 248px;
$icon-relacionats-pdf-image: 'img/spritesheet.png?1649327118895';
$icon-relacionats-pdf: (252px, 0px, -252px, 0px, 21px, 25px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-relacionats-pdf', );
$icon-relacionats-vincle-name: 'icon-relacionats-vincle';
$icon-relacionats-vincle-x: 252px;
$icon-relacionats-vincle-y: 75px;
$icon-relacionats-vincle-offset-x: -252px;
$icon-relacionats-vincle-offset-y: -75px;
$icon-relacionats-vincle-width: 21px;
$icon-relacionats-vincle-height: 25px;
$icon-relacionats-vincle-total-width: 273px;
$icon-relacionats-vincle-total-height: 248px;
$icon-relacionats-vincle-image: 'img/spritesheet.png?1649327118895';
$icon-relacionats-vincle: (252px, 75px, -252px, -75px, 21px, 25px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-relacionats-vincle', );
$icon-relacionats-word-name: 'icon-relacionats-word';
$icon-relacionats-word-x: 252px;
$icon-relacionats-word-y: 25px;
$icon-relacionats-word-offset-x: -252px;
$icon-relacionats-word-offset-y: -25px;
$icon-relacionats-word-width: 21px;
$icon-relacionats-word-height: 25px;
$icon-relacionats-word-total-width: 273px;
$icon-relacionats-word-total-height: 248px;
$icon-relacionats-word-image: 'img/spritesheet.png?1649327118895';
$icon-relacionats-word: (252px, 25px, -252px, -25px, 21px, 25px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-relacionats-word', );
$icon-relacionats-zip-name: 'icon-relacionats-zip';
$icon-relacionats-zip-x: 252px;
$icon-relacionats-zip-y: 50px;
$icon-relacionats-zip-offset-x: -252px;
$icon-relacionats-zip-offset-y: -50px;
$icon-relacionats-zip-width: 21px;
$icon-relacionats-zip-height: 25px;
$icon-relacionats-zip-total-width: 273px;
$icon-relacionats-zip-total-height: 248px;
$icon-relacionats-zip-image: 'img/spritesheet.png?1649327118895';
$icon-relacionats-zip: (252px, 50px, -252px, -50px, 21px, 25px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icon-relacionats-zip', );
$icona-mas-name: 'icona-mas';
$icona-mas-x: 252px;
$icona-mas-y: 222px;
$icona-mas-offset-x: -252px;
$icona-mas-offset-y: -222px;
$icona-mas-width: 17px;
$icona-mas-height: 18px;
$icona-mas-total-width: 273px;
$icona-mas-total-height: 248px;
$icona-mas-image: 'img/spritesheet.png?1649327118895';
$icona-mas: (252px, 222px, -252px, -222px, 17px, 18px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'icona-mas', );
$kland-arxiu-destacat-name: 'kland-arxiu-destacat';
$kland-arxiu-destacat-x: 231px;
$kland-arxiu-destacat-y: 182px;
$kland-arxiu-destacat-offset-x: -231px;
$kland-arxiu-destacat-offset-y: -182px;
$kland-arxiu-destacat-width: 15px;
$kland-arxiu-destacat-height: 14px;
$kland-arxiu-destacat-total-width: 273px;
$kland-arxiu-destacat-total-height: 248px;
$kland-arxiu-destacat-image: 'img/spritesheet.png?1649327118895';
$kland-arxiu-destacat: (231px, 182px, -231px, -182px, 15px, 14px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'kland-arxiu-destacat', );
$kland-caixa-blanc-name: 'kland-caixa-blanc';
$kland-caixa-blanc-x: 120px;
$kland-caixa-blanc-y: 178px;
$kland-caixa-blanc-offset-x: -120px;
$kland-caixa-blanc-offset-y: -178px;
$kland-caixa-blanc-width: 12px;
$kland-caixa-blanc-height: 17px;
$kland-caixa-blanc-total-width: 273px;
$kland-caixa-blanc-total-height: 248px;
$kland-caixa-blanc-image: 'img/spritesheet.png?1649327118895';
$kland-caixa-blanc: (120px, 178px, -120px, -178px, 12px, 17px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'kland-caixa-blanc', );
$kland-caixa-blau-name: 'kland-caixa-blau';
$kland-caixa-blau-x: 132px;
$kland-caixa-blau-y: 178px;
$kland-caixa-blau-offset-x: -132px;
$kland-caixa-blau-offset-y: -178px;
$kland-caixa-blau-width: 12px;
$kland-caixa-blau-height: 17px;
$kland-caixa-blau-total-width: 273px;
$kland-caixa-blau-total-height: 248px;
$kland-caixa-blau-image: 'img/spritesheet.png?1649327118895';
$kland-caixa-blau: (132px, 178px, -132px, -178px, 12px, 17px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'kland-caixa-blau', );
$kland-caixa-gris-name: 'kland-caixa-gris';
$kland-caixa-gris-x: 144px;
$kland-caixa-gris-y: 178px;
$kland-caixa-gris-offset-x: -144px;
$kland-caixa-gris-offset-y: -178px;
$kland-caixa-gris-width: 12px;
$kland-caixa-gris-height: 17px;
$kland-caixa-gris-total-width: 273px;
$kland-caixa-gris-total-height: 248px;
$kland-caixa-gris-image: 'img/spritesheet.png?1649327118895';
$kland-caixa-gris: (144px, 178px, -144px, -178px, 12px, 17px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'kland-caixa-gris', );
$kland-cuadradet-llistats-name: 'kland-cuadradet-llistats';
$kland-cuadradet-llistats-x: 165px;
$kland-cuadradet-llistats-y: 200px;
$kland-cuadradet-llistats-offset-x: -165px;
$kland-cuadradet-llistats-offset-y: -200px;
$kland-cuadradet-llistats-width: 12px;
$kland-cuadradet-llistats-height: 12px;
$kland-cuadradet-llistats-total-width: 273px;
$kland-cuadradet-llistats-total-height: 248px;
$kland-cuadradet-llistats-image: 'img/spritesheet.png?1649327118895';
$kland-cuadradet-llistats: (165px, 200px, -165px, -200px, 12px, 12px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'kland-cuadradet-llistats', );
$kland-llistat-name: 'kland-llistat';
$kland-llistat-x: 244px;
$kland-llistat-y: 84px;
$kland-llistat-offset-x: -244px;
$kland-llistat-offset-y: -84px;
$kland-llistat-width: 8px;
$kland-llistat-height: 12px;
$kland-llistat-total-width: 273px;
$kland-llistat-total-height: 248px;
$kland-llistat-image: 'img/spritesheet.png?1649327118895';
$kland-llistat: (244px, 84px, -244px, -84px, 8px, 12px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'kland-llistat', );
$kland-llistats-portada-name: 'kland-llistats-portada';
$kland-llistats-portada-x: 189px;
$kland-llistats-portada-y: 200px;
$kland-llistats-portada-offset-x: -189px;
$kland-llistats-portada-offset-y: -200px;
$kland-llistats-portada-width: 11px;
$kland-llistats-portada-height: 12px;
$kland-llistats-portada-total-width: 273px;
$kland-llistats-portada-total-height: 248px;
$kland-llistats-portada-image: 'img/spritesheet.png?1649327118895';
$kland-llistats-portada: (189px, 200px, -189px, -200px, 11px, 12px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'kland-llistats-portada', );
$llistat-bola-roig-name: 'llistat-bola-roig';
$llistat-bola-roig-x: 222px;
$llistat-bola-roig-y: 200px;
$llistat-bola-roig-offset-x: -222px;
$llistat-bola-roig-offset-y: -200px;
$llistat-bola-roig-width: 11px;
$llistat-bola-roig-height: 11px;
$llistat-bola-roig-total-width: 273px;
$llistat-bola-roig-total-height: 248px;
$llistat-bola-roig-image: 'img/spritesheet.png?1649327118895';
$llistat-bola-roig: (222px, 200px, -222px, -200px, 11px, 11px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'llistat-bola-roig', );
$llistat-bola-taronja-name: 'llistat-bola-taronja';
$llistat-bola-taronja-x: 211px;
$llistat-bola-taronja-y: 200px;
$llistat-bola-taronja-offset-x: -211px;
$llistat-bola-taronja-offset-y: -200px;
$llistat-bola-taronja-width: 11px;
$llistat-bola-taronja-height: 11px;
$llistat-bola-taronja-total-width: 273px;
$llistat-bola-taronja-total-height: 248px;
$llistat-bola-taronja-image: 'img/spritesheet.png?1649327118895';
$llistat-bola-taronja: (211px, 200px, -211px, -200px, 11px, 11px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'llistat-bola-taronja', );
$llistat-bola-verd-name: 'llistat-bola-verd';
$llistat-bola-verd-x: 200px;
$llistat-bola-verd-y: 200px;
$llistat-bola-verd-offset-x: -200px;
$llistat-bola-verd-offset-y: -200px;
$llistat-bola-verd-width: 11px;
$llistat-bola-verd-height: 11px;
$llistat-bola-verd-total-width: 273px;
$llistat-bola-verd-total-height: 248px;
$llistat-bola-verd-image: 'img/spritesheet.png?1649327118895';
$llistat-bola-verd: (200px, 200px, -200px, -200px, 11px, 11px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'llistat-bola-verd', );
$llistat-nivell-1-name: 'llistat-nivell-1';
$llistat-nivell-1-x: 153px;
$llistat-nivell-1-y: 200px;
$llistat-nivell-1-offset-x: -153px;
$llistat-nivell-1-offset-y: -200px;
$llistat-nivell-1-width: 12px;
$llistat-nivell-1-height: 12px;
$llistat-nivell-1-total-width: 273px;
$llistat-nivell-1-total-height: 248px;
$llistat-nivell-1-image: 'img/spritesheet.png?1649327118895';
$llistat-nivell-1: (153px, 200px, -153px, -200px, 12px, 12px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'llistat-nivell-1', );
$llistat-nivell-2-name: 'llistat-nivell-2';
$llistat-nivell-2-x: 141px;
$llistat-nivell-2-y: 200px;
$llistat-nivell-2-offset-x: -141px;
$llistat-nivell-2-offset-y: -200px;
$llistat-nivell-2-width: 12px;
$llistat-nivell-2-height: 12px;
$llistat-nivell-2-total-width: 273px;
$llistat-nivell-2-total-height: 248px;
$llistat-nivell-2-image: 'img/spritesheet.png?1649327118895';
$llistat-nivell-2: (141px, 200px, -141px, -200px, 12px, 12px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'llistat-nivell-2', );
$llistat-nivell-3-name: 'llistat-nivell-3';
$llistat-nivell-3-x: 264px;
$llistat-nivell-3-y: 240px;
$llistat-nivell-3-offset-x: -264px;
$llistat-nivell-3-offset-y: -240px;
$llistat-nivell-3-width: 5px;
$llistat-nivell-3-height: 5px;
$llistat-nivell-3-total-width: 273px;
$llistat-nivell-3-total-height: 248px;
$llistat-nivell-3-image: 'img/spritesheet.png?1649327118895';
$llistat-nivell-3: (264px, 240px, -264px, -240px, 5px, 5px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'llistat-nivell-3', );
$menu-arrows-down-name: 'menu-arrows-down';
$menu-arrows-down-x: 190px;
$menu-arrows-down-y: 178px;
$menu-arrows-down-offset-x: -190px;
$menu-arrows-down-offset-y: -178px;
$menu-arrows-down-width: 17px;
$menu-arrows-down-height: 10px;
$menu-arrows-down-total-width: 273px;
$menu-arrows-down-total-height: 248px;
$menu-arrows-down-image: 'img/spritesheet.png?1649327118895';
$menu-arrows-down: (190px, 178px, -190px, -178px, 17px, 10px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'menu-arrows-down', );
$menu-arrows-left-name: 'menu-arrows-left';
$menu-arrows-left-x: 131px;
$menu-arrows-left-y: 200px;
$menu-arrows-left-offset-x: -131px;
$menu-arrows-left-offset-y: -200px;
$menu-arrows-left-width: 10px;
$menu-arrows-left-height: 17px;
$menu-arrows-left-total-width: 273px;
$menu-arrows-left-total-height: 248px;
$menu-arrows-left-image: 'img/spritesheet.png?1649327118895';
$menu-arrows-left: (131px, 200px, -131px, -200px, 10px, 17px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'menu-arrows-left', );
$menu-burger-name: 'menu-burger';
$menu-burger-x: 62px;
$menu-burger-y: 141px;
$menu-burger-offset-x: -62px;
$menu-burger-offset-y: -141px;
$menu-burger-width: 37px;
$menu-burger-height: 37px;
$menu-burger-total-width: 273px;
$menu-burger-total-height: 248px;
$menu-burger-image: 'img/spritesheet.png?1649327118895';
$menu-burger: (62px, 141px, -62px, -141px, 37px, 37px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'menu-burger', );
$menu-fletxes-enrera-name: 'menu-fletxes-enrera';
$menu-fletxes-enrera-x: 212px;
$menu-fletxes-enrera-y: 182px;
$menu-fletxes-enrera-offset-x: -212px;
$menu-fletxes-enrera-offset-y: -182px;
$menu-fletxes-enrera-width: 19px;
$menu-fletxes-enrera-height: 14px;
$menu-fletxes-enrera-total-width: 273px;
$menu-fletxes-enrera-total-height: 248px;
$menu-fletxes-enrera-image: 'img/spritesheet.png?1649327118895';
$menu-fletxes-enrera: (212px, 182px, -212px, -182px, 19px, 14px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'menu-fletxes-enrera', );
$menu-triangle-desplega-blanc-name: 'menu-triangle-desplega-blanc';
$menu-triangle-desplega-blanc-x: 266px;
$menu-triangle-desplega-blanc-y: 144px;
$menu-triangle-desplega-blanc-offset-x: -266px;
$menu-triangle-desplega-blanc-offset-y: -144px;
$menu-triangle-desplega-blanc-width: 7px;
$menu-triangle-desplega-blanc-height: 12px;
$menu-triangle-desplega-blanc-total-width: 273px;
$menu-triangle-desplega-blanc-total-height: 248px;
$menu-triangle-desplega-blanc-image: 'img/spritesheet.png?1649327118895';
$menu-triangle-desplega-blanc: (266px, 144px, -266px, -144px, 7px, 12px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'menu-triangle-desplega-blanc', );
$menu-triangle-desplega-name: 'menu-triangle-desplega';
$menu-triangle-desplega-x: 266px;
$menu-triangle-desplega-y: 156px;
$menu-triangle-desplega-offset-x: -266px;
$menu-triangle-desplega-offset-y: -156px;
$menu-triangle-desplega-width: 7px;
$menu-triangle-desplega-height: 12px;
$menu-triangle-desplega-total-width: 273px;
$menu-triangle-desplega-total-height: 248px;
$menu-triangle-desplega-image: 'img/spritesheet.png?1649327118895';
$menu-triangle-desplega: (266px, 156px, -266px, -156px, 7px, 12px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'menu-triangle-desplega', );
$navega-llistat-seguent-name: 'navega-llistat-seguent';
$navega-llistat-seguent-x: 252px;
$navega-llistat-seguent-y: 144px;
$navega-llistat-seguent-offset-x: -252px;
$navega-llistat-seguent-offset-y: -144px;
$navega-llistat-seguent-width: 14px;
$navega-llistat-seguent-height: 24px;
$navega-llistat-seguent-total-width: 273px;
$navega-llistat-seguent-total-height: 248px;
$navega-llistat-seguent-image: 'img/spritesheet.png?1649327118895';
$navega-llistat-seguent: (252px, 144px, -252px, -144px, 14px, 24px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'navega-llistat-seguent', );
$paginacio-anterior-name: 'paginacio-anterior';
$paginacio-anterior-x: 163px;
$paginacio-anterior-y: 141px;
$paginacio-anterior-offset-x: -163px;
$paginacio-anterior-offset-y: -141px;
$paginacio-anterior-width: 32px;
$paginacio-anterior-height: 24px;
$paginacio-anterior-total-width: 273px;
$paginacio-anterior-total-height: 248px;
$paginacio-anterior-image: 'img/spritesheet.png?1649327118895';
$paginacio-anterior: (163px, 141px, -163px, -141px, 32px, 24px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'paginacio-anterior', );
$paginacio-seguent-name: 'paginacio-seguent';
$paginacio-seguent-x: 131px;
$paginacio-seguent-y: 141px;
$paginacio-seguent-offset-x: -131px;
$paginacio-seguent-offset-y: -141px;
$paginacio-seguent-width: 32px;
$paginacio-seguent-height: 24px;
$paginacio-seguent-total-width: 273px;
$paginacio-seguent-total-height: 248px;
$paginacio-seguent-image: 'img/spritesheet.png?1649327118895';
$paginacio-seguent: (131px, 141px, -131px, -141px, 32px, 24px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'paginacio-seguent', );
$pestanya-bola-roig-name: 'pestanya-bola-roig';
$pestanya-bola-roig-x: 252px;
$pestanya-bola-roig-y: 186px;
$pestanya-bola-roig-offset-x: -252px;
$pestanya-bola-roig-offset-y: -186px;
$pestanya-bola-roig-width: 18px;
$pestanya-bola-roig-height: 18px;
$pestanya-bola-roig-total-width: 273px;
$pestanya-bola-roig-total-height: 248px;
$pestanya-bola-roig-image: 'img/spritesheet.png?1649327118895';
$pestanya-bola-roig: (252px, 186px, -252px, -186px, 18px, 18px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'pestanya-bola-roig', );
$pestanya-bola-taronja-name: 'pestanya-bola-taronja';
$pestanya-bola-taronja-x: 252px;
$pestanya-bola-taronja-y: 168px;
$pestanya-bola-taronja-offset-x: -252px;
$pestanya-bola-taronja-offset-y: -168px;
$pestanya-bola-taronja-width: 18px;
$pestanya-bola-taronja-height: 18px;
$pestanya-bola-taronja-total-width: 273px;
$pestanya-bola-taronja-total-height: 248px;
$pestanya-bola-taronja-image: 'img/spritesheet.png?1649327118895';
$pestanya-bola-taronja: (252px, 168px, -252px, -168px, 18px, 18px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'pestanya-bola-taronja', );
$pestanya-bola-verd-name: 'pestanya-bola-verd';
$pestanya-bola-verd-x: 252px;
$pestanya-bola-verd-y: 204px;
$pestanya-bola-verd-offset-x: -252px;
$pestanya-bola-verd-offset-y: -204px;
$pestanya-bola-verd-width: 18px;
$pestanya-bola-verd-height: 18px;
$pestanya-bola-verd-total-width: 273px;
$pestanya-bola-verd-total-height: 248px;
$pestanya-bola-verd-image: 'img/spritesheet.png?1649327118895';
$pestanya-bola-verd: (252px, 204px, -252px, -204px, 18px, 18px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'pestanya-bola-verd', );
$slide-endavant-name: 'slide-endavant';
$slide-endavant-x: 212px;
$slide-endavant-y: 126px;
$slide-endavant-offset-x: -212px;
$slide-endavant-offset-y: -126px;
$slide-endavant-width: 32px;
$slide-endavant-height: 56px;
$slide-endavant-total-width: 273px;
$slide-endavant-total-height: 248px;
$slide-endavant-image: 'img/spritesheet.png?1649327118895';
$slide-endavant: (212px, 126px, -212px, -126px, 32px, 56px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'slide-endavant', );
$slide-enrera-name: 'slide-enrera';
$slide-enrera-x: 212px;
$slide-enrera-y: 70px;
$slide-enrera-offset-x: -212px;
$slide-enrera-offset-y: -70px;
$slide-enrera-width: 32px;
$slide-enrera-height: 56px;
$slide-enrera-total-width: 273px;
$slide-enrera-total-height: 248px;
$slide-enrera-image: 'img/spritesheet.png?1649327118895';
$slide-enrera: (212px, 70px, -212px, -70px, 32px, 56px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'slide-enrera', );
$subir-name: 'subir';
$subir-x: 62px;
$subir-y: 178px;
$subir-offset-x: -62px;
$subir-offset-y: -178px;
$subir-width: 34px;
$subir-height: 20px;
$subir-total-width: 273px;
$subir-total-height: 248px;
$subir-image: 'img/spritesheet.png?1649327118895';
$subir: (62px, 178px, -62px, -178px, 34px, 20px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'subir', );
$triangle-cronologia-dreta-name: 'triangle-cronologia-dreta';
$triangle-cronologia-dreta-x: 195px;
$triangle-cronologia-dreta-y: 141px;
$triangle-cronologia-dreta-offset-x: -195px;
$triangle-cronologia-dreta-offset-y: -141px;
$triangle-cronologia-dreta-width: 12px;
$triangle-cronologia-dreta-height: 22px;
$triangle-cronologia-dreta-total-width: 273px;
$triangle-cronologia-dreta-total-height: 248px;
$triangle-cronologia-dreta-image: 'img/spritesheet.png?1649327118895';
$triangle-cronologia-dreta: (195px, 141px, -195px, -141px, 12px, 22px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'triangle-cronologia-dreta', );
$triangle-cronologia-esquerra-name: 'triangle-cronologia-esquerra';
$triangle-cronologia-esquerra-x: 94px;
$triangle-cronologia-esquerra-y: 223px;
$triangle-cronologia-esquerra-offset-x: -94px;
$triangle-cronologia-esquerra-offset-y: -223px;
$triangle-cronologia-esquerra-width: 12px;
$triangle-cronologia-esquerra-height: 22px;
$triangle-cronologia-esquerra-total-width: 273px;
$triangle-cronologia-esquerra-total-height: 248px;
$triangle-cronologia-esquerra-image: 'img/spritesheet.png?1649327118895';
$triangle-cronologia-esquerra: (94px, 223px, -94px, -223px, 12px, 22px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'triangle-cronologia-esquerra', );
$triangle-menu-name: 'triangle-menu';
$triangle-menu-x: 252px;
$triangle-menu-y: 240px;
$triangle-menu-offset-x: -252px;
$triangle-menu-offset-y: -240px;
$triangle-menu-width: 12px;
$triangle-menu-height: 7px;
$triangle-menu-total-width: 273px;
$triangle-menu-total-height: 248px;
$triangle-menu-image: 'img/spritesheet.png?1649327118895';
$triangle-menu: (252px, 240px, -252px, -240px, 12px, 7px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'triangle-menu', );
$valores-cerrar-creu-name: 'valores-cerrar-creu';
$valores-cerrar-creu-x: 252px;
$valores-cerrar-creu-y: 125px;
$valores-cerrar-creu-offset-x: -252px;
$valores-cerrar-creu-offset-y: -125px;
$valores-cerrar-creu-width: 18px;
$valores-cerrar-creu-height: 19px;
$valores-cerrar-creu-total-width: 273px;
$valores-cerrar-creu-total-height: 248px;
$valores-cerrar-creu-image: 'img/spritesheet.png?1649327118895';
$valores-cerrar-creu: (252px, 125px, -252px, -125px, 18px, 19px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'valores-cerrar-creu', );
$valores-rodona-mes-name: 'valores-rodona-mes';
$valores-rodona-mes-x: 99px;
$valores-rodona-mes-y: 141px;
$valores-rodona-mes-offset-x: -99px;
$valores-rodona-mes-offset-y: -141px;
$valores-rodona-mes-width: 32px;
$valores-rodona-mes-height: 33px;
$valores-rodona-mes-total-width: 273px;
$valores-rodona-mes-total-height: 248px;
$valores-rodona-mes-image: 'img/spritesheet.png?1649327118895';
$valores-rodona-mes: (99px, 141px, -99px, -141px, 32px, 33px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'valores-rodona-mes', );
$xarxes-facebook-name: 'xarxes-facebook';
$xarxes-facebook-x: 72px;
$xarxes-facebook-y: 223px;
$xarxes-facebook-offset-x: -72px;
$xarxes-facebook-offset-y: -223px;
$xarxes-facebook-width: 22px;
$xarxes-facebook-height: 22px;
$xarxes-facebook-total-width: 273px;
$xarxes-facebook-total-height: 248px;
$xarxes-facebook-image: 'img/spritesheet.png?1649327118895';
$xarxes-facebook: (72px, 223px, -72px, -223px, 22px, 22px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'xarxes-facebook', );
$xarxes-twitter-name: 'xarxes-twitter';
$xarxes-twitter-x: 29px;
$xarxes-twitter-y: 200px;
$xarxes-twitter-offset-x: -29px;
$xarxes-twitter-offset-y: -200px;
$xarxes-twitter-width: 27px;
$xarxes-twitter-height: 22px;
$xarxes-twitter-total-width: 273px;
$xarxes-twitter-total-height: 248px;
$xarxes-twitter-image: 'img/spritesheet.png?1649327118895';
$xarxes-twitter: (29px, 200px, -29px, -200px, 27px, 22px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'xarxes-twitter', );
$xarxes-vimeo-name: 'xarxes-vimeo';
$xarxes-vimeo-x: 83px;
$xarxes-vimeo-y: 200px;
$xarxes-vimeo-offset-x: -83px;
$xarxes-vimeo-offset-y: -200px;
$xarxes-vimeo-width: 26px;
$xarxes-vimeo-height: 21px;
$xarxes-vimeo-total-width: 273px;
$xarxes-vimeo-total-height: 248px;
$xarxes-vimeo-image: 'img/spritesheet.png?1649327118895';
$xarxes-vimeo: (83px, 200px, -83px, -200px, 26px, 21px, 273px, 248px, 'img/spritesheet.png?1649327118895', 'xarxes-vimeo', );
$bola-play-video-2x-name: 'bola-play-video@2x';
$bola-play-video-2x-x: 0px;
$bola-play-video-2x-y: 0px;
$bola-play-video-2x-offset-x: 0px;
$bola-play-video-2x-offset-y: 0px;
$bola-play-video-2x-width: 284px;
$bola-play-video-2x-height: 282px;
$bola-play-video-2x-total-width: 546px;
$bola-play-video-2x-total-height: 496px;
$bola-play-video-2x-image: 'img/spritesheet@2x.png?1649327118895';
$bola-play-video-2x: (0px, 0px, 0px, 0px, 284px, 282px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'bola-play-video@2x', );
$comparte-facebook-2x-name: 'comparte-facebook@2x';
$comparte-facebook-2x-x: 0px;
$comparte-facebook-2x-y: 446px;
$comparte-facebook-2x-offset-x: 0px;
$comparte-facebook-2x-offset-y: -446px;
$comparte-facebook-2x-width: 50px;
$comparte-facebook-2x-height: 50px;
$comparte-facebook-2x-total-width: 546px;
$comparte-facebook-2x-total-height: 496px;
$comparte-facebook-2x-image: 'img/spritesheet@2x.png?1649327118895';
$comparte-facebook-2x: (0px, 446px, 0px, -446px, 50px, 50px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'comparte-facebook@2x', );
$comparte-linkedin-2x-name: 'comparte-linkedin@2x';
$comparte-linkedin-2x-x: 50px;
$comparte-linkedin-2x-y: 446px;
$comparte-linkedin-2x-offset-x: -50px;
$comparte-linkedin-2x-offset-y: -446px;
$comparte-linkedin-2x-width: 48px;
$comparte-linkedin-2x-height: 50px;
$comparte-linkedin-2x-total-width: 546px;
$comparte-linkedin-2x-total-height: 496px;
$comparte-linkedin-2x-image: 'img/spritesheet@2x.png?1649327118895';
$comparte-linkedin-2x: (50px, 446px, -50px, -446px, 48px, 50px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'comparte-linkedin@2x', );
$comparte-twitter-2x-name: 'comparte-twitter@2x';
$comparte-twitter-2x-x: 112px;
$comparte-twitter-2x-y: 400px;
$comparte-twitter-2x-offset-x: -112px;
$comparte-twitter-2x-offset-y: -400px;
$comparte-twitter-2x-width: 54px;
$comparte-twitter-2x-height: 44px;
$comparte-twitter-2x-total-width: 546px;
$comparte-twitter-2x-total-height: 496px;
$comparte-twitter-2x-image: 'img/spritesheet@2x.png?1649327118895';
$comparte-twitter-2x: (112px, 400px, -112px, -400px, 54px, 44px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'comparte-twitter@2x', );
$filter-arrows-down-2x-name: 'filter-arrows-down@2x';
$filter-arrows-down-2x-x: 312px;
$filter-arrows-down-2x-y: 356px;
$filter-arrows-down-2x-offset-x: -312px;
$filter-arrows-down-2x-offset-y: -356px;
$filter-arrows-down-2x-width: 34px;
$filter-arrows-down-2x-height: 20px;
$filter-arrows-down-2x-total-width: 546px;
$filter-arrows-down-2x-total-height: 496px;
$filter-arrows-down-2x-image: 'img/spritesheet@2x.png?1649327118895';
$filter-arrows-down-2x: (312px, 356px, -312px, -356px, 34px, 20px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'filter-arrows-down@2x', );
$fletxa-menu-avall-2x-name: 'fletxa-menu-avall@2x';
$fletxa-menu-avall-2x-x: 346px;
$fletxa-menu-avall-2x-y: 356px;
$fletxa-menu-avall-2x-offset-x: -346px;
$fletxa-menu-avall-2x-offset-y: -356px;
$fletxa-menu-avall-2x-width: 34px;
$fletxa-menu-avall-2x-height: 20px;
$fletxa-menu-avall-2x-total-width: 546px;
$fletxa-menu-avall-2x-total-height: 496px;
$fletxa-menu-avall-2x-image: 'img/spritesheet@2x.png?1649327118895';
$fletxa-menu-avall-2x: (346px, 356px, -346px, -356px, 34px, 20px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'fletxa-menu-avall@2x', );
$fletxa-menu-endavant-2x-name: 'fletxa-menu-endavant@2x';
$fletxa-menu-endavant-2x-x: 488px;
$fletxa-menu-endavant-2x-y: 140px;
$fletxa-menu-endavant-2x-offset-x: -488px;
$fletxa-menu-endavant-2x-offset-y: -140px;
$fletxa-menu-endavant-2x-width: 16px;
$fletxa-menu-endavant-2x-height: 28px;
$fletxa-menu-endavant-2x-total-width: 546px;
$fletxa-menu-endavant-2x-total-height: 496px;
$fletxa-menu-endavant-2x-image: 'img/spritesheet@2x.png?1649327118895';
$fletxa-menu-endavant-2x: (488px, 140px, -488px, -140px, 16px, 28px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'fletxa-menu-endavant@2x', );
$icon-breadcrumb-home-2x-name: 'icon-breadcrumb-home@2x';
$icon-breadcrumb-home-2x-x: 354px;
$icon-breadcrumb-home-2x-y: 400px;
$icon-breadcrumb-home-2x-offset-x: -354px;
$icon-breadcrumb-home-2x-offset-y: -400px;
$icon-breadcrumb-home-2x-width: 24px;
$icon-breadcrumb-home-2x-height: 24px;
$icon-breadcrumb-home-2x-total-width: 546px;
$icon-breadcrumb-home-2x-total-height: 496px;
$icon-breadcrumb-home-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-breadcrumb-home-2x: (354px, 400px, -354px, -400px, 24px, 24px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-breadcrumb-home@2x', );
$icon-breadcrumb-2x-name: 'icon-breadcrumb@2x';
$icon-breadcrumb-2x-x: 488px;
$icon-breadcrumb-2x-y: 192px;
$icon-breadcrumb-2x-offset-x: -488px;
$icon-breadcrumb-2x-offset-y: -192px;
$icon-breadcrumb-2x-width: 14px;
$icon-breadcrumb-2x-height: 18px;
$icon-breadcrumb-2x-total-width: 546px;
$icon-breadcrumb-2x-total-height: 496px;
$icon-breadcrumb-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-breadcrumb-2x: (488px, 192px, -488px, -192px, 14px, 18px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-breadcrumb@2x', );
$icon-caixa-biblioteca-cusrn-2x-name: 'icon-caixa-biblioteca-cusrn@2x';
$icon-caixa-biblioteca-cusrn-2x-x: 284px;
$icon-caixa-biblioteca-cusrn-2x-y: 0px;
$icon-caixa-biblioteca-cusrn-2x-offset-x: -284px;
$icon-caixa-biblioteca-cusrn-2x-offset-y: 0px;
$icon-caixa-biblioteca-cusrn-2x-width: 140px;
$icon-caixa-biblioteca-cusrn-2x-height: 148px;
$icon-caixa-biblioteca-cusrn-2x-total-width: 546px;
$icon-caixa-biblioteca-cusrn-2x-total-height: 496px;
$icon-caixa-biblioteca-cusrn-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-caixa-biblioteca-cusrn-2x: (284px, 0px, -284px, 0px, 140px, 148px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-caixa-biblioteca-cusrn@2x', );
$icon-caixa-donaciones-2x-name: 'icon-caixa-donaciones@2x';
$icon-caixa-donaciones-2x-x: 284px;
$icon-caixa-donaciones-2x-y: 148px;
$icon-caixa-donaciones-2x-offset-x: -284px;
$icon-caixa-donaciones-2x-offset-y: -148px;
$icon-caixa-donaciones-2x-width: 124px;
$icon-caixa-donaciones-2x-height: 124px;
$icon-caixa-donaciones-2x-total-width: 546px;
$icon-caixa-donaciones-2x-total-height: 496px;
$icon-caixa-donaciones-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-caixa-donaciones-2x: (284px, 148px, -284px, -148px, 124px, 124px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-caixa-donaciones@2x', );
$icon-caixa-memoria-2x-name: 'icon-caixa-memoria@2x';
$icon-caixa-memoria-2x-x: 424px;
$icon-caixa-memoria-2x-y: 0px;
$icon-caixa-memoria-2x-offset-x: -424px;
$icon-caixa-memoria-2x-offset-y: 0px;
$icon-caixa-memoria-2x-width: 80px;
$icon-caixa-memoria-2x-height: 140px;
$icon-caixa-memoria-2x-total-width: 546px;
$icon-caixa-memoria-2x-total-height: 496px;
$icon-caixa-memoria-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-caixa-memoria-2x: (424px, 0px, -424px, 0px, 80px, 140px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-caixa-memoria@2x', );
$icon-caixa-newsletter-2x-name: 'icon-caixa-newsletter@2x';
$icon-caixa-newsletter-2x-x: 0px;
$icon-caixa-newsletter-2x-y: 282px;
$icon-caixa-newsletter-2x-offset-x: 0px;
$icon-caixa-newsletter-2x-offset-y: -282px;
$icon-caixa-newsletter-2x-width: 124px;
$icon-caixa-newsletter-2x-height: 118px;
$icon-caixa-newsletter-2x-total-width: 546px;
$icon-caixa-newsletter-2x-total-height: 496px;
$icon-caixa-newsletter-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-caixa-newsletter-2x: (0px, 282px, 0px, -282px, 124px, 118px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-caixa-newsletter@2x', );
$icon-clip-relacionats-2x-name: 'icon-clip-relacionats@2x';
$icon-clip-relacionats-2x-x: 218px;
$icon-clip-relacionats-2x-y: 400px;
$icon-clip-relacionats-2x-offset-x: -218px;
$icon-clip-relacionats-2x-offset-y: -400px;
$icon-clip-relacionats-2x-width: 44px;
$icon-clip-relacionats-2x-height: 42px;
$icon-clip-relacionats-2x-total-width: 546px;
$icon-clip-relacionats-2x-total-height: 496px;
$icon-clip-relacionats-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-clip-relacionats-2x: (218px, 400px, -218px, -400px, 44px, 42px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-clip-relacionats@2x', );
$icon-email-equipo-2x-name: 'icon-email-equipo@2x';
$icon-email-equipo-2x-x: 192px;
$icon-email-equipo-2x-y: 356px;
$icon-email-equipo-2x-offset-x: -192px;
$icon-email-equipo-2x-offset-y: -356px;
$icon-email-equipo-2x-width: 48px;
$icon-email-equipo-2x-height: 40px;
$icon-email-equipo-2x-total-width: 546px;
$icon-email-equipo-2x-total-height: 496px;
$icon-email-equipo-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-email-equipo-2x: (192px, 356px, -192px, -356px, 48px, 40px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-email-equipo@2x', );
$icon-lat-categoria-2x-name: 'icon-lat-categoria@2x';
$icon-lat-categoria-2x-x: 0px;
$icon-lat-categoria-2x-y: 400px;
$icon-lat-categoria-2x-offset-x: 0px;
$icon-lat-categoria-2x-offset-y: -400px;
$icon-lat-categoria-2x-width: 58px;
$icon-lat-categoria-2x-height: 46px;
$icon-lat-categoria-2x-total-width: 546px;
$icon-lat-categoria-2x-total-height: 496px;
$icon-lat-categoria-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-lat-categoria-2x: (0px, 400px, 0px, -400px, 58px, 46px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-lat-categoria@2x', );
$icon-lupa-2x-name: 'icon-lupa@2x';
$icon-lupa-2x-x: 98px;
$icon-lupa-2x-y: 446px;
$icon-lupa-2x-offset-x: -98px;
$icon-lupa-2x-offset-y: -446px;
$icon-lupa-2x-width: 46px;
$icon-lupa-2x-height: 46px;
$icon-lupa-2x-total-width: 546px;
$icon-lupa-2x-total-height: 496px;
$icon-lupa-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-lupa-2x: (98px, 446px, -98px, -446px, 46px, 46px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-lupa@2x', );
$icon-relacionats-excel-2x-name: 'icon-relacionats-excel@2x';
$icon-relacionats-excel-2x-x: 504px;
$icon-relacionats-excel-2x-y: 200px;
$icon-relacionats-excel-2x-offset-x: -504px;
$icon-relacionats-excel-2x-offset-y: -200px;
$icon-relacionats-excel-2x-width: 42px;
$icon-relacionats-excel-2x-height: 50px;
$icon-relacionats-excel-2x-total-width: 546px;
$icon-relacionats-excel-2x-total-height: 496px;
$icon-relacionats-excel-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-relacionats-excel-2x: (504px, 200px, -504px, -200px, 42px, 50px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-relacionats-excel@2x', );
$icon-relacionats-pdf-2x-name: 'icon-relacionats-pdf@2x';
$icon-relacionats-pdf-2x-x: 504px;
$icon-relacionats-pdf-2x-y: 0px;
$icon-relacionats-pdf-2x-offset-x: -504px;
$icon-relacionats-pdf-2x-offset-y: 0px;
$icon-relacionats-pdf-2x-width: 42px;
$icon-relacionats-pdf-2x-height: 50px;
$icon-relacionats-pdf-2x-total-width: 546px;
$icon-relacionats-pdf-2x-total-height: 496px;
$icon-relacionats-pdf-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-relacionats-pdf-2x: (504px, 0px, -504px, 0px, 42px, 50px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-relacionats-pdf@2x', );
$icon-relacionats-vincle-2x-name: 'icon-relacionats-vincle@2x';
$icon-relacionats-vincle-2x-x: 504px;
$icon-relacionats-vincle-2x-y: 150px;
$icon-relacionats-vincle-2x-offset-x: -504px;
$icon-relacionats-vincle-2x-offset-y: -150px;
$icon-relacionats-vincle-2x-width: 42px;
$icon-relacionats-vincle-2x-height: 50px;
$icon-relacionats-vincle-2x-total-width: 546px;
$icon-relacionats-vincle-2x-total-height: 496px;
$icon-relacionats-vincle-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-relacionats-vincle-2x: (504px, 150px, -504px, -150px, 42px, 50px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-relacionats-vincle@2x', );
$icon-relacionats-word-2x-name: 'icon-relacionats-word@2x';
$icon-relacionats-word-2x-x: 504px;
$icon-relacionats-word-2x-y: 50px;
$icon-relacionats-word-2x-offset-x: -504px;
$icon-relacionats-word-2x-offset-y: -50px;
$icon-relacionats-word-2x-width: 42px;
$icon-relacionats-word-2x-height: 50px;
$icon-relacionats-word-2x-total-width: 546px;
$icon-relacionats-word-2x-total-height: 496px;
$icon-relacionats-word-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-relacionats-word-2x: (504px, 50px, -504px, -50px, 42px, 50px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-relacionats-word@2x', );
$icon-relacionats-zip-2x-name: 'icon-relacionats-zip@2x';
$icon-relacionats-zip-2x-x: 504px;
$icon-relacionats-zip-2x-y: 100px;
$icon-relacionats-zip-2x-offset-x: -504px;
$icon-relacionats-zip-2x-offset-y: -100px;
$icon-relacionats-zip-2x-width: 42px;
$icon-relacionats-zip-2x-height: 50px;
$icon-relacionats-zip-2x-total-width: 546px;
$icon-relacionats-zip-2x-total-height: 496px;
$icon-relacionats-zip-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icon-relacionats-zip-2x: (504px, 100px, -504px, -100px, 42px, 50px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icon-relacionats-zip@2x', );
$icona-mas-2x-name: 'icona-mas@2x';
$icona-mas-2x-x: 504px;
$icona-mas-2x-y: 444px;
$icona-mas-2x-offset-x: -504px;
$icona-mas-2x-offset-y: -444px;
$icona-mas-2x-width: 34px;
$icona-mas-2x-height: 36px;
$icona-mas-2x-total-width: 546px;
$icona-mas-2x-total-height: 496px;
$icona-mas-2x-image: 'img/spritesheet@2x.png?1649327118895';
$icona-mas-2x: (504px, 444px, -504px, -444px, 34px, 36px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'icona-mas@2x', );
$kland-arxiu-destacat-2x-name: 'kland-arxiu-destacat@2x';
$kland-arxiu-destacat-2x-x: 462px;
$kland-arxiu-destacat-2x-y: 364px;
$kland-arxiu-destacat-2x-offset-x: -462px;
$kland-arxiu-destacat-2x-offset-y: -364px;
$kland-arxiu-destacat-2x-width: 30px;
$kland-arxiu-destacat-2x-height: 28px;
$kland-arxiu-destacat-2x-total-width: 546px;
$kland-arxiu-destacat-2x-total-height: 496px;
$kland-arxiu-destacat-2x-image: 'img/spritesheet@2x.png?1649327118895';
$kland-arxiu-destacat-2x: (462px, 364px, -462px, -364px, 30px, 28px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'kland-arxiu-destacat@2x', );
$kland-caixa-blanc-2x-name: 'kland-caixa-blanc@2x';
$kland-caixa-blanc-2x-x: 240px;
$kland-caixa-blanc-2x-y: 356px;
$kland-caixa-blanc-2x-offset-x: -240px;
$kland-caixa-blanc-2x-offset-y: -356px;
$kland-caixa-blanc-2x-width: 24px;
$kland-caixa-blanc-2x-height: 34px;
$kland-caixa-blanc-2x-total-width: 546px;
$kland-caixa-blanc-2x-total-height: 496px;
$kland-caixa-blanc-2x-image: 'img/spritesheet@2x.png?1649327118895';
$kland-caixa-blanc-2x: (240px, 356px, -240px, -356px, 24px, 34px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'kland-caixa-blanc@2x', );
$kland-caixa-blau-2x-name: 'kland-caixa-blau@2x';
$kland-caixa-blau-2x-x: 264px;
$kland-caixa-blau-2x-y: 356px;
$kland-caixa-blau-2x-offset-x: -264px;
$kland-caixa-blau-2x-offset-y: -356px;
$kland-caixa-blau-2x-width: 24px;
$kland-caixa-blau-2x-height: 34px;
$kland-caixa-blau-2x-total-width: 546px;
$kland-caixa-blau-2x-total-height: 496px;
$kland-caixa-blau-2x-image: 'img/spritesheet@2x.png?1649327118895';
$kland-caixa-blau-2x: (264px, 356px, -264px, -356px, 24px, 34px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'kland-caixa-blau@2x', );
$kland-caixa-gris-2x-name: 'kland-caixa-gris@2x';
$kland-caixa-gris-2x-x: 288px;
$kland-caixa-gris-2x-y: 356px;
$kland-caixa-gris-2x-offset-x: -288px;
$kland-caixa-gris-2x-offset-y: -356px;
$kland-caixa-gris-2x-width: 24px;
$kland-caixa-gris-2x-height: 34px;
$kland-caixa-gris-2x-total-width: 546px;
$kland-caixa-gris-2x-total-height: 496px;
$kland-caixa-gris-2x-image: 'img/spritesheet@2x.png?1649327118895';
$kland-caixa-gris-2x: (288px, 356px, -288px, -356px, 24px, 34px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'kland-caixa-gris@2x', );
$kland-cuadradet-llistats-2x-name: 'kland-cuadradet-llistats@2x';
$kland-cuadradet-llistats-2x-x: 330px;
$kland-cuadradet-llistats-2x-y: 400px;
$kland-cuadradet-llistats-2x-offset-x: -330px;
$kland-cuadradet-llistats-2x-offset-y: -400px;
$kland-cuadradet-llistats-2x-width: 24px;
$kland-cuadradet-llistats-2x-height: 24px;
$kland-cuadradet-llistats-2x-total-width: 546px;
$kland-cuadradet-llistats-2x-total-height: 496px;
$kland-cuadradet-llistats-2x-image: 'img/spritesheet@2x.png?1649327118895';
$kland-cuadradet-llistats-2x: (330px, 400px, -330px, -400px, 24px, 24px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'kland-cuadradet-llistats@2x', );
$kland-llistat-2x-name: 'kland-llistat@2x';
$kland-llistat-2x-x: 488px;
$kland-llistat-2x-y: 168px;
$kland-llistat-2x-offset-x: -488px;
$kland-llistat-2x-offset-y: -168px;
$kland-llistat-2x-width: 16px;
$kland-llistat-2x-height: 24px;
$kland-llistat-2x-total-width: 546px;
$kland-llistat-2x-total-height: 496px;
$kland-llistat-2x-image: 'img/spritesheet@2x.png?1649327118895';
$kland-llistat-2x: (488px, 168px, -488px, -168px, 16px, 24px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'kland-llistat@2x', );
$kland-llistats-portada-2x-name: 'kland-llistats-portada@2x';
$kland-llistats-portada-2x-x: 378px;
$kland-llistats-portada-2x-y: 400px;
$kland-llistats-portada-2x-offset-x: -378px;
$kland-llistats-portada-2x-offset-y: -400px;
$kland-llistats-portada-2x-width: 22px;
$kland-llistats-portada-2x-height: 24px;
$kland-llistats-portada-2x-total-width: 546px;
$kland-llistats-portada-2x-total-height: 496px;
$kland-llistats-portada-2x-image: 'img/spritesheet@2x.png?1649327118895';
$kland-llistats-portada-2x: (378px, 400px, -378px, -400px, 22px, 24px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'kland-llistats-portada@2x', );
$llistat-bola-roig-2x-name: 'llistat-bola-roig@2x';
$llistat-bola-roig-2x-x: 444px;
$llistat-bola-roig-2x-y: 400px;
$llistat-bola-roig-2x-offset-x: -444px;
$llistat-bola-roig-2x-offset-y: -400px;
$llistat-bola-roig-2x-width: 22px;
$llistat-bola-roig-2x-height: 22px;
$llistat-bola-roig-2x-total-width: 546px;
$llistat-bola-roig-2x-total-height: 496px;
$llistat-bola-roig-2x-image: 'img/spritesheet@2x.png?1649327118895';
$llistat-bola-roig-2x: (444px, 400px, -444px, -400px, 22px, 22px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'llistat-bola-roig@2x', );
$llistat-bola-taronja-2x-name: 'llistat-bola-taronja@2x';
$llistat-bola-taronja-2x-x: 422px;
$llistat-bola-taronja-2x-y: 400px;
$llistat-bola-taronja-2x-offset-x: -422px;
$llistat-bola-taronja-2x-offset-y: -400px;
$llistat-bola-taronja-2x-width: 22px;
$llistat-bola-taronja-2x-height: 22px;
$llistat-bola-taronja-2x-total-width: 546px;
$llistat-bola-taronja-2x-total-height: 496px;
$llistat-bola-taronja-2x-image: 'img/spritesheet@2x.png?1649327118895';
$llistat-bola-taronja-2x: (422px, 400px, -422px, -400px, 22px, 22px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'llistat-bola-taronja@2x', );
$llistat-bola-verd-2x-name: 'llistat-bola-verd@2x';
$llistat-bola-verd-2x-x: 400px;
$llistat-bola-verd-2x-y: 400px;
$llistat-bola-verd-2x-offset-x: -400px;
$llistat-bola-verd-2x-offset-y: -400px;
$llistat-bola-verd-2x-width: 22px;
$llistat-bola-verd-2x-height: 22px;
$llistat-bola-verd-2x-total-width: 546px;
$llistat-bola-verd-2x-total-height: 496px;
$llistat-bola-verd-2x-image: 'img/spritesheet@2x.png?1649327118895';
$llistat-bola-verd-2x: (400px, 400px, -400px, -400px, 22px, 22px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'llistat-bola-verd@2x', );
$llistat-nivell-1-2x-name: 'llistat-nivell-1@2x';
$llistat-nivell-1-2x-x: 306px;
$llistat-nivell-1-2x-y: 400px;
$llistat-nivell-1-2x-offset-x: -306px;
$llistat-nivell-1-2x-offset-y: -400px;
$llistat-nivell-1-2x-width: 24px;
$llistat-nivell-1-2x-height: 24px;
$llistat-nivell-1-2x-total-width: 546px;
$llistat-nivell-1-2x-total-height: 496px;
$llistat-nivell-1-2x-image: 'img/spritesheet@2x.png?1649327118895';
$llistat-nivell-1-2x: (306px, 400px, -306px, -400px, 24px, 24px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'llistat-nivell-1@2x', );
$llistat-nivell-2-2x-name: 'llistat-nivell-2@2x';
$llistat-nivell-2-2x-x: 282px;
$llistat-nivell-2-2x-y: 400px;
$llistat-nivell-2-2x-offset-x: -282px;
$llistat-nivell-2-2x-offset-y: -400px;
$llistat-nivell-2-2x-width: 24px;
$llistat-nivell-2-2x-height: 24px;
$llistat-nivell-2-2x-total-width: 546px;
$llistat-nivell-2-2x-total-height: 496px;
$llistat-nivell-2-2x-image: 'img/spritesheet@2x.png?1649327118895';
$llistat-nivell-2-2x: (282px, 400px, -282px, -400px, 24px, 24px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'llistat-nivell-2@2x', );
$llistat-nivell-3-2x-name: 'llistat-nivell-3@2x';
$llistat-nivell-3-2x-x: 528px;
$llistat-nivell-3-2x-y: 480px;
$llistat-nivell-3-2x-offset-x: -528px;
$llistat-nivell-3-2x-offset-y: -480px;
$llistat-nivell-3-2x-width: 10px;
$llistat-nivell-3-2x-height: 10px;
$llistat-nivell-3-2x-total-width: 546px;
$llistat-nivell-3-2x-total-height: 496px;
$llistat-nivell-3-2x-image: 'img/spritesheet@2x.png?1649327118895';
$llistat-nivell-3-2x: (528px, 480px, -528px, -480px, 10px, 10px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'llistat-nivell-3@2x', );
$menu-arrows-down-2x-name: 'menu-arrows-down@2x';
$menu-arrows-down-2x-x: 380px;
$menu-arrows-down-2x-y: 356px;
$menu-arrows-down-2x-offset-x: -380px;
$menu-arrows-down-2x-offset-y: -356px;
$menu-arrows-down-2x-width: 34px;
$menu-arrows-down-2x-height: 20px;
$menu-arrows-down-2x-total-width: 546px;
$menu-arrows-down-2x-total-height: 496px;
$menu-arrows-down-2x-image: 'img/spritesheet@2x.png?1649327118895';
$menu-arrows-down-2x: (380px, 356px, -380px, -356px, 34px, 20px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'menu-arrows-down@2x', );
$menu-arrows-left-2x-name: 'menu-arrows-left@2x';
$menu-arrows-left-2x-x: 262px;
$menu-arrows-left-2x-y: 400px;
$menu-arrows-left-2x-offset-x: -262px;
$menu-arrows-left-2x-offset-y: -400px;
$menu-arrows-left-2x-width: 20px;
$menu-arrows-left-2x-height: 34px;
$menu-arrows-left-2x-total-width: 546px;
$menu-arrows-left-2x-total-height: 496px;
$menu-arrows-left-2x-image: 'img/spritesheet@2x.png?1649327118895';
$menu-arrows-left-2x: (262px, 400px, -262px, -400px, 20px, 34px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'menu-arrows-left@2x', );
$menu-burger-2x-name: 'menu-burger@2x';
$menu-burger-2x-x: 124px;
$menu-burger-2x-y: 282px;
$menu-burger-2x-offset-x: -124px;
$menu-burger-2x-offset-y: -282px;
$menu-burger-2x-width: 74px;
$menu-burger-2x-height: 74px;
$menu-burger-2x-total-width: 546px;
$menu-burger-2x-total-height: 496px;
$menu-burger-2x-image: 'img/spritesheet@2x.png?1649327118895';
$menu-burger-2x: (124px, 282px, -124px, -282px, 74px, 74px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'menu-burger@2x', );
$menu-fletxes-enrera-2x-name: 'menu-fletxes-enrera@2x';
$menu-fletxes-enrera-2x-x: 424px;
$menu-fletxes-enrera-2x-y: 364px;
$menu-fletxes-enrera-2x-offset-x: -424px;
$menu-fletxes-enrera-2x-offset-y: -364px;
$menu-fletxes-enrera-2x-width: 38px;
$menu-fletxes-enrera-2x-height: 28px;
$menu-fletxes-enrera-2x-total-width: 546px;
$menu-fletxes-enrera-2x-total-height: 496px;
$menu-fletxes-enrera-2x-image: 'img/spritesheet@2x.png?1649327118895';
$menu-fletxes-enrera-2x: (424px, 364px, -424px, -364px, 38px, 28px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'menu-fletxes-enrera@2x', );
$menu-triangle-desplega-blanc-2x-name: 'menu-triangle-desplega-blanc@2x';
$menu-triangle-desplega-blanc-2x-x: 532px;
$menu-triangle-desplega-blanc-2x-y: 288px;
$menu-triangle-desplega-blanc-2x-offset-x: -532px;
$menu-triangle-desplega-blanc-2x-offset-y: -288px;
$menu-triangle-desplega-blanc-2x-width: 14px;
$menu-triangle-desplega-blanc-2x-height: 24px;
$menu-triangle-desplega-blanc-2x-total-width: 546px;
$menu-triangle-desplega-blanc-2x-total-height: 496px;
$menu-triangle-desplega-blanc-2x-image: 'img/spritesheet@2x.png?1649327118895';
$menu-triangle-desplega-blanc-2x: (532px, 288px, -532px, -288px, 14px, 24px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'menu-triangle-desplega-blanc@2x', );
$menu-triangle-desplega-2x-name: 'menu-triangle-desplega@2x';
$menu-triangle-desplega-2x-x: 532px;
$menu-triangle-desplega-2x-y: 312px;
$menu-triangle-desplega-2x-offset-x: -532px;
$menu-triangle-desplega-2x-offset-y: -312px;
$menu-triangle-desplega-2x-width: 14px;
$menu-triangle-desplega-2x-height: 24px;
$menu-triangle-desplega-2x-total-width: 546px;
$menu-triangle-desplega-2x-total-height: 496px;
$menu-triangle-desplega-2x-image: 'img/spritesheet@2x.png?1649327118895';
$menu-triangle-desplega-2x: (532px, 312px, -532px, -312px, 14px, 24px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'menu-triangle-desplega@2x', );
$navega-llistat-seguent-2x-name: 'navega-llistat-seguent@2x';
$navega-llistat-seguent-2x-x: 504px;
$navega-llistat-seguent-2x-y: 288px;
$navega-llistat-seguent-2x-offset-x: -504px;
$navega-llistat-seguent-2x-offset-y: -288px;
$navega-llistat-seguent-2x-width: 28px;
$navega-llistat-seguent-2x-height: 48px;
$navega-llistat-seguent-2x-total-width: 546px;
$navega-llistat-seguent-2x-total-height: 496px;
$navega-llistat-seguent-2x-image: 'img/spritesheet@2x.png?1649327118895';
$navega-llistat-seguent-2x: (504px, 288px, -504px, -288px, 28px, 48px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'navega-llistat-seguent@2x', );
$paginacio-anterior-2x-name: 'paginacio-anterior@2x';
$paginacio-anterior-2x-x: 326px;
$paginacio-anterior-2x-y: 282px;
$paginacio-anterior-2x-offset-x: -326px;
$paginacio-anterior-2x-offset-y: -282px;
$paginacio-anterior-2x-width: 64px;
$paginacio-anterior-2x-height: 48px;
$paginacio-anterior-2x-total-width: 546px;
$paginacio-anterior-2x-total-height: 496px;
$paginacio-anterior-2x-image: 'img/spritesheet@2x.png?1649327118895';
$paginacio-anterior-2x: (326px, 282px, -326px, -282px, 64px, 48px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'paginacio-anterior@2x', );
$paginacio-seguent-2x-name: 'paginacio-seguent@2x';
$paginacio-seguent-2x-x: 262px;
$paginacio-seguent-2x-y: 282px;
$paginacio-seguent-2x-offset-x: -262px;
$paginacio-seguent-2x-offset-y: -282px;
$paginacio-seguent-2x-width: 64px;
$paginacio-seguent-2x-height: 48px;
$paginacio-seguent-2x-total-width: 546px;
$paginacio-seguent-2x-total-height: 496px;
$paginacio-seguent-2x-image: 'img/spritesheet@2x.png?1649327118895';
$paginacio-seguent-2x: (262px, 282px, -262px, -282px, 64px, 48px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'paginacio-seguent@2x', );
$pestanya-bola-roig-2x-name: 'pestanya-bola-roig@2x';
$pestanya-bola-roig-2x-x: 504px;
$pestanya-bola-roig-2x-y: 372px;
$pestanya-bola-roig-2x-offset-x: -504px;
$pestanya-bola-roig-2x-offset-y: -372px;
$pestanya-bola-roig-2x-width: 36px;
$pestanya-bola-roig-2x-height: 36px;
$pestanya-bola-roig-2x-total-width: 546px;
$pestanya-bola-roig-2x-total-height: 496px;
$pestanya-bola-roig-2x-image: 'img/spritesheet@2x.png?1649327118895';
$pestanya-bola-roig-2x: (504px, 372px, -504px, -372px, 36px, 36px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'pestanya-bola-roig@2x', );
$pestanya-bola-taronja-2x-name: 'pestanya-bola-taronja@2x';
$pestanya-bola-taronja-2x-x: 504px;
$pestanya-bola-taronja-2x-y: 336px;
$pestanya-bola-taronja-2x-offset-x: -504px;
$pestanya-bola-taronja-2x-offset-y: -336px;
$pestanya-bola-taronja-2x-width: 36px;
$pestanya-bola-taronja-2x-height: 36px;
$pestanya-bola-taronja-2x-total-width: 546px;
$pestanya-bola-taronja-2x-total-height: 496px;
$pestanya-bola-taronja-2x-image: 'img/spritesheet@2x.png?1649327118895';
$pestanya-bola-taronja-2x: (504px, 336px, -504px, -336px, 36px, 36px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'pestanya-bola-taronja@2x', );
$pestanya-bola-verd-2x-name: 'pestanya-bola-verd@2x';
$pestanya-bola-verd-2x-x: 504px;
$pestanya-bola-verd-2x-y: 408px;
$pestanya-bola-verd-2x-offset-x: -504px;
$pestanya-bola-verd-2x-offset-y: -408px;
$pestanya-bola-verd-2x-width: 36px;
$pestanya-bola-verd-2x-height: 36px;
$pestanya-bola-verd-2x-total-width: 546px;
$pestanya-bola-verd-2x-total-height: 496px;
$pestanya-bola-verd-2x-image: 'img/spritesheet@2x.png?1649327118895';
$pestanya-bola-verd-2x: (504px, 408px, -504px, -408px, 36px, 36px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'pestanya-bola-verd@2x', );
$slide-endavant-2x-name: 'slide-endavant@2x';
$slide-endavant-2x-x: 424px;
$slide-endavant-2x-y: 252px;
$slide-endavant-2x-offset-x: -424px;
$slide-endavant-2x-offset-y: -252px;
$slide-endavant-2x-width: 64px;
$slide-endavant-2x-height: 112px;
$slide-endavant-2x-total-width: 546px;
$slide-endavant-2x-total-height: 496px;
$slide-endavant-2x-image: 'img/spritesheet@2x.png?1649327118895';
$slide-endavant-2x: (424px, 252px, -424px, -252px, 64px, 112px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'slide-endavant@2x', );
$slide-enrera-2x-name: 'slide-enrera@2x';
$slide-enrera-2x-x: 424px;
$slide-enrera-2x-y: 140px;
$slide-enrera-2x-offset-x: -424px;
$slide-enrera-2x-offset-y: -140px;
$slide-enrera-2x-width: 64px;
$slide-enrera-2x-height: 112px;
$slide-enrera-2x-total-width: 546px;
$slide-enrera-2x-total-height: 496px;
$slide-enrera-2x-image: 'img/spritesheet@2x.png?1649327118895';
$slide-enrera-2x: (424px, 140px, -424px, -140px, 64px, 112px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'slide-enrera@2x', );
$subir-2x-name: 'subir@2x';
$subir-2x-x: 124px;
$subir-2x-y: 356px;
$subir-2x-offset-x: -124px;
$subir-2x-offset-y: -356px;
$subir-2x-width: 68px;
$subir-2x-height: 40px;
$subir-2x-total-width: 546px;
$subir-2x-total-height: 496px;
$subir-2x-image: 'img/spritesheet@2x.png?1649327118895';
$subir-2x: (124px, 356px, -124px, -356px, 68px, 40px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'subir@2x', );
$triangle-cronologia-dreta-2x-name: 'triangle-cronologia-dreta@2x';
$triangle-cronologia-dreta-2x-x: 390px;
$triangle-cronologia-dreta-2x-y: 282px;
$triangle-cronologia-dreta-2x-offset-x: -390px;
$triangle-cronologia-dreta-2x-offset-y: -282px;
$triangle-cronologia-dreta-2x-width: 24px;
$triangle-cronologia-dreta-2x-height: 44px;
$triangle-cronologia-dreta-2x-total-width: 546px;
$triangle-cronologia-dreta-2x-total-height: 496px;
$triangle-cronologia-dreta-2x-image: 'img/spritesheet@2x.png?1649327118895';
$triangle-cronologia-dreta-2x: (390px, 282px, -390px, -282px, 24px, 44px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'triangle-cronologia-dreta@2x', );
$triangle-cronologia-esquerra-2x-name: 'triangle-cronologia-esquerra@2x';
$triangle-cronologia-esquerra-2x-x: 188px;
$triangle-cronologia-esquerra-2x-y: 446px;
$triangle-cronologia-esquerra-2x-offset-x: -188px;
$triangle-cronologia-esquerra-2x-offset-y: -446px;
$triangle-cronologia-esquerra-2x-width: 24px;
$triangle-cronologia-esquerra-2x-height: 44px;
$triangle-cronologia-esquerra-2x-total-width: 546px;
$triangle-cronologia-esquerra-2x-total-height: 496px;
$triangle-cronologia-esquerra-2x-image: 'img/spritesheet@2x.png?1649327118895';
$triangle-cronologia-esquerra-2x: (188px, 446px, -188px, -446px, 24px, 44px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'triangle-cronologia-esquerra@2x', );
$triangle-menu-2x-name: 'triangle-menu@2x';
$triangle-menu-2x-x: 504px;
$triangle-menu-2x-y: 480px;
$triangle-menu-2x-offset-x: -504px;
$triangle-menu-2x-offset-y: -480px;
$triangle-menu-2x-width: 24px;
$triangle-menu-2x-height: 14px;
$triangle-menu-2x-total-width: 546px;
$triangle-menu-2x-total-height: 496px;
$triangle-menu-2x-image: 'img/spritesheet@2x.png?1649327118895';
$triangle-menu-2x: (504px, 480px, -504px, -480px, 24px, 14px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'triangle-menu@2x', );
$valores-cerrar-creu-2x-name: 'valores-cerrar-creu@2x';
$valores-cerrar-creu-2x-x: 504px;
$valores-cerrar-creu-2x-y: 250px;
$valores-cerrar-creu-2x-offset-x: -504px;
$valores-cerrar-creu-2x-offset-y: -250px;
$valores-cerrar-creu-2x-width: 36px;
$valores-cerrar-creu-2x-height: 38px;
$valores-cerrar-creu-2x-total-width: 546px;
$valores-cerrar-creu-2x-total-height: 496px;
$valores-cerrar-creu-2x-image: 'img/spritesheet@2x.png?1649327118895';
$valores-cerrar-creu-2x: (504px, 250px, -504px, -250px, 36px, 38px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'valores-cerrar-creu@2x', );
$valores-rodona-mes-2x-name: 'valores-rodona-mes@2x';
$valores-rodona-mes-2x-x: 198px;
$valores-rodona-mes-2x-y: 282px;
$valores-rodona-mes-2x-offset-x: -198px;
$valores-rodona-mes-2x-offset-y: -282px;
$valores-rodona-mes-2x-width: 64px;
$valores-rodona-mes-2x-height: 66px;
$valores-rodona-mes-2x-total-width: 546px;
$valores-rodona-mes-2x-total-height: 496px;
$valores-rodona-mes-2x-image: 'img/spritesheet@2x.png?1649327118895';
$valores-rodona-mes-2x: (198px, 282px, -198px, -282px, 64px, 66px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'valores-rodona-mes@2x', );
$xarxes-facebook-2x-name: 'xarxes-facebook@2x';
$xarxes-facebook-2x-x: 144px;
$xarxes-facebook-2x-y: 446px;
$xarxes-facebook-2x-offset-x: -144px;
$xarxes-facebook-2x-offset-y: -446px;
$xarxes-facebook-2x-width: 44px;
$xarxes-facebook-2x-height: 44px;
$xarxes-facebook-2x-total-width: 546px;
$xarxes-facebook-2x-total-height: 496px;
$xarxes-facebook-2x-image: 'img/spritesheet@2x.png?1649327118895';
$xarxes-facebook-2x: (144px, 446px, -144px, -446px, 44px, 44px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'xarxes-facebook@2x', );
$xarxes-twitter-2x-name: 'xarxes-twitter@2x';
$xarxes-twitter-2x-x: 58px;
$xarxes-twitter-2x-y: 400px;
$xarxes-twitter-2x-offset-x: -58px;
$xarxes-twitter-2x-offset-y: -400px;
$xarxes-twitter-2x-width: 54px;
$xarxes-twitter-2x-height: 44px;
$xarxes-twitter-2x-total-width: 546px;
$xarxes-twitter-2x-total-height: 496px;
$xarxes-twitter-2x-image: 'img/spritesheet@2x.png?1649327118895';
$xarxes-twitter-2x: (58px, 400px, -58px, -400px, 54px, 44px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'xarxes-twitter@2x', );
$xarxes-vimeo-2x-name: 'xarxes-vimeo@2x';
$xarxes-vimeo-2x-x: 166px;
$xarxes-vimeo-2x-y: 400px;
$xarxes-vimeo-2x-offset-x: -166px;
$xarxes-vimeo-2x-offset-y: -400px;
$xarxes-vimeo-2x-width: 52px;
$xarxes-vimeo-2x-height: 42px;
$xarxes-vimeo-2x-total-width: 546px;
$xarxes-vimeo-2x-total-height: 496px;
$xarxes-vimeo-2x-image: 'img/spritesheet@2x.png?1649327118895';
$xarxes-vimeo-2x: (166px, 400px, -166px, -400px, 52px, 42px, 546px, 496px, 'img/spritesheet@2x.png?1649327118895', 'xarxes-vimeo@2x', );
$spritesheet-width: 273px;
$spritesheet-height: 248px;
$spritesheet-image: 'img/spritesheet.png?1649327118895';
$spritesheet-sprites: ($bola-play-video, $comparte-facebook, $comparte-linkedin, $comparte-twitter, $filter-arrows-down, $fletxa-menu-avall, $fletxa-menu-endavant, $icon-breadcrumb-home, $icon-breadcrumb, $icon-caixa-biblioteca-cusrn, $icon-caixa-donaciones, $icon-caixa-memoria, $icon-caixa-newsletter, $icon-clip-relacionats, $icon-email-equipo, $icon-lat-categoria, $icon-lupa, $icon-relacionats-excel, $icon-relacionats-pdf, $icon-relacionats-vincle, $icon-relacionats-word, $icon-relacionats-zip, $icona-mas, $kland-arxiu-destacat, $kland-caixa-blanc, $kland-caixa-blau, $kland-caixa-gris, $kland-cuadradet-llistats, $kland-llistat, $kland-llistats-portada, $llistat-bola-roig, $llistat-bola-taronja, $llistat-bola-verd, $llistat-nivell-1, $llistat-nivell-2, $llistat-nivell-3, $menu-arrows-down, $menu-arrows-left, $menu-burger, $menu-fletxes-enrera, $menu-triangle-desplega-blanc, $menu-triangle-desplega, $navega-llistat-seguent, $paginacio-anterior, $paginacio-seguent, $pestanya-bola-roig, $pestanya-bola-taronja, $pestanya-bola-verd, $slide-endavant, $slide-enrera, $subir, $triangle-cronologia-dreta, $triangle-cronologia-esquerra, $triangle-menu, $valores-cerrar-creu, $valores-rodona-mes, $xarxes-facebook, $xarxes-twitter, $xarxes-vimeo, );
$spritesheet: (273px, 248px, 'img/spritesheet.png?1649327118895', $spritesheet-sprites, );
$retina-spritesheet-width: 546px;
$retina-spritesheet-height: 496px;
$retina-spritesheet-image: 'img/spritesheet@2x.png?1649327118895';
$retina-spritesheet-sprites: ($bola-play-video-2x, $comparte-facebook-2x, $comparte-linkedin-2x, $comparte-twitter-2x, $filter-arrows-down-2x, $fletxa-menu-avall-2x, $fletxa-menu-endavant-2x, $icon-breadcrumb-home-2x, $icon-breadcrumb-2x, $icon-caixa-biblioteca-cusrn-2x, $icon-caixa-donaciones-2x, $icon-caixa-memoria-2x, $icon-caixa-newsletter-2x, $icon-clip-relacionats-2x, $icon-email-equipo-2x, $icon-lat-categoria-2x, $icon-lupa-2x, $icon-relacionats-excel-2x, $icon-relacionats-pdf-2x, $icon-relacionats-vincle-2x, $icon-relacionats-word-2x, $icon-relacionats-zip-2x, $icona-mas-2x, $kland-arxiu-destacat-2x, $kland-caixa-blanc-2x, $kland-caixa-blau-2x, $kland-caixa-gris-2x, $kland-cuadradet-llistats-2x, $kland-llistat-2x, $kland-llistats-portada-2x, $llistat-bola-roig-2x, $llistat-bola-taronja-2x, $llistat-bola-verd-2x, $llistat-nivell-1-2x, $llistat-nivell-2-2x, $llistat-nivell-3-2x, $menu-arrows-down-2x, $menu-arrows-left-2x, $menu-burger-2x, $menu-fletxes-enrera-2x, $menu-triangle-desplega-blanc-2x, $menu-triangle-desplega-2x, $navega-llistat-seguent-2x, $paginacio-anterior-2x, $paginacio-seguent-2x, $pestanya-bola-roig-2x, $pestanya-bola-taronja-2x, $pestanya-bola-verd-2x, $slide-endavant-2x, $slide-enrera-2x, $subir-2x, $triangle-cronologia-dreta-2x, $triangle-cronologia-esquerra-2x, $triangle-menu-2x, $valores-cerrar-creu-2x, $valores-rodona-mes-2x, $xarxes-facebook-2x, $xarxes-twitter-2x, $xarxes-vimeo-2x, );
$retina-spritesheet: (546px, 496px, 'img/spritesheet@2x.png?1649327118895', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$bola-play-video-group-name: 'bola-play-video';
$bola-play-video-group: ('bola-play-video', $bola-play-video, $bola-play-video-2x, );
$comparte-facebook-group-name: 'comparte-facebook';
$comparte-facebook-group: ('comparte-facebook', $comparte-facebook, $comparte-facebook-2x, );
$comparte-linkedin-group-name: 'comparte-linkedin';
$comparte-linkedin-group: ('comparte-linkedin', $comparte-linkedin, $comparte-linkedin-2x, );
$comparte-twitter-group-name: 'comparte-twitter';
$comparte-twitter-group: ('comparte-twitter', $comparte-twitter, $comparte-twitter-2x, );
$filter-arrows-down-group-name: 'filter-arrows-down';
$filter-arrows-down-group: ('filter-arrows-down', $filter-arrows-down, $filter-arrows-down-2x, );
$fletxa-menu-avall-group-name: 'fletxa-menu-avall';
$fletxa-menu-avall-group: ('fletxa-menu-avall', $fletxa-menu-avall, $fletxa-menu-avall-2x, );
$fletxa-menu-endavant-group-name: 'fletxa-menu-endavant';
$fletxa-menu-endavant-group: ('fletxa-menu-endavant', $fletxa-menu-endavant, $fletxa-menu-endavant-2x, );
$icon-breadcrumb-home-group-name: 'icon-breadcrumb-home';
$icon-breadcrumb-home-group: ('icon-breadcrumb-home', $icon-breadcrumb-home, $icon-breadcrumb-home-2x, );
$icon-breadcrumb-group-name: 'icon-breadcrumb';
$icon-breadcrumb-group: ('icon-breadcrumb', $icon-breadcrumb, $icon-breadcrumb-2x, );
$icon-caixa-biblioteca-cusrn-group-name: 'icon-caixa-biblioteca-cusrn';
$icon-caixa-biblioteca-cusrn-group: ('icon-caixa-biblioteca-cusrn', $icon-caixa-biblioteca-cusrn, $icon-caixa-biblioteca-cusrn-2x, );
$icon-caixa-donaciones-group-name: 'icon-caixa-donaciones';
$icon-caixa-donaciones-group: ('icon-caixa-donaciones', $icon-caixa-donaciones, $icon-caixa-donaciones-2x, );
$icon-caixa-memoria-group-name: 'icon-caixa-memoria';
$icon-caixa-memoria-group: ('icon-caixa-memoria', $icon-caixa-memoria, $icon-caixa-memoria-2x, );
$icon-caixa-newsletter-group-name: 'icon-caixa-newsletter';
$icon-caixa-newsletter-group: ('icon-caixa-newsletter', $icon-caixa-newsletter, $icon-caixa-newsletter-2x, );
$icon-clip-relacionats-group-name: 'icon-clip-relacionats';
$icon-clip-relacionats-group: ('icon-clip-relacionats', $icon-clip-relacionats, $icon-clip-relacionats-2x, );
$icon-email-equipo-group-name: 'icon-email-equipo';
$icon-email-equipo-group: ('icon-email-equipo', $icon-email-equipo, $icon-email-equipo-2x, );
$icon-lat-categoria-group-name: 'icon-lat-categoria';
$icon-lat-categoria-group: ('icon-lat-categoria', $icon-lat-categoria, $icon-lat-categoria-2x, );
$icon-lupa-group-name: 'icon-lupa';
$icon-lupa-group: ('icon-lupa', $icon-lupa, $icon-lupa-2x, );
$icon-relacionats-excel-group-name: 'icon-relacionats-excel';
$icon-relacionats-excel-group: ('icon-relacionats-excel', $icon-relacionats-excel, $icon-relacionats-excel-2x, );
$icon-relacionats-pdf-group-name: 'icon-relacionats-pdf';
$icon-relacionats-pdf-group: ('icon-relacionats-pdf', $icon-relacionats-pdf, $icon-relacionats-pdf-2x, );
$icon-relacionats-vincle-group-name: 'icon-relacionats-vincle';
$icon-relacionats-vincle-group: ('icon-relacionats-vincle', $icon-relacionats-vincle, $icon-relacionats-vincle-2x, );
$icon-relacionats-word-group-name: 'icon-relacionats-word';
$icon-relacionats-word-group: ('icon-relacionats-word', $icon-relacionats-word, $icon-relacionats-word-2x, );
$icon-relacionats-zip-group-name: 'icon-relacionats-zip';
$icon-relacionats-zip-group: ('icon-relacionats-zip', $icon-relacionats-zip, $icon-relacionats-zip-2x, );
$icona-mas-group-name: 'icona-mas';
$icona-mas-group: ('icona-mas', $icona-mas, $icona-mas-2x, );
$kland-arxiu-destacat-group-name: 'kland-arxiu-destacat';
$kland-arxiu-destacat-group: ('kland-arxiu-destacat', $kland-arxiu-destacat, $kland-arxiu-destacat-2x, );
$kland-caixa-blanc-group-name: 'kland-caixa-blanc';
$kland-caixa-blanc-group: ('kland-caixa-blanc', $kland-caixa-blanc, $kland-caixa-blanc-2x, );
$kland-caixa-blau-group-name: 'kland-caixa-blau';
$kland-caixa-blau-group: ('kland-caixa-blau', $kland-caixa-blau, $kland-caixa-blau-2x, );
$kland-caixa-gris-group-name: 'kland-caixa-gris';
$kland-caixa-gris-group: ('kland-caixa-gris', $kland-caixa-gris, $kland-caixa-gris-2x, );
$kland-cuadradet-llistats-group-name: 'kland-cuadradet-llistats';
$kland-cuadradet-llistats-group: ('kland-cuadradet-llistats', $kland-cuadradet-llistats, $kland-cuadradet-llistats-2x, );
$kland-llistat-group-name: 'kland-llistat';
$kland-llistat-group: ('kland-llistat', $kland-llistat, $kland-llistat-2x, );
$kland-llistats-portada-group-name: 'kland-llistats-portada';
$kland-llistats-portada-group: ('kland-llistats-portada', $kland-llistats-portada, $kland-llistats-portada-2x, );
$llistat-bola-roig-group-name: 'llistat-bola-roig';
$llistat-bola-roig-group: ('llistat-bola-roig', $llistat-bola-roig, $llistat-bola-roig-2x, );
$llistat-bola-taronja-group-name: 'llistat-bola-taronja';
$llistat-bola-taronja-group: ('llistat-bola-taronja', $llistat-bola-taronja, $llistat-bola-taronja-2x, );
$llistat-bola-verd-group-name: 'llistat-bola-verd';
$llistat-bola-verd-group: ('llistat-bola-verd', $llistat-bola-verd, $llistat-bola-verd-2x, );
$llistat-nivell-1-group-name: 'llistat-nivell-1';
$llistat-nivell-1-group: ('llistat-nivell-1', $llistat-nivell-1, $llistat-nivell-1-2x, );
$llistat-nivell-2-group-name: 'llistat-nivell-2';
$llistat-nivell-2-group: ('llistat-nivell-2', $llistat-nivell-2, $llistat-nivell-2-2x, );
$llistat-nivell-3-group-name: 'llistat-nivell-3';
$llistat-nivell-3-group: ('llistat-nivell-3', $llistat-nivell-3, $llistat-nivell-3-2x, );
$menu-arrows-down-group-name: 'menu-arrows-down';
$menu-arrows-down-group: ('menu-arrows-down', $menu-arrows-down, $menu-arrows-down-2x, );
$menu-arrows-left-group-name: 'menu-arrows-left';
$menu-arrows-left-group: ('menu-arrows-left', $menu-arrows-left, $menu-arrows-left-2x, );
$menu-burger-group-name: 'menu-burger';
$menu-burger-group: ('menu-burger', $menu-burger, $menu-burger-2x, );
$menu-fletxes-enrera-group-name: 'menu-fletxes-enrera';
$menu-fletxes-enrera-group: ('menu-fletxes-enrera', $menu-fletxes-enrera, $menu-fletxes-enrera-2x, );
$menu-triangle-desplega-blanc-group-name: 'menu-triangle-desplega-blanc';
$menu-triangle-desplega-blanc-group: ('menu-triangle-desplega-blanc', $menu-triangle-desplega-blanc, $menu-triangle-desplega-blanc-2x, );
$menu-triangle-desplega-group-name: 'menu-triangle-desplega';
$menu-triangle-desplega-group: ('menu-triangle-desplega', $menu-triangle-desplega, $menu-triangle-desplega-2x, );
$navega-llistat-seguent-group-name: 'navega-llistat-seguent';
$navega-llistat-seguent-group: ('navega-llistat-seguent', $navega-llistat-seguent, $navega-llistat-seguent-2x, );
$paginacio-anterior-group-name: 'paginacio-anterior';
$paginacio-anterior-group: ('paginacio-anterior', $paginacio-anterior, $paginacio-anterior-2x, );
$paginacio-seguent-group-name: 'paginacio-seguent';
$paginacio-seguent-group: ('paginacio-seguent', $paginacio-seguent, $paginacio-seguent-2x, );
$pestanya-bola-roig-group-name: 'pestanya-bola-roig';
$pestanya-bola-roig-group: ('pestanya-bola-roig', $pestanya-bola-roig, $pestanya-bola-roig-2x, );
$pestanya-bola-taronja-group-name: 'pestanya-bola-taronja';
$pestanya-bola-taronja-group: ('pestanya-bola-taronja', $pestanya-bola-taronja, $pestanya-bola-taronja-2x, );
$pestanya-bola-verd-group-name: 'pestanya-bola-verd';
$pestanya-bola-verd-group: ('pestanya-bola-verd', $pestanya-bola-verd, $pestanya-bola-verd-2x, );
$slide-endavant-group-name: 'slide-endavant';
$slide-endavant-group: ('slide-endavant', $slide-endavant, $slide-endavant-2x, );
$slide-enrera-group-name: 'slide-enrera';
$slide-enrera-group: ('slide-enrera', $slide-enrera, $slide-enrera-2x, );
$subir-group-name: 'subir';
$subir-group: ('subir', $subir, $subir-2x, );
$triangle-cronologia-dreta-group-name: 'triangle-cronologia-dreta';
$triangle-cronologia-dreta-group: ('triangle-cronologia-dreta', $triangle-cronologia-dreta, $triangle-cronologia-dreta-2x, );
$triangle-cronologia-esquerra-group-name: 'triangle-cronologia-esquerra';
$triangle-cronologia-esquerra-group: ('triangle-cronologia-esquerra', $triangle-cronologia-esquerra, $triangle-cronologia-esquerra-2x, );
$triangle-menu-group-name: 'triangle-menu';
$triangle-menu-group: ('triangle-menu', $triangle-menu, $triangle-menu-2x, );
$valores-cerrar-creu-group-name: 'valores-cerrar-creu';
$valores-cerrar-creu-group: ('valores-cerrar-creu', $valores-cerrar-creu, $valores-cerrar-creu-2x, );
$valores-rodona-mes-group-name: 'valores-rodona-mes';
$valores-rodona-mes-group: ('valores-rodona-mes', $valores-rodona-mes, $valores-rodona-mes-2x, );
$xarxes-facebook-group-name: 'xarxes-facebook';
$xarxes-facebook-group: ('xarxes-facebook', $xarxes-facebook, $xarxes-facebook-2x, );
$xarxes-twitter-group-name: 'xarxes-twitter';
$xarxes-twitter-group: ('xarxes-twitter', $xarxes-twitter, $xarxes-twitter-2x, );
$xarxes-vimeo-group-name: 'xarxes-vimeo';
$xarxes-vimeo-group: ('xarxes-vimeo', $xarxes-vimeo, $xarxes-vimeo-2x, );
$retina-groups: ($bola-play-video-group, $comparte-facebook-group, $comparte-linkedin-group, $comparte-twitter-group, $filter-arrows-down-group, $fletxa-menu-avall-group, $fletxa-menu-endavant-group, $icon-breadcrumb-home-group, $icon-breadcrumb-group, $icon-caixa-biblioteca-cusrn-group, $icon-caixa-donaciones-group, $icon-caixa-memoria-group, $icon-caixa-newsletter-group, $icon-clip-relacionats-group, $icon-email-equipo-group, $icon-lat-categoria-group, $icon-lupa-group, $icon-relacionats-excel-group, $icon-relacionats-pdf-group, $icon-relacionats-vincle-group, $icon-relacionats-word-group, $icon-relacionats-zip-group, $icona-mas-group, $kland-arxiu-destacat-group, $kland-caixa-blanc-group, $kland-caixa-blau-group, $kland-caixa-gris-group, $kland-cuadradet-llistats-group, $kland-llistat-group, $kland-llistats-portada-group, $llistat-bola-roig-group, $llistat-bola-taronja-group, $llistat-bola-verd-group, $llistat-nivell-1-group, $llistat-nivell-2-group, $llistat-nivell-3-group, $menu-arrows-down-group, $menu-arrows-left-group, $menu-burger-group, $menu-fletxes-enrera-group, $menu-triangle-desplega-blanc-group, $menu-triangle-desplega-group, $navega-llistat-seguent-group, $paginacio-anterior-group, $paginacio-seguent-group, $pestanya-bola-roig-group, $pestanya-bola-taronja-group, $pestanya-bola-verd-group, $slide-endavant-group, $slide-enrera-group, $subir-group, $triangle-cronologia-dreta-group, $triangle-cronologia-esquerra-group, $triangle-menu-group, $valores-cerrar-creu-group, $valores-rodona-mes-group, $xarxes-facebook-group, $xarxes-twitter-group, $xarxes-vimeo-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
